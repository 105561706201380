
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { NavLink, useParams } from "react-router-dom";
// import { UserContext } from "../Context/CreateContext";
// import { useContext, useEffect, useState } from "react";
// import { base_url } from "../Config/Index";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// export default function AllProducts({ product }) {
//   const { token } = useContext(UserContext);
//   const { id } = useParams();
//   const [allProducts, setAllProducts] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [cartItems, setCartItems] = useState([]);
//   const [cartId, setcartId] = useState();
//   console.log(token, "ttt")
//   useEffect(() => {
//     getProduct();
//   }, [id]);

//   useEffect(() => {
//     getAllProduct();
//   }, []);

//   useEffect(() => {
//     getCartItems();
//   }, []);

//   const handleCart = async (productId) => {
//     const payload = {
//       product: productId,
//       quantity: 1,
//     };
//     try {
//       const response = await axios.post(`${base_url}/api/post/card`, payload, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       });
//       toast.success("Item Added Successfully");
//       console.log(response.data, "add")
//       getCartItems();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getProduct = async () => {
//     try {
//       const response = await axios.get(`${base_url}/api/get/product/${id}`, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       });
//       setSelectedCategory(response.data.category);
//     } catch (error) {
//       console.error("Error:", error.message);
//     }
//   };

//   const getAllProduct = async () => {
//     try {
//       const response = await axios.get(
//         `${base_url}/api/get/all/products?categoryId=${selectedCategory}`,
//         {
//           headers: {
//             Authorization: `${token}`,
//           },
//         }
//       );
//       const filteredProducts = response.data.filter((item) => item._id !== id);
//       setAllProducts(filteredProducts);
//     } catch (error) {
//       console.error("Error:", error.message);
//     }
//   };

//   const getCartItems = async () => {
//     try {
//       const response = await axios.get(`${base_url}/api/get/card`, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       });
//       setCartItems(response.data.items);
//       setcartId(response.data._id);
//       console.log(response.data._id, "cartId")
//     } catch (error) {
//       console.log(error);
//     }
//   };


//   const handleQuantity = async (itemId, action) => {
//     try {
//       const response = await axios.put(
//         `${base_url}/api/carts/${cartId}/items/${itemId}`,
//         { action: action },
//         {
//           headers: {
//             Authorization: `${token}`,
//           },
//         }
//       );
//       console.log(response, "resp");
//       if (response.status === 200) {
//         getCartItems();
//       }
//     } catch (error) {
//       toast.error("Item Out of Stock");
//     }
//   };
//   const isInCart = (productId) => {
//     return cartItems.some((item) => item.product?._id === productId);
//   };

//   console.log(cartItems, "cartitems")

//   var settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <>
//       <h2 className="text-center mt-3">
//         Products For You
//       </h2>
//       <section className="p-3 mb-5" style={{ backgroundColor: "#90d576", boxShadow: '0 -4px 20px 0 #90d576' }}>
//         <div className="outerDiv" style={{ backgroundColor: "#90d576" }}>
//           <Slider {...settings}>
//             {allProducts.map((item, index) => (
//               <div key={index}>
//                 <div
//                   className="card product_div"
//                   style={{
//                     backgroundColor: "white",
//                     borderRadius: "15px",
//                     padding: "10px",
//                     margin: "10px",
//                     height: "350px",
//                     width: "250px"
//                   }}
//                 >
//                   <NavLink to={`/product_details/${item?._id}`}>
//                     <div className="card-header image_div">
//                       <img
//                         src={item.images[0].url}
//                         className="w-100 h-300"
//                         alt={item.title}
//                         style={{ borderRadius: "10px" }}
//                       />
//                     </div>
//                   </NavLink>
//                   <div
//                     className="card-body details_div text-center"
//                     style={{ fontSize: 12, fontWeight: "bold", marginTop: "10px" }}
//                   >
//                     {item.title}
//                     <br />
//                     <span style={{ color: "green", fontSize: 12 }}>
//                       Rs.
//                       {item.price}
//                       <br />
//                     </span>
//                   </div>
//                   <div className="card-footer p-0">
//                     {isInCart(item._id) ? (
//                       <div className="d-flex justify-content-between align-items-center">
//                         <button
//                           onClick={() => handleQuantity(item._id, "decrement")}
//                           className="btn btn-sm border border-success"
//                         >
//                           -
//                         </button>
//                         <span className="mx-2" style={{ color: "black", fontSize: 15, marginBottom: "10px" }}>
//                           {cartItems.find((cartItem) => cartItem.product._id === item._id)?.quantity}
//                         </span>
//                         <button
//                           className="btn btn-sm border border-success"
//                           onClick={() => handleQuantity(item._id, "increment")}
//                         >
//                           +
//                         </button>
//                       </div>
//                     ) : (
//                       <button
//                         className="btn btn-sm btn-success w-500"
//                         onClick={() => handleCart(item._id)}
//                         style={{ borderRadius: "5px", marginTop: 20 }}
//                       >
//                         Add to Cart
//                       </button>
//                     )}

//                   </div>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//         {/* <NavLink to="/product_page">
//           <button
//             className="btn text-primary"
//             style={{
//               backgroundColor: "transparent",
//               border: 0,
//               // float: "right",
//               position: "absolute", 
//       // top: "2px", 
//       right: "10px", 
//       bottom:"-500px"

//             }}
//           >
//             View More
//           </button>
//         </NavLink> */}
//       </section>
//       <ToastContainer />
//     </>
//   );
// }



import Img4 from "../Assets/Images/Img4.jpeg";
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';  // Ensure Bootstrap is imported
import 'aos/dist/aos.css';  // Ensure AOS is imported
import Aos from "aos";

const FarmerImageSection = () => {
  useEffect(() => {
    Aos.init();  // Initialize AOS
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 2000,  // Set default duration to 2 seconds (2000ms)
    });
  }, []);

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-12 text-center">
          <h1 className="text-danger mb-4">
            Welcome to Biocyll Laboratories Pvt. Ltd. – Your Trusted Partner in Sustainable Agri Inputs
          </h1>
        </div>
      </div>
      <div className="row">
  {/* Image Section - Full Width */}
  <div className="col-12 text-center mb-4">
    <div className="hm-kb-org-thumb aos-init aos-animate" data-aos="zoom-in">
      <a href="https://www.youtube.com/watch?v=LhruK1TUK00" data-fancybox="banner">
        <figure>
          <img
            src={Img4} // Replace with your farmer image
            className="img-fluid"
            alt="Farmer"
            width="600"
            height="450"
            loading="lazy"
          />
        </figure>
      </a>
    </div>
  </div>

  {/* Text Section - Below the Image */}
  <div className="col-12">
    <p className="text-secondary text-justify" data-aos="fade-right" data-aos-duration="2000" style={{ fontSize: "1.2rem" }}>
      At Biocyll Laboratories Pvt. Ltd., we are dedicated to advancing agricultural productivity
      through cutting-edge biological solutions. As a leading provider of bulk manufacturing,
      contract manufacturing, and contract R&D, we specialize in producing high-quality
      biofertilizers, biopesticides (both extract-based and microbial-based), biostimulants, and
      micronutrients tailored to meet the needs of agri-input marketers and businesses across
      India.
    </p>

    <p className="text-secondary text-justify" data-aos="fade-right" data-aos-duration="2000" style={{ fontSize: "1.2rem" }}>
      Our reputation is built on our expertise in Vesicular Arbuscular Mycorrhiza (VAM)
      production, where we stand out as a premier supplier in bulk and contract manufacturing.
      VAM plays a critical role in enhancing soil health and plant growth, making it an
      indispensable input for sustainable and organic farming. This specialization positions us as
      the go-to partner for companies seeking reliable and scalable solutions to expand their
      agriproduct offerings.
      With state-of-the-art facilities, advanced R&D capabilities, and a deep commitment to
      quality, Biocyll Laboratories collaborates closely with agri-input marketers, helping them
      innovate, customize, and scale production to meet market demands.
    </p>

    <a href="/aboutus" className="btn btn-danger">
      Know More
    </a>
  </div>
</div>

    </div>
  );
};

export default FarmerImageSection;

