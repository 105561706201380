// import React from 'react'
// import "../Css/style.css"
// const Contract_mfg = () => {
//   return (
//     <>
//       <section className='journey-section'>
//         <div class="container-fluid">
//           <div class="row">
//             <div class="col-md-12">
//               <div class="journey-text">
//                 <h3 className='mb-2'>Complete Solution for Sustainable Farming Practices</h3>
//                 <p>Our contract manufacturing facility has been meticulously designed to adhere to global standards, boasting state-of-the-art technology for efficient and top-tier production. With robust systems and stringent procedures in place, we uphold stringent quality control measures at every phase of the production process, ensuring excellence in every product we deliver.

//                   At Biocyll Laboratories Pvt. Ltd., our dedication to sustainable agriculture drives our belief in the transformative potential of microbial solutions. By partnering with us, you gain access to our extensive product range, technical expertise, and diverse business opportunities. Our team comprises technical experts who collaborate closely to facilitate knowledge transfer and ensure the availability of our environmentally friendly solutions. With Biocyll Laboratories Pvt. Ltd., you not only receive high-quality products but also benefit from our unwavering commitment to sustainable farming practices and our comprehensive support network.

//                   To discover opportunities for mutually advantageous business partnerships. Reach out to us now!

//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   )
// }

// export default Contract_mfg
import React from 'react';
// import "../Css/style.css";

const Contract_mfg = () => {
  return (
    <section className="journey-section">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="">
              <div className="header text-center">
                <h3 className="title text-success">
                  {/* Complete Solution for Sustainable Farming Practices */}
                  What We Offer ?
                </h3>
              </div>
              <div className="body">
                <p className="text-justify mt-3">
                  <b> 1. Biofertilizers Manufacturing </b>
                  We specialize in producing a diverse range of biofertilizers, including nitrogen-fixing,
                  phosphate-solubilizing, and potassium-mobilizing strains. These products promote
                  sustainable farming by improving nutrient availability, soil health, and plant growth.                 </p>
                <p className="text-justify">
                  <b> 2. Biopesticides Manufacturing (Extract-Based and Microbial-Based) </b>
                  Our biopesticides are derived from natural sources and microbial strains, offering ecofriendly solutions for pest and disease management. Whether it’s neem-based extracts or
                  Bacillus-based microbial pesticides, we deliver tailored solutions.
                </p>
                <p>
                  <b>3. Biostimulants Manufacturing </b>
                  Our biostimulants are designed to improve plant metabolism, enhance stress tolerance, and
                  optimize growth. They include amino acid-based, humic acid, and seaweed extract
                  formulations to meet diverse agricultural needs.
                </p>
                <p>
                  <b>4. Micronutrient Manufacturing </b>
                  We produce a range of chelated and non-chelated micronutrients that address soil deficiencies
                  and ensure optimal plant nutrition. Custom formulations are available to meet market-specific
                  requirements.
                </p>
                <p>
                  <b>5. Specialized Vesicular Arbuscular Mycorrhiza (VAM) </b>
                  Biocyll is renowned for its expertise in the production of Vesicular Arbuscular Mycorrhiza
                  (VAM). Our VAM solutions improve nutrient uptake, water retention, and overall soil
                  fertility, making them an essential addition to any agricultural input portfolio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contract_mfg;
