// import React from 'react'
// // import "../Css/style.css"
// import Farmer from '../Assets/Images/farmer_journey.png'
// const Journey = () => {
//   return (
//     <>
//       <section className='journey-section' >
//         <div className='row'>
//           <div className='col-md-12 text-center'>
//             <h2>Our Journey</h2>
//           </div>
//         </div>

//         <div className='container-fluid'>
//           <div className="row" style={{ textAlign: 'justify' }}>
//             <div className="col-md-6 mt-4 journey-text" >
//               <h4 style={{ lineHeight: 1.5 }}>Started back in 2012 in a very tiny manufacturing facility of 500 sq.ft. area, Biocyll draws inspiration from the noble thought of helping farmers with innovative solutions leading to more yield, helping them achieve prosperity in farming. Starting with the combined experience of more than 50 years of promoters, Biocyll has come so far as to acquire many technologies and products from industry, institutions, and industry stalwarts. Now Biocyll is impacting more than millions of farmers and is recognized as the Best Company in Biologicals by Agriculture Today, Biocyll Laboratories Pvt. Ltd. stands as a testament to the growth and pioneering advancements in biological technologies.</h4>
//             </div>
//             <div className="col-md-6" >
//               <img src={Farmer} className='w-100 mt-4'   />
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   )
// }

// export default Journey
import React, { useEffect } from 'react';
import Farmer from '../Assets/Images/farmer_journey.png';
import AOS from 'aos';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import 'aos/dist/aos.css'; // AOS styles

const Journey = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    });
  }, []);

  return (
    <section className='journey-section py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          {/* Left Section */}
          <div className='col-md-6' data-aos="fade-right">
            <div className='journey-content'>
              <h1 className='text-success display-4 display-md-3 display-sm-5'>Our Journey</h1>
              <p className='fs-5 fs-md-4 fs-sm-4 text-justify'>
                Started back in 2012 in a very tiny manufacturing facility of 500 sq.ft. area, Biocyll draws
                inspiration from the noble thought of helping farmers with innovative solutions leading to
                more yield, helping them achieve prosperity in farming. Starting with the combined
                experience of more than 50 years of promoters, Biocyll has come so far as to acquire many
                technologies and products from industry, institutions, and industry stalwarts. Now Biocyll is
                impacting more than millions of farmers and is recognized as the Best Company in
                Biologicals by Agriculture Today, Biocyll Laboratories Pvt. Ltd. stands as a testament to the
                growth and pioneering advancements in biological technologies.              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className='col-md-6' data-aos="zoom-out">
            <div className='journey-thumb'>
              <figure>
                <img src={Farmer} className='img-fluid mt-4' alt='Our Journey' />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Journey;
