// import axios from 'axios';
// import React, { useContext, useEffect, useState } from 'react'
// import { base_url } from '../Config/Index';
// import { UserContext } from '../Context/CreateContext';

// const Team = () => {
//   const { user, token, login, logout } = useContext(UserContext);

//   const [team, setTeam] = useState([]);
//   // console.log(team)
//   useEffect(() => {
//     getTeam()
//   }, [])

//   const getTeam = async () => {
//     try {
//       const response = await axios.get(`${base_url}/api/get/teams`, {
//         headers: {
//           Authorization: `${token}`
//         }
//       })
//       setTeam(response.data)
//       // console.log(response.data)
//     } catch (error) {
//       console.log(error)
//     }
//   }
//   return (
//     <>
//       <div className="row">
//   <div className="col-12 text-center mt-0 mb-4 headings">
//     <h1 className="text-success">Our Team</h1>
//   </div>
// </div>

// {/* Team Members Row */}
// <div className="row d-flex flex-wrap justify-content-center">
//   {team.map((item, index) => (
//     <div key={index} className="col-6 col-md-3 d-flex flex-column align-items-center">
//       {/* Image Section */}
//       <img
//         src={item?.image}
//         className="w-100"
//         style={{ height: "380px", objectFit: "cover", borderRadius: "10px", marginBottom: "10px"}}
//         alt="Team Member"
//       />

//       {/* Text Section */}
//       <div className="text-center">
//         <p className="mb-1" style={{ fontSize: "16px", fontWeight: "bold" }}>{item?.name}</p>
//         <p className="mb-2" style={{ fontSize: "14px", color: "#666" }}>{item?.position}</p>
//       </div>
//     </div>
//   ))}
// </div>

//     </>
//   )
// }

// export default Team



import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { base_url } from '../Config/Index';
import { UserContext } from '../Context/CreateContext';
import group from "../Assets/Images/group.JPG"
import AOS from 'aos';

const Team = () => {
  const { user, token, login, logout } = useContext(UserContext);

  const [team, setTeam] = useState([]);

  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    try {
      const response = await axios.get(`${base_url}/api/get/teams`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setTeam(response.data);
    } catch (error) {
      console.log(error);
    }
  };
useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 text-center mt-0 mb-4 headings">
          <h1 className="text-success">Our Team</h1>
        </div>
      </div>

      {/* Team Members Row */}
      <div className="row d-flex flex-wrap justify-content-center">
        {team.map((item, index) => (
          <div key={index} className="col-6 col-md-3 d-flex flex-column align-items-center">
            {/* Image Section */}
            <img
              src={item?.image}
              className="w-100" data-aos="zoom-in"
              style={{ height: '390px', objectFit: 'cover', borderRadius: '10px', marginBottom: '10px' }}
              alt="Team Member"
            />

            {/* Text Section */}
            <div className="text-center">
              <p className="mb-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>{item?.name}</p>
              <p className="mb-2" style={{ fontSize: '14px', color: '#666' }}>{item?.position}</p>
            </div>
          </div>
        ))}

        <div className="col-12 text-center mt-4">
          <img
            src={group}  
            className="img-fluid" data-aos="fade-up"
            style={{ width: '50%', maxWidth: '900px', borderRadius: '10px' }}
          />
        </div>
      </div>
    </>
  );
};

export default Team;
