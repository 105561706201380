
// import React, { useContext, useState } from 'react';
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
// import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for Toastify

// const ContactForm = () => {
//     const { user, token } = useContext(UserContext);
//     const [contact, setContact] = useState({
//         Name: "",
//         Email: "",
//         Phone: "",
//         Company: "",
//         Occupation: "",
//         Message: "",
//     });

//     const handleChange = (event) => {
//         setContact({ ...contact, [event.target.name]: event.target.value });
//     };

//     const handleRadioChange = (event) => {
//         setContact({ ...contact, Occupation: event.target.value });
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault(); // Prevent the default form submission
//         const payload = {
//             Name: contact.Name,
//             Email: contact.Email,
//             Phone: contact.Phone,
//             Company: contact.Company,
//             Occupation: contact.Occupation,
//             Message: contact.Message,
//         };

//         try {
//             const response = await axios.post(`${base_url}/api/contactus/post`, payload, {
//                 headers: {
//                     Authorization: `${token}`,
//                 },
//             });
//             console.log(response.data);
//             toast.success("Form submitted successfully!"); 
//             setContact({
//                 Name: "",
//                 Email: "",
//                 Phone: "",
//                 Company: "",
//                 Occupation: "",
//                 Message: "",
//             });
//         } catch (error) {
//             console.log(error);
//             toast.error("Failed to submit the form. Please try again.");
//         }
//     };

//     return (
//         <>
//             {/* <section>
//                 <div className="container-fluid " style={{fontSize:'115%'}}>
//                     <div className="row">
//                         <div className="col-12 bg-light text-center">
//                             <h3><b>Get in Touch</b></h3>
//                         </div>

//                         <div style={{ backgroundColor: "#90d576", boxShadow: '0 -4px 20px 0 #90d576' }}>
//                             <div className="col-12 p-3 text-center">
//                                 <h5 className='text-white'>Reach out to us for any inquiries or information you require that isn't available here, and we'll respond promptly.</h5>
//                             </div>
//                             <div className="row p-3">
//                                 <div className="col-md-5 p-5" style={{ color: 'white' }}>
//                                     <p className='mb-4'><b>Phone Number :</b><br />+91 20 60202626</p>
//                                     <p className='mb-4'><b>Email :</b><br />info@biocyll.com</p>
//                                     <p className='mb-4'><b>Office Address :</b><br />Office No 404, Karle Chowk, Karle Empire, Nanded City Phase 2, Sinhagad Road Pune 411041</p>
//                                     <p className='mb-4'><b>Mfg Address :</b><br />Sr. No 168/3B Near Sakal Press, Behind Laxmi Vajan Kata, Uruli Devachi, Tal. Haveli, Dist. Pune - 412308</p>
//                                 </div>
//                                 <div className="col-md-7 bg-light p-4">
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="row">
//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Full Name :
//                                                 <input placeholder='Enter Your Name' className='form-control' name='Name' value={contact.Name} onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Email :
//                                                 <input placeholder='Enter Your Email' className='form-control' name='Email' value={contact.Email} onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Phone :
//                                                 <input placeholder='Enter Your Phone Number' className='form-control' name='Phone' value={contact.Phone} onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Company Name :
//                                                 <input placeholder='Enter Your Company Name' className='form-control' name='Company' value={contact.Company} onChange={handleChange} />
//                                             </div>
//                                             <div className="d-flex align-items-center justify-content-center mt-3 col-12">
//                                                 <div className="form-check form-check-inline mr-4">
//                                                     <input
//                                                         type="radio"
//                                                         id="business"
//                                                         name="occupation"
//                                                         className="form-check-input"
//                                                         value="Business Enquiry"
//                                                         checked={contact.Occupation === "Business Enquiry"}
//                                                         onChange={handleRadioChange}
//                                                     />
//                                                     <label htmlFor="business" className="ml-1">Business</label>
//                                                 </div>
//                                                 <div className="form-check form-check-inline">
//                                                     <input
//                                                         type="radio"
//                                                         id="farmer"
//                                                         name="occupation"
//                                                         className="form-check-input"
//                                                         value="Farmer Enquiry"
//                                                         checked={contact.Occupation === "Farmer Enquiry"}
//                                                         onChange={handleRadioChange}
//                                                     />
//                                                     <label htmlFor="farmer" className="ml-1">Farmer</label>
//                                                 </div>
//                                             </div>
//                                             <div className='col-12 mt-3'>
//                                                 Message :
//                                                 <textarea placeholder='Message' className='form-control' name='Message' value={contact.Message} onChange={handleChange} style={{ height: '200px' }} />
//                                             </div>
//                                             <div className='col-12 mt-3 text-center'>
//                                                 <button type='submit' className='btn btn-success btn-lg'>Submit</button>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>

//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </section> */}
//             <section>
//   <div className="container-fluid" style={{ fontSize: '115%' }}>
//     <div className="row">
//       <div className="col-12 bg-light text-center">
//         <h3><b>Get in Touch</b></h3>
//       </div>

//       <div style={{ backgroundColor: "#90d576", boxShadow: '0 -4px 20px 0 #90d576' }}>
//         <div className="col-12 p-3 text-center">
//           <h5 className='text-white'>Reach out to us for any inquiries or information you require that isn't available here, and we'll respond promptly.</h5>
//         </div>

//         <div className="row p-3">
//           <div className="col-md-5 p-5" style={{ color: 'white' }}>
//             <p className='mb-4'><b>Phone Number :</b><br />+91 20 60202626</p>
//             <p className='mb-4'><b>Email :</b><br />info@biocyll.com</p>
//             <p className='mb-4'><b>Office Address :</b><br />Office No 404, Karle Chowk, Karle Empire, Nanded City Phase 2, Sinhagad Road Pune 411041</p>
//             <p className='mb-4'><b>Mfg Address :</b><br />Sr. No 168/3B Near Sakal Press, Behind Laxmi Vajan Kata, Uruli Devachi, Tal. Haveli, Dist. Pune - 412308</p>
//           </div>

//           <div className="col-md-7 bg-light p-4">
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-md-6 col-sm-12 mt-3">
//                   Full Name :
//                   <input placeholder='Enter Your Name' className='form-control' name='Name' value={contact.Name} onChange={handleChange} />
//                 </div>

//                 <div className="col-md-6 col-sm-12 mt-3">
//                   Email :
//                   <input placeholder='Enter Your Email' className='form-control' name='Email' value={contact.Email} onChange={handleChange} />
//                 </div>

//                 <div className="col-md-6 col-sm-12 mt-3">
//                   Phone :
//                   <input placeholder='Enter Your Phone Number' className='form-control' name='Phone' value={contact.Phone} onChange={handleChange} />
//                 </div>

//                 <div className="col-md-6 col-sm-12 mt-3">
//                   Company Name :
//                   <input placeholder='Enter Your Company Name' className='form-control' name='Company' value={contact.Company} onChange={handleChange} />
//                 </div>

//                 {/* Add User Type Heading */}
//                 <div className="col-12 mt-4">
//                   <h5 className="text-left">User Type :</h5>
//                 </div>

//                 <div className="d-flex align-items-left mt-3 col-12">
//                   <div className="form-check form-check-inline mr-4">
//                     <input
//                       type="radio"
//                       id="business"
//                       name="occupation"
//                       className="form-check-input"
//                       value="Business Enquiry"
//                       checked={contact.Occupation === "Business Enquiry"}
//                       onChange={handleRadioChange}
//                     />
//                     <label htmlFor="business" className="ml-1">Corporate</label>
//                   </div>

//                   <div className="form-check form-check-inline">
//                     <input
//                       type="radio"
//                       id="farmer"
//                       name="occupation"
//                       className="form-check-input"
//                       value="Farmer Enquiry"
//                       checked={contact.Occupation === "Farmer Enquiry"}
//                       onChange={handleRadioChange}
//                     />
//                     <label htmlFor="farmer" className="ml-1">Farmer</label>
//                   </div>
//                 </div>

//                 <div className='col-12 mt-3'>
//                   Message :
//                   <textarea placeholder='Message' className='form-control' name='Message' value={contact.Message} onChange={handleChange} style={{ height: '200px' }} />
//                 </div>

//                 <div className='col-12 mt-3 text-center'>
//                   <button type='submit' className='btn btn-success btn-lg'>Submit</button>
//                 </div>

//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </section>

//             <ToastContainer /> 
//         </>
//     );
// };

// export default ContactForm;
// import React, { useContext, useState } from 'react';
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
// import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for Toastify

// const ContactForm = () => {
//     const { user, token } = useContext(UserContext);
//     const [contact, setContact] = useState({
//         Name: "",
//         Email: "",
//         Phone: "",
//         Company: "",
//         Occupation: "",
//         Message: "",
//     });

//     const handleChange = (event) => {
//         setContact({ ...contact, [event.target.name]: event.target.value });
//     };

//     const handleRadioChange = (event) => {
//         setContact({ ...contact, Occupation: event.target.value });
//     };

//     const validateForm = () => {
//         const { Name, Email, Phone, Company, Message } = contact;

//         // Check for empty fields
//         if (!Name || !Email || !Phone || !Company || !Message || !contact.Occupation) {
//             toast.error("Please fill out all fields.");
//             return false;
//         }

//         // Validate email format
//         const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         if (!emailPattern.test(Email)) {
//             toast.error("Please enter a valid email address.");
//             return false;
//         }

//         // Validate phone number format (adjust according to your needs)
//         const phonePattern = /^\+?\d{10,15}$/; // Example: Allowing international format
//         if (!phonePattern.test(Phone)) {
//             toast.error("Please enter a valid phone number.");
//             return false;
//         }

//         return true;
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault(); // Prevent the default form submission

//         // Validate form inputs
//         if (!validateForm()) {
//             return;
//         }

//         const payload = {
//             Name: contact.Name,
//             Email: contact.Email,
//             Phone: contact.Phone,
//             Company: contact.Company,
//             Occupation: contact.Occupation,
//             Message: contact.Message,
//         };

//         try {
//             const response = await axios.post(`${base_url}/api/contactus/post`, payload, {
//                 headers: {
//                     Authorization: `${token}`,
//                 },
//             });
//             console.log(response.data);
//             toast.success("Form submitted successfully!"); 
//             setContact({
//                 Name: "",
//                 Email: "",
//                 Phone: "",
//                 Company: "",
//                 Occupation: "",
//                 Message: "",
//             });
//         } catch (error) {
//             console.log(error);
//             toast.error("Failed to submit the form. Please try again.");
//         }
//     };

//     return (
//         <>
//             <section>
//                 <div className="container-fluid" style={{ fontSize: '115%' }}>
//                     <div className="row">
//                         <div className="col-12 bg-light text-center">
//                             <h3><b>Get in Touch</b></h3>
//                         </div>

//                         <div style={{ backgroundColor: "#90d576", boxShadow: '0 -4px 20px 0 #90d576' }}>
//                             <div className="col-12 p-3 text-center">
//                                 <h5 className='text-white'>Reach out to us for any inquiries or information you require that isn't available here, and we'll respond promptly.</h5>
//                             </div>

//                             <div className="row p-3">
//                                 <div className="col-md-5 p-5" style={{ color: 'white' }}>
//                                     <p className='mb-4'><b>Phone Number :</b><br />+91 20 60202626</p>
//                                     <p className='mb-4'><b>Email :</b><br />info@biocyll.com</p>
//                                     <p className='mb-4'><b>Office Address :</b><br />Office No 404, Karle Chowk, Karle Empire, Nanded City Phase 2, Sinhagad Road Pune 411041</p>
//                                     <p className='mb-4'><b>Mfg Address :</b><br />Sr. No 168/3B Near Sakal Press, Behind Laxmi Vajan Kata, Uruli Devachi, Tal. Haveli, Dist. Pune - 412308</p>
//                                 </div>

//                                 <div className="col-md-7 bg-light p-4">
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="row">
//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Full Name :
//                                                 <input placeholder='Enter Your Name' className='form-control' name='Name' value={contact.Name} onChange={handleChange} />
//                                             </div>

//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Email :
//                                                 <input placeholder='Enter Your Email' className='form-control' name='Email' value={contact.Email} onChange={handleChange} />
//                                             </div>

//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Phone :
//                                                 <input placeholder='Enter Your Phone Number' className='form-control' name='Phone' value={contact.Phone} onChange={handleChange} />
//                                             </div>

//                                             <div className="col-md-6 col-sm-12 mt-3">
//                                                 Company Name :
//                                                 <input placeholder='Enter Your Company Name' className='form-control' name='Company' value={contact.Company} onChange={handleChange} />
//                                             </div>

//                                             <div className="col-12 mt-4">
//                                                 <h5 className="text-left">User Type :</h5>
//                                             </div>

//                                             <div className="d-flex align-items-left mt-3 col-12">
//                                                 <div className="form-check form-check-inline mr-4">
//                                                     <input
//                                                         type="radio"
//                                                         id="business"
//                                                         name="occupation"
//                                                         className="form-check-input"
//                                                         value="Business Enquiry"
//                                                         checked={contact.Occupation === "Business Enquiry"}
//                                                         onChange={handleRadioChange}
//                                                     />
//                                                     <label htmlFor="business" className="ml-1">Corporate</label>
//                                                 </div>

//                                                 <div className="form-check form-check-inline">
//                                                     <input
//                                                         type="radio"
//                                                         id="farmer"
//                                                         name="occupation"
//                                                         className="form-check-input"
//                                                         value="Farmer Enquiry"
//                                                         checked={contact.Occupation === "Farmer Enquiry"}
//                                                         onChange={handleRadioChange}
//                                                     />
//                                                     <label htmlFor="farmer" className="ml-1">Farmer</label>
//                                                 </div>
//                                             </div>

//                                             <div className='col-12 mt-3'>
//                                                 Message :
//                                                 <textarea placeholder='Message' className='form-control' name='Message' value={contact.Message} onChange={handleChange} style={{ height: '200px' }} />
//                                             </div>

//                                             <div className='col-12 mt-3 text-center'>
//                                                 <button type='submit' className='btn btn-success btn-lg'>Submit</button>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>

//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </section>

//             <ToastContainer />
//         </>
//     );
// };

// export default ContactForm;
import React, { useContext, useState,  useEffect } from 'react';
import { UserContext } from '../Context/CreateContext';
import axios from 'axios';
import { base_url } from '../Config/Index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import "../Css/style.css"

const ContactForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    const { user, token } = useContext(UserContext);
    const [contact, setContact] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Company: "",
        Occupation: "",
        Message: "",
    });

    const handleChange = (event) => {
        setContact({ ...contact, [event.target.name]: event.target.value });
    };

    const handleRadioChange = (event) => {
        setContact({ ...contact, Occupation: event.target.value });
    };

    const validateForm = () => {
        const { Name, Email, Phone, Company, Message } = contact;

        // if (!Name || !Email || !Phone || !Company || !Message || !contact.Occupation) {
        //     toast.error("Please fill out all fields.");
        //     return false;
        // }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // if (!emailPattern.test(Email)) {
        //     toast.error("Please enter a valid email address.");
        //     return false;
        // }

        const phonePattern = /^\+?\d{10,15}$/;
        // if (!phonePattern.test(Phone)) {
        //     toast.error("Please enter a valid phone number.");
        //     return false;
        // }

        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const payload = {
            Name: contact.Name,
            Email: contact.Email,
            Phone: contact.Phone,
            Company: contact.Company,
            Occupation: contact.Occupation,
            Message: contact.Message,
        };

        try {
            const response = await axios.post(`${base_url}/api/contactus/post`, payload, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            console.log(response.data);
            toast.success("Form submitted successfully!");
            setContact({
                Name: "",
                Email: "",
                Phone: "",
                Company: "",
                Occupation: "",
                Message: "",
            });
        } catch (error) {
            console.log(error);
            toast.error("Failed to submit the form. Please try again.");
        }
    };

    return (
        <>
            <section className="contact-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 bg-light text-center p-4">
                            <h3><b>Get in Touch</b></h3>
                        </div>

                        <div className="contact-info-wrapper">
                            <div className="col-12 p-3 text-center">
                                <h5 className=''>Reach out to us for any inquiries or information you require that isn't available here, and we'll respond promptly.</h5>
                            </div>

                            <div className="row p-3">
                                <div className="col-md-5 contact-info" >
                                    <p><b>Phone Number :</b><br />+9120-67506783</p>
                                    <p><b>Email :</b><br />info@biocyll.com</p>
                                    <p><b>Office Address :</b><br />Office No. 404, Karle Chowk, Karle Empire, Nanded City, Phase 2, Sinhagad Road, Pune, 411041.</p>
                                    <p><b>Factory Address :</b><br />Sr.No. 168/4, Near Sakal Press, Behind Laxmi Vajan Kata, Uruli Devachi, Tal. Haveli, Dist. Pune - 412308.</p>
                                </div>

                                <div className="col-md-7 bg-light p-4">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 mt-3">
                                                Full Name :
                                                <input
                                                    placeholder='Enter Your Name'
                                                    className='form-control'
                                                    name='Name'
                                                    value={contact.Name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>

                                            <div className="col-md-6 col-sm-12 mt-3">
                                                Phone:
                                                <input placeholder='Enter Your Email' className='form-control' name='Email' value={contact.Email} onChange={handleChange} required/>
                                            </div>

                                            <div className="col-md-6 col-sm-12 mt-3">
                                                Email:
                                                <input placeholder='Enter Your Phone Number' className='form-control' name='Phone' value={contact.Phone} onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6 col-sm-12 mt-3">
                                                Company Name :
                                                <input placeholder='Enter Your Company Name' className='form-control' name='Company' value={contact.Company} onChange={handleChange} />
                                            </div>
                                            <div className='col-12 mt-3'>
                                                Message :
                                                <textarea placeholder='Message' className='form-control' name='Message' value={contact.Message} onChange={handleChange} style={{ height: '200px' }} />
                                            </div>

                                            <div className='col-12 mt-3 text-center'>
                                                <button type='submit' className='btn btn-success btn-lg'>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <ToastContainer />
        </>
    );
};

export default ContactForm;
