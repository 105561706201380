

// import React from 'react'
// import { NavLink } from 'react-router-dom'
// import s3 from "../Assets/Images/s3.png"
// import s4 from "../Assets/Images/s4.png"
// import s5 from "../Assets/Images/s5.png"
// import s6 from "../Assets/Images/s6.png"
// import s7 from "../Assets/Images/s7.png"

// const Values = () => {
//   return (
//     <>
//         <section className='journey-section'>

//     <div className="row responsive-text" >
//       <div className="col-12 pr-5 pl-5 text-center  headings" >
//       <h3 className=" ">Core Values</h3>
//         <div className='journey-textt'>
//             <p className=' p-3' >Get to know us better with these core values that define us not only as a company but as a group of like-minded individuals who have a common goal of creating a better tomorrow.
//             </p>
//         </div>
//         </div>
//     </div>
//     <div className="responsive-text container-fluid pl-5 pb-5 lr-5 services " >
//                 <div className=" responsive-text row  p-3">
//                     <div className="col-md-8 mt-3 service_area " >
//                         <div className="row">
//                             <div className="col-4">
//                                 <img src={s3}  className='w-50'/>
//                             </div>
//                             <div className="col-8 headings  p-3 " >
//                                 <h5>ETHICS</h5>
//                                <p>We conduct ourselves and our business affairs in accordance with the highest ethical standards.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-8 mt-3 service_area " style={{marginLeft:300}}>
//                         <div className="row">
//                             <div className="col-4">
//                                 <img src={s4}  className='w-50'/>
//                             </div>
//                             <div className="col-8 headings">
//                                 <h5>LONG TERM</h5>
//                                <p>We base our decisions on maximizing the long-term success of our company.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-8 mt-3 service_area" > 
//                         <div className="row">
//                             <div className="col-4">
//                                 <img src={s4}  className='w-50'/>
//                             </div>
//                             <div className="col-8 headings">
//                                 <h5>CUSTOMER FIRST</h5>
//                                <p >We will work towards a customer focussed organization, keeping the customer front and center in all we do.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-8 mt-3 service_area " style={{marginLeft:300}}>
//                         <div className="row">
//                             <div className="col-4">
//                                 <img src={s5}  className='w-50'/>
//                             </div>
//                             <div className="col-8 headings">
//                                 <h5>OWNERSHIP </h5>
//                                <p>Each individual doing what it takes to win.</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-8 mt-3 service_area " >
//                         <div className="row">
//                             <div className="col-4">
//                                 <img src={s6}  className='w-50'/>
//                             </div>
//                             <div className="col-8 headings">
//                                 <h5> QUALITY</h5>
//                                <p>We take responsibility for quality. Our product and services will be amongst the best in terms of value delivered.</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     </>
//   )
// }

// export default Values
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import s3 from "../Assets/Images/s3.png";
import s4 from "../Assets/Images/s4.png";
import s5 from "../Assets/Images/s5.png";
import s6 from "../Assets/Images/s6.png";

const Values = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);

  return (
    <>
    <section className="journey pr-5"> {/* Added pr-5 for padding-right */}
  <div className="row responsive-text">
    <div className="col-12 pr-5 text-center headings">
      <h1 className="text-success">Core Values</h1>
      <div className="journey-textt">
        <p className="p-4 text-justify" data-aos="fade-up">
          Get to know us better with these core values that define us not only as a company but as a group of like-minded individuals who have a common goal of creating a better tomorrow.
        </p>
      </div>
    </div>
  </div>

  <div className="responsive-text pl-5 pr-5 pb-5 lr-5 services"> {/* Added pr-5 here */}
    <div className="responsive-text row p-3 text-justify">
      {/* ETHICS */}
      <div className="col-md-8 mt-3 service_area pr-4" data-aos="fade-right"> {/* Added pr-4 */}
        <div className="row">
          <div className="col-4">
            <img src={s3} className="w-50" alt="Ethics" />
          </div>
          <div className="col-8 headings p-3">
            <h5>ETHICS & INTEGRITY</h5>
            <p>
              We uphold ethical business practices, ensuring fairness, transparency, and regulatory compliance at all levels. Our philosophy is that everyone should receive what they rightfully deserve. Be it investors, customers, employees or vendors. We are committed to delivering more value than the price paid.
            </p>
          </div>
        </div>
      </div>

      {/* CUSTOMER FIRST */}
      <div className="col-md-8 mt-3 service_area pr-4" style={{ marginLeft: 200 }} data-aos="fade-right">
        <div className="row">
          <div className="col-4">
            <img src={s4} className="w-50" alt="Customer First" />
          </div>
          <div className="col-8 headings">
            <h5>CUSTOMER FIRST APPROACH</h5>
            <p>
              We believe in understanding and fulfilling the exact needs of our customers. Whether it’s customized contract manufacturing, bulk production, or contract R&D. Our focus remains on delivering innovative, high-quality agricultural solutions that enhance customers' profitability and sustainability.
            </p>
          </div>
        </div>
      </div>

      {/* LONG TERM THINKING */}
      <div className="col-md-8 mt-3 service_area pr-4" data-aos="fade-right">
        <div className="row">
          <div className="col-4">
            <img src={s4} className="w-50" alt="Long Term" />
          </div>
          <div className="col-8 headings">
            <h5>LONG TERM THINKING</h5>
            <p>
              Our vision extends beyond individual lifespans. We prioritize sustainable relationships with customers, vendors, and people, ensuring business longevity and mutual growth. Our strategies focus on long-term success rather than short-term gains.
            </p>
          </div>
        </div>
      </div>

      {/* CONSISTENT QUALITY */}
      <div className="col-md-8 mt-3 service_area pr-4" style={{ marginLeft: 200 }} data-aos="fade-right">
        <div className="row">
          <div className="col-4">
            <img src={s5} className="w-50" alt="Ownership" />
          </div>
          <div className="col-8 headings">
            <h5>CONSISTENT QUALITY & INNOVATION</h5>
            <p>
              We maintain stringent quality assurance across all our microbial products to ensure superior efficacy through robust processes. Our commitment to continuous innovation enables us to create products that deliver optimal results consistently.
            </p>
          </div>
        </div>
      </div>

      {/* ECO THINKING */}
      <div className="col-md-8 mt-3 service_area pr-4" data-aos="fade-right">
        <div className="row">
          <div className="col-4">
            <img src={s6} className="w-50" alt="Quality" />
          </div>
          <div className="col-8 headings">
            <h5>ECO THINKING</h5>
            <p>
              We believe in empowering talent and fostering innovation. By encouraging “Eco thinking”, we foster a collaborative work culture where individuals can explore their full potential. Our approach benefits not just our business but also the Agribusiness ecosystem as a whole.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default Values;
