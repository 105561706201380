// import React, { useContext, useEffect, useState } from 'react'
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';

// const Total = ({totalAmt}) => {

//     return (
//         <>
//             <div className="card mt-4 bg-white" style={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
//                 <div className="card-header bg-white">
//                     <h5>PRICE DETAILS</h5>
//                 </div>
//                 <div className="card-body">
//                     <table className='w-100'>
//                         <tr>
//                             <td>Total Product Price</td>
//                             <td><i className="fa-solid fa-indian-rupee-sign mr-2"></i>{totalAmt}.00</td>
//                         </tr>
//                         <tr>
//                             <td>Delivery Charges</td>
//                             <td><i className="fa-solid fa-indian-rupee-sign mr-2"></i>00</td>
//                         </tr>
//                     </table>
//                 </div>
//                 <div className="card-footer bg-white">
//                     <table className='w-100'>
//                         <tr>
//                             <td>Order Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
//                             <td><i className="fa-solid fa-indian-rupee-sign mr-2"></i>{totalAmt}.00</td>
//                         </tr>
//                     </table>
//                 </div>
//             </div >
//         </>
//     )
// }

// export default Total
import React from 'react';

const Total = ({ totalAmt }) => {
    return (
        <div
            className="price-details-container mt-4 bg-white p-3"
            style={{
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                borderRadius: '8px',
                border: '1px solid #ddd'
            }}
        >
            <div
                className="header"
                style={{
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '10px',
                    marginBottom: '15px'
                }}
            >
                <h5 style={{ margin: 0, color: 'black' }}>PRICE DETAILS</h5>
            </div>

            <div className="details" style={{ marginBottom: '15px' }}>
                <div className="detail-item d-flex justify-content-between">
                    <span style={{ color: 'black' }}>Total Product Price</span>
                    <span style={{ color: 'black' }}>
                        <i className="fa-solid fa-indian-rupee-sign mr-2"></i>{totalAmt}.00
                    </span>
                </div>
                <div className="detail-item d-flex justify-content-between mt-2">
                    <span style={{ color: 'black' }}>Delivery Charges</span>
                    <span style={{ color: 'black' }}>
                        <i className="fa-solid fa-indian-rupee-sign mr-2"></i>00
                    </span>
                </div>
            </div>

            <div
                className="footer d-flex justify-content-between"
                style={{
                    borderTop: '1px solid #ddd',
                    paddingTop: '10px',
                    fontWeight: 'bold',
                    color: 'black'
                }}
            >
                <span className='text-black'>Order Total</span>
                <span className='text-black' >
                    <i className="fa-solid fa-indian-rupee-sign mr-2"></i>{totalAmt}.00
                </span>
            </div>
        </div>
    );
};

export default Total;
