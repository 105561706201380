import React from 'react'
import "../Css/style.css"
import { useEffect } from 'react';
const Whyus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="journey-section"  style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }}>
        <div className="container-fluid px-4 py-5">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="journey-text p-4 rounded shadow">
                <h3 className="mb-4 text-center text-success">Why Choose Biocyll Laboratories for Bulk and Contract Manufacturing? </h3>
                <h5 className=" mt-4 text-success"><b>Customized Solutions</b></h5>
                <p>
                  Tailored formulations to meet your brand's specific needs.
                </p>
                <h5 className=" mt-4 text-success"><b>Advanced R&D</b></h5>
                <p>
                  Cutting-edge research ensures superior quality and performance.
                </p>
                <h5 className=" mt-4 text-success"><b>Scalable Production</b></h5>
                <p>
                  From small batches to large volumes, we’ve got you covered.
                </p>
                <h5 className=" mt-4 text-success"><b>Stringent Quality Control</b></h5>
                <p>
                  Each product undergoes rigorous testing to ensure
                  compliance and efficacy.
                </p>
                <h5 className=' mt-4 text-success'> <b>Reliable Partner</b></h5>
                <p>Over a decade of experience catering to agri-input marketers and
                  distributors.</p>
                <b className="d-block my-3">Industries We Serve:</b>
                <ul className="list-unstyled">
                  <li><i className="fa fa-check-circle text-success"></i> Agri-input companies </li>
                  <li><i className="fa fa-check-circle text-success"></i> Organic farming solution providers </li>
                  <li><i className="fa fa-check-circle text-success"></i> Fertilizer and pesticide distributors</li>
                  <li><i className="fa fa-check-circle text-success"></i> Exporters of eco-friendly agricultural products</li>
                </ul>
                <h5 className=" mt-4 text-success"><b>Take the Next Step: Request a Sample Today! </b></h5>
                <p>
                  Partner with Biocyll Laboratories Pvt. Ltd. to deliver innovative, sustainable agricultural
                  solutions. Whether you're looking for bulk manufacturing, contract manufacturing, or
                  custom R&D services, we are here to help your brand grow.</p>
              </div>
            </div>
            <div className="col-md-10" >
              <div className="journey-text p-4 rounded shadow"  style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }}>
                <h3 className="mb-4 text-center text-success">Why Choose Biocyll for Contract R&D Services? </h3>
                <h5 className=" mt-4 text-success"><b>Innovative Research</b></h5>
                <p>
                Advanced facilities and expertise to develop high-performance agricultural inputs. 
                </p>
                <h5 className=" mt-4 text-success"><b>Custom Solutions</b></h5>
                <p>
                Tailored R&D for unique product formulations and applications.                </p>
                <h5 className=" mt-4 text-success"><b> Confidentiality Assured</b></h5>
                <p>
                Your intellectual property is protected with stringent confidentiality protocols.                 </p>
                <h5 className=" mt-4 text-success"><b>Regulatory Compliance</b></h5>
                <p>
                We ensure all products meet required safety and quality standards
                </p>
                <h5 className=' mt-4 text-success'> <b>Accelerated Time-to-Market</b></h5>
                <p>
                Rapid prototyping and testing for efficient </p>
                <b className="d-block my-3">Industries We Serve:</b>
                <ul className="list-unstyled">
                  <li><i className="fa fa-check-circle text-success"></i> Agricultural input manufacturers</li>
                  <li><i className="fa fa-check-circle text-success"></i> Organic farming solution providers </li>
                  <li><i className="fa fa-check-circle text-success"></i> Agri-biotech companies</li>
                  <li><i className="fa fa-check-circle text-success"></i> Exporters seeking innovative product lines </li>
                </ul>
                <h5 className=" mt-4 text-success"><b>Partner with Biocyll Laboratories for Advanced R&D </b></h5>
                <p>
                As a leader in Contract R&D services, Biocyll Laboratories Pvt. Ltd. is dedicated to driving 
                innovation in sustainable agriculture. Let us help you create the next generation of high performance agricultural products that align with your brand vision and market goals.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}

export default Whyus