




import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context/CreateContext';
import { base_url } from '../Config/Index';
import axios from 'axios';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';

const My_Orders = () => {
    const { token } = useContext(UserContext);
    const [orders, setOrders] = useState([]);
    // const [filteredOrders, setFilteredOrders] = useState([]);
  useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

   

    // useEffect(() => {
    //     setFilteredOrders(orders);
    // }, [orders]);

    // const myOrders = async () => {
    //     try {
    //         const response = await fetch(`${base_url}/api/order/get/user/`, {
    //             headers: {
    //                 Authorization: `${token}`
    //             }
    //         });
    //         const data = await response.json();
    //         setOrders(data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const getenquiry = () => {
        axios.get(`${base_url}/api/get/enquiry`, {
            headers: {
                Authorization: `${token}`
            }
        })
        .then(response => {
            console.log(response.data, "////// API Response"); // Log API response
            
            // Ensure response.data.data is an array before setting state
            if (Array.isArray(response.data.data)) {
                setOrders(response.data.data);
            } else {
                console.error("API did not return an array", response.data.data);
                setOrders([]); // Set to empty array if response is invalid
            }
        })
        .catch(error => console.log("Error fetching data:", error));
    };

    useEffect(() => {
        getenquiry();
    }, []);
    
    return (
        <>
     <section>
                <div className="container-fluid p-4">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="border p-3 rounded shadow-sm">
                                <ul className="list-group">
                                    {orders.map((order) => (
                                        <li key={order._id} className="list-group-item d-flex flex-column flex-sm-row align-items-center mb-3">
                                            <div className="productDetails">
                                           <p><strong>Product Name:</strong> {order.productName }</p>
                                                <p><strong>Message:</strong> {order.message }</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </>
    

    );
};

export default My_Orders;
