
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../Config/Index';
import AOS from 'aos'

const JobCard = ({ job }) => {
  const navigate = useNavigate();
 useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    })
  }, [])

  return (
    <div
      className="col-12 p-3 mb-3 bg-white"
      data-aos="zoom-in"
      style={{
        border: '1px solid green',
        boxShadow: 'rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px',
      }}
    >
      <div className="row">
        <div className="col-md-5">
          <p>{job.position}</p>
        </div>
        <div className="col-md-2">{job.Location}</div>
        <div className="col-md-3">{job.Status}</div>
        <div className="col-md-2">
          <button
            style={{ float: 'right' }}
            className="btn btn-sm btn-success"
            onClick={() => navigate(`/jobdetails/${job._id}`, { state: job })}
          >
            Know More
          </button>
        </div>
      </div>
    </div>
  );
};

const CurrentOpenings = () => {
  const [openings, setOpenings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOpenings = async () => {
      try {
        const response = await axios.get(`${base_url}/api/get/career`);
        setOpenings(response.data);
      } catch (err) {
        setError('Failed to fetch job openings. Please try again later.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOpenings();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", 
    }}>
            <h1 className='mb-4 text-center text-success p-4'>Current openings</h1>
      <div className="container-fluid" data-aos="zoom-in" >
        <div
          className=" mb-4 p-4"
          style={{
            backgroundColor: 'white',
            border: '1px solid #e0e0e0',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
          }}
        >
          <p className='text-justify'>
          Join our dynamic team and take your career to the next level! We are excited to announce several job openings across various departments. Whether you’re an experienced professional or just starting your career journey, we seek passionate individuals who thrive in a collaborative environment. Explore our current vacancies to find the perfect fit for your skills and aspirations. Be a part of our mission to innovate and excel—apply today!
          </p>
        </div>
        <div className="row">
          {openings.length > 0 ? (
            openings.map((job, index) => <JobCard key={index} job={job} />)
          ) : (
            <p className="col-12 text-center" style={{ fontStyle: 'Sans Serif', fontSize: '130%' }}>
              No job openings available.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default CurrentOpenings;
