
// // import React from 'react';
// // import tree from '../Assets/Images/tree.jpg';
// // import plant1 from "../Assets/Images/plant 1.jpg";
// // import plant2 from "../Assets/Images/plant2.jpeg";
// // import Plant3 from "../Assets/Images/Plant3.jpg";
// // import Plant4 from "../Assets/Images/Plant4.jpg";

// // const Harnessing = () => {
// //   return (
// //     <div className="harnessing-section" style={{ backgroundColor: "#90d576", boxShadow: '0 -4px 20px 0 #90d576' }}>
// //       <div className="container-fluid py-5">
// //         {/* Main Row Section */}
// //         <div className="row align-items-center mb-4">
// //           {/* Image Section */}
// //           <div className="col-md-6 mb-3 mb-md-0">
// //             <img
// //               src={tree}
// //               alt="Microbial Potential"
// //               className="img-fluid rounded shadow"
// //               style={{ width: "100%", height: "auto", maxHeight: "500px", margin: "20px 0" }}
// //             />
// //           </div>

// //           {/* Text Section */}
// //           <div className="col-md-6">
// //             <h3 className="mb-3">
// //               Harnessing the potential of microbes, we propel sustainable agriculture forward.
// //             </h3>
// //             <br />
// //             <p style={{ fontStyle: 'Sans Serif', fontSize: '130%', color: "white" }}>
// //               Crafted with high CFU Counts and extended shelf life, our innovative microbial formulations are tailored to bolster farmers in fostering sustainable development across the crop lifecycle. We aspire to transform the agricultural landscape by engineering products that surpass the efficacy of existing offerings in the market.
// //             </p>
// //           </div>
// //         </div>

// //         {/* Features Section */}
// //         <div className="p-4 rounded">
// //           <div className="row text-center">
// //             {/* Card 1 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={plant1} alt="Augmented Effectiveness" className="img-fluid" />
// //                 <h6 className="mt-3">Augmented Effectiveness</h6>
// //                 <p style={{ fontStyle: 'Sans Serif', fontSize: '100%' }}>
// //                   Discovering the most potent strains and amplifying their potency manifold.
// //                 </p>
// //               </div>
// //             </div>

// //             {/* Card 2 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={plant2} alt="Cutting-edge Microbial Solutions" className="img-fluid" />
// //                 <h6 className="mt-3">Cutting-edge Microbial Solutions</h6>
// //                 <p style={{ fontStyle: 'Sans Serif', fontSize: '100%' }}>
// //                   Manufacturing high CFU, contaminant-free, and enduring products in diverse forms (liquid, powder, granules, tablets, capsules).
// //                 </p>
// //               </div>
// //             </div>

// //             {/* Card 3 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={Plant3} alt="Extended Shelf Life" className="img-fluid" />
// //                 <h6 className="mt-3">Extended Shelf Life</h6>
// //                 <p style={{ fontStyle: 'Sans Serif', fontSize: '100%' }}>
// //                   Prolonged durability (up to 5 years depending on technology) even under adverse storage conditions.
// //                 </p>
// //               </div>
// //             </div>

// //             {/* Card 4 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={Plant4} alt="Resilience in Adverse Environments" className="img-fluid" />
// //                 <h6 className="mt-3">Resilience in Adverse Environments</h6>
// //                 <p style={{ fontStyle: 'Sans Serif', fontSize: '100%' }}>
// //                   Highly effective solutions in broad and unfavorable environmental conditions, including humidity, temperature fluctuations, and varying soil pH levels.
// //                 </p>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Harnessing;
// // import React from 'react';
// // import tree from '../Assets/Images/tree.jpg';
// // import plant1 from "../Assets/Images/plant 1.jpg";
// // import plant2 from "../Assets/Images/plant2.jpeg";
// // import Plant3 from "../Assets/Images/Plant3.jpg";
// // import Plant4 from "../Assets/Images/Plant4.jpg";
// // import "../Css/style.css";

// // const Harnessing = () => {
// //   return (
// //     <div className="harnessing-section" style={{fontSize:'115%'}}>
// //       <div className="container-fluid py-5">
// //         {/* Main Row Section */}
// //         <div className="row align-items-center mb-4">
// //           {/* Image Section */}
// //           <div className="col-md-6 mb-3 mb-md-0">
// //             <img
// //               src={tree}
// //               alt="Microbial Potential"
// //               className="img-fluid rounded shadow"
// //             />
// //           </div>

// //           {/* Text Section */}
// //           <div className="col-md-6">
// //             <h3 className="heading-text">
// //               Harnessing the potential of microbes, we propel sustainable agriculture forward.
// //             </h3>
// //             <br/>
// //             <p className="description-text ">
// //               Crafted with high CFU counts and extended shelf life, our innovative microbial formulations are tailored to bolster farmers in fostering sustainable development across the crop lifecycle. we aspire to transform the agricultural landscape by engineering products that surpass the efficacy of existing offerings in the market.
// //             </p>
// //           </div>
// //         </div>

// //         {/* Features Section */}
// //         <div className="p-4 rounded">
// //           <div className="row text-center">
// //             {/* Card 1 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={plant1} alt="Augmented Effectiveness" className="img-fluid" />
// //                 <h6>Augmented Effectiveness</h6>
// //                 <p className="card-text">
// //                   Discovering the most potent strains and amplifying their potency manifold.
// //                 </p>
// //               </div>
// //             </div>

// //             {/* Card 2 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={plant2} alt="Cutting-edge Microbial Solutions" className="img-fluid" />
// //                 <h6>Cutting-edge Microbial Solutions</h6>
// //                 <p className="card-text">
// //                   Manufacturing high CFU, contaminant-free, and enduring products in diverse forms.
// //                 </p>
// //               </div>
// //             </div>

// //             {/* Card 3 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={Plant3} alt="Extended Shelf Life" className="img-fluid" />
// //                 <h6>Extended Shelf Life</h6>
// //                 <p className="card-text">
// //                   Prolonged durability even under adverse storage conditions.
// //                 </p>
// //               </div>
// //             </div>

// //             {/* Card 4 */}
// //             <div className="col-12 col-md-6 col-lg-3 mb-4">
// //               <div className="card h-100 p-3">
// //                 <img src={Plant4} alt="Resilience in Adverse Environments" className="img-fluid" />
// //                 <h6>Resilience in Adverse Environments</h6>
// //                 <p className="card-text">
// //                   Highly effective solutions in broad and unfavorable environmental conditions.
// //                 </p>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Harnessing;
// import React from 'react';
// import tree from '../Assets/Images/tree.jpg';
// import plant1 from "../Assets/Images/plant 1.jpg";
// import plant2 from "../Assets/Images/plant2.jpeg";
// import Plant3 from "../Assets/Images/Plant3.jpg";
// import Plant4 from "../Assets/Images/Plant4.jpg";
// import "../Css/style.css";

// const Harnessing = () => {
//   return (
//     <div className="harnessing-section" style={{fontSize:'115%'}}>
//       <div className="container-fluid py-5">
//         {/* Main Row Section */}
//         <div className="row align-items-center mb-4">
//           {/* Image Section */}
//           <div className="col-md-6 mb-3 mb-md-0">
//             <div data-aos="zoom-in">
//               <img
//                 src={tree}
//                 alt="Microbial Potential"
//                 className="img-fluid rounded shadow"
//                 style={{height:'500px'}}
//               />
//             </div>
//           </div>

//           {/* Text Section */}
//           <div className="col-md-6 text-justify">
//             <h3 className="heading-text">
//               Harnessing the potential of microbes, we propel sustainable agriculture forward.
//             </h3>
//             <br />
//             <p className="description-text">
//               Crafted with high CFU counts and extended shelf life, our innovative microbial formulations are tailored to bolster farmers in fostering sustainable development across the crop lifecycle. We aspire to transform the agricultural landscape by engineering products that surpass the efficacy of existing offerings in the market.
//             </p>
//           </div>
//         </div>

//         {/* Features Section */}
//         <div className="p-4 rounded">
//           <div className="row text-center">
//             {/* Card 1 */}
//             <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in">
//               <div className="card h-100 p-3">
//                 <img
//                   src={plant1}
//                   alt="Augmented Effectiveness"
//                   className="img-fluid"
//                 />
//                 <h6>Augmented Effectiveness</h6>
//                 <p className="card-text">
//                   Discovering the most potent strains and amplifying their potency manifold.
//                 </p>
//               </div>
//             </div>

//             {/* Card 2 */}
//             <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in">
//               <div className="card h-100 p-3">
//                 <img
//                   src={plant2}
//                   alt="Cutting-edge Microbial Solutions"
//                   className="img-fluid"
//                 />
//                 <h6>Cutting-edge Microbial Solutions</h6>
//                 <p className="card-text">
//                   Manufacturing high CFU, contaminant-free, and enduring products in diverse forms.
//                 </p>
//               </div>
//             </div>

//             {/* Card 3 */}
//             <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in">
//               <div className="card h-100 p-3">
//                 <img
//                   src={Plant3}
//                   alt="Extended Shelf Life"
//                   className="img-fluid"
//                 />
//                 <h6>Extended Shelf Life</h6>
//                 <p className="card-text">
//                   Prolonged durability even under adverse storage conditions.
//                 </p>
//               </div>
//             </div>

//             {/* Card 4 */}
//             <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in">
//               <div className="card h-100 p-3">
//                 <img
//                   src={Plant4}
//                   alt="Resilience in Adverse Environments"
//                   className="img-fluid"
//                 />
//                 <h6>Resilience in Adverse Environments</h6>
//                 <p className="card-text">
//                   Highly effective solutions in broad and unfavorable environmental conditions.
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Harnessing;
import React from 'react'
import AOS from 'aos'
import { useEffect } from 'react';
function Harnessing() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    })
  }, [])
  return (
    <>
      <div className="row" style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}> 
        <div className="col-12 text-center mx-auto mb-4">
          <div className="p-5 headings">
            <h3>              Harnessing the potential of microbes, we propel sustainable agriculture forward.
</h3>
            <div className='responsive-text text-justify' data-aos="zoom-in">
              <p>
              Crafted with high CFU counts and extended shelf life, our innovative microbial formulations are tailored to bolster farmers in fostering sustainable development across the crop lifecycle. We aspire to transform the agricultural landscape by engineering products that surpass the efficacy of existing offerings in the market.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Harnessing
