import React, { useEffect, useState } from 'react'
import Slider1Explore from '../Component/Slider1Explore';
import Slider2Explore from '../Component/Slider2Explore';
import Journey from '../Component/Journey';
import Values from '../Component/Values';
import banner from '../Assets/Images/banner.jpg'
import { useParams } from 'react-router-dom';
import Explore3Slider from '../Component/Explore3Slider';

const Explore = () => {
    const{section} =useParams();
    const [currentComp, setCurrentComp] = useState(section || 'explore');

    useEffect(() => {
        setCurrentComp(section)
    }, [section])

    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                    </div>
                    <div className="row ab_bg pt-5 pl-3 pr-3 pb-5 background-image-opacity"style={{backgroundColor:'#90d576'}}>
                        <div className="col-3 p-3 bg-white " style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                            <hr style={{ backgroundColor: 'green' }} />
                            <div className={` col-12 ${currentComp === "experience" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("experience")}>12 years of Experince</div><hr style={{ backgroundColor: 'green' }} />
                            <div className={` col-12 ${currentComp === "revamping" ? "text-success font-weight-bold" : ""}  cursor-pointer`} onClick={() => setCurrentComp("revamping")}>Revamping agriculture with Microbial solutions </div><hr style={{ backgroundColor: 'green' }} />
                            <div className={` col-12 ${currentComp === "ourPremier" ? "text-success font-weight-bold" : ""}  cursor-pointer`} onClick={() => setCurrentComp("ourPremier")}>Our Premier Technology and Innovation Facility</div><hr style={{ backgroundColor: 'green' }} />                        
                            <div className={` col-12 ${currentComp === "values" ? "text-success font-weight-bold" : ""}  cursor-pointer`} onClick={() => setCurrentComp("values")}>Our Values</div><hr style={{ backgroundColor: 'green' }} />
                        </div>
                        <div className="col-9 p-4">
                            {currentComp == "experience" &&
                                <Slider1Explore />
                            }
                            {currentComp == "revamping" &&
                                <Slider2Explore />
                            }
                            {currentComp == "ourPremier" &&
                                <Explore3Slider />
                            }
                            {currentComp == "values" &&
                                <Values />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Explore
