import React from 'react';
import AOS from 'aos';
import { useEffect } from 'react';
import quality from "../Assets/Images/quality.JPG";
import quality2 from "../Assets/Images/quality2.JPG";

const Quality_comp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    });
  }, []);

  return (
    <>
      <section className='journey mb-4' style={{
            boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
      }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 d-flex flex-column align-items-center'>
              <img 
                src={quality} 
                alt='Quality Assurance 1' 
                style={{ width: '100%', maxWidth: '300px', borderRadius: '10px', marginBottom: '20px' }} 
                data-aos='fade-right'
              />
              <img 
                src={quality2} 
                alt='Quality Assurance 2' 
                style={{ width: '100%', maxWidth: '300px', borderRadius: '10px' }} 
                data-aos='fade-left'
              />
            </div>
            <div className='col-md-6'>
              <div className='journey-text text-justify'>
                <h1 className='mb-2 text-success text-center'>Quality Compliance</h1>
                <br />
                <h4 className='text-start'>Quality Assurance</h4>
                <br />
                <p className='' data-aos='fade-up'>
                  Our rigorous quality control procedures ensure that our microbial products align with client specifications, FCO, and CIB regulations. We ensure compliance through stringent protocols governing the handling and storage of microorganisms, continuous monitoring for contamination, adherence to various standards, and meticulous documentation practices.
                </p>
                <br />
                <h4 className='text-start'>Adherence to Quality Standards</h4>
                <br />
                <p className='' data-aos='fade-up'>
                  Given that biological products consist of living microorganisms, they demand highly specialized protocols for handling, storage, and application. Our top-notch Quality Policy guarantees the superior quality of our products. Our production procedures strictly adhere to various standards, incorporating clean room facilities, among other measures. We meticulously implement quality procedures to achieve a contamination-free product.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Quality_comp;
