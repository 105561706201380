// import React, { useEffect, useState } from 'react'
// import R_and_d from '../Component/R_and_d'
// import Production_fac from '../Component/Production_fac'
// import Research_farm from '../Component/Research_farm'
// import Quality_comp from '../Component/Quality_comp'
// import { useParams } from 'react-router-dom';
// import Explore3Slider from '../Component/Explore3Slider'
// import "../Css/style.css"
// const Innovation = () => {
//   const { section } = useParams();
//   const [currentComp, setCurrentComp] = useState(section || "r&d")

//   useEffect(() => {
//     setCurrentComp(section || "r&d")
//   }, [section])
//   return (
//     <>
//       <section>
//         <div className="container-fluid">
//           <div className="row">
//           </div>
//           <div className="row pt-2 pl-3 pr-3 pb-5 background-image-opacity" style={{ backgroundColor: '#e0f7e7' }}>
//             <div className="col-md-3 p-3 bg-white sidebar-fixed-height" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
//             <h5 className='text-center text-success font-weight-bold'>Innovations</h5>
//               {/* <div className={` col-12 ${currentComp === "slider3content" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("slider3content")}>Our premier technology and innovation facility </div><hr style={{ backgroundColor: 'green' }} /> */}
//               <div className={` col-12 ${currentComp === "r&d" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("r&d")}>R & D</div><hr style={{ backgroundColor: 'green' }} />
//               <div className={` col-12 ${currentComp === "production" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("production")}>Production Facility</div><hr style={{ backgroundColor: 'green' }} />
//               <div className={` col-12 ${currentComp === "quality" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("quality")}>Quality Compliance</div><hr style={{ backgroundColor: 'green' }} />
//               <div className={` col-12 ${currentComp === "research" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("research")}>Research Farm</div><hr style={{ backgroundColor: 'green' }} />
//             </div>
//             <div className="col-md-9 ">
//               {currentComp == "r&d" &&
//                 <R_and_d />
//               }
//               {currentComp == "production" &&
//                 <Production_fac />
//               }
//               {currentComp == "quality" &&
//                 <Quality_comp />
//               }
//               {currentComp == "research" &&
//                 <Research_farm />
//               }
//             </div>
//           </div>
//         </div>
//       </section >
//     </>
//   )
// }

// export default Innovation
import React, { useEffect, useState } from 'react'
import R_and_d from '../Component/R_and_d'
import Production_fac from '../Component/Production_fac'
import Research_farm from '../Component/Research_farm'
import Quality_comp from '../Component/Quality_comp'
import { useParams } from 'react-router-dom';
import Explore3Slider from '../Component/Explore3Slider'
import "../Css/style.css"
import biocyll_Logo from '../Assets/Images/biocyll_Logo.png'
import Certifications from '../Component/Certifications'

const Innovation = () => {
  const { section } = useParams();
  const [currentComp, setCurrentComp] = useState(section || "r&d")
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (

    // loading ? (
    //   <div
    //     className="d-flex justify-content-center align-items-center"
    //     style={{ height: '100vh' }}
    //   >
    //     <img src={biocyll_Logo} alt="Biocyll Logo" style={{ width: '150px', height: 'auto' }} />
    //   </div>
    // ) : (
      <>
        <div className="container">
          {/* <div className="fs-5">
            <R_and_d />
          </div> */}
          <div className="fs-5">
            <Production_fac />
          </div>
          <div className="fs-5">
            <Certifications />
          </div>
          <div className="fs-5">
            <Quality_comp />
          </div>
          <div className="fs-5">
            <Research_farm />
          </div>
        </div>
      </>
    // )
  );
};

export default Innovation;
