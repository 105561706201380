
import React, { useState, useEffect } from "react";
import boximage1 from "../Assets/Images/boximage1.jpeg"
import boximage2 from "../Assets/Images/boximage2.jpeg"
import boximage3 from "../Assets/Images/boximage3.jpeg"
import boximage4 from "../Assets/Images/boximage4.jpg"
import boximage5 from "../Assets/Images/boximage5.jpeg"
import boximage6 from "../Assets/Images/boximage6.jpeg"
const Slider = () => {
    const [selectedBox, setSelectedBox] = useState("box1"); // Initially set to box1
    const boxKeys = ["box1", "box2", "box3", "box4"]; // Keys for boxes

    // Text mapping for boxes
    const textContent = {
        box1: { title: "Superior Quality", description: "Superior Quality VAM for Sustainable Agriculture " },
        box2: { title: "Trusted Partner", description: "Trusted Partner for Bulk & Contract Manufacturing " },
        box3: { title: "Research and Innovation", description: "Backed by Advanced Research & Innovation " },
        box4: { title: "Experience the Difference", description: "Sample Available – Experience the Difference" },
    };

    // Background images for each box
    const backgroundImages = {
        box1: boximage6,
        box2: boximage4,
        box3: boximage2,
        box4: boximage1,
    };

    // Automatically change the selected box every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedBox((prevBox) => {
                const currentIndex = boxKeys.indexOf(prevBox);
                const nextIndex = (currentIndex + 1) % boxKeys.length;
                return boxKeys[nextIndex];
            });
        }, 3000);

        return () => clearInterval(interval);
    }, [boxKeys]);

    return (
        <div
            className="container-fluid text-center py-5 w-100"
            style={{
                backgroundImage: `url(${backgroundImages[selectedBox]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                transition: "background-image 1s ease-in-out", // Smooth transition effect
            }}
        >
            <div className="container-fluid text-center py-5 w-100">
                <div className="mb-3">
                    <h4 className="text-uppercase fw-semibold text-white">India’s Leading Mycorrhizae (VAM) Manufacturer
                    </h4>
                </div>
                <div className="mb-4 text-start text-white">
                    {selectedBox && (
                        <div className="d-flex flex-column align-items-start">
                            <h2 className="fw-bold text-uppercase m-0">{textContent[selectedBox].title}</h2>
                            <p className=" text-white m-0">{textContent[selectedBox].description}</p>
                        </div>
                    )}
                </div>


                {/* Boxes */}
                <div className="row">
                    {boxKeys.map((boxKey, index) => (
                        <div key={index} className="col-md-3">
                            <div
                                className={`p-4 border rounded text-center ${selectedBox === boxKey ? "bg-dark text-white" : "bg-light"
                                    }`}
                                onClick={() => setSelectedBox(boxKey)}
                                style={{ cursor: "pointer" }}
                            >
                                <h6>
                                    {boxKey === "box1"
                                        ? "Superior Quality"
                                        : boxKey === "box2"
                                            ? "Trusted Partner"
                                            : boxKey === "box3"
                                                ? "Research and Innovation"
                                                : boxKey === "box4"
                                                    ? "Experience the Difference"
                                                    : ""}
                                </h6>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Slider;
