import React, { useContext, useEffect, useState } from 'react'
import Nav from '../Component/Nav'
import DeliveryAddress from '../Component/DeliveryAddress';
import Total from '../Component/Total';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { UserContext } from '../Context/CreateContext';
import axios from 'axios';
import { base_url } from '../Config/Index';
import { base_url1 } from '../Config/Index';
// import "../Css/style.css"

const Checkout = () => {
    // const { id } = useParams();
    const { id } = useParams();

    const { user, token, login, logout } = useContext(UserContext);
    const [cartItems, setCartitems] = useState([])
    const [totalAmt, setTotalAmt] = useState()
    const [cardId, setCardId] = useState()
    const [userAdd, setUseradd] = useState([])
    const [addressToggle, setAddressToggle] = useState(false);
    const [orderToggle, setOrderToggle] = useState(false);
    const [paymentToggle, setPaymentToggle] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("")
    const [selectedAddress, setSelectedAddress] = useState("")
    const location = useLocation()
    const { productId } = location.state || {};
    const [Amount, SetAmount] = useState(null);
    const [ReferralCode, SetReferralCode] = useState(null);
    const [LoremIpsum, SetLoremIpsum] = useState(null);
    const whatsappNumber = localStorage.getItem("ApiWhatsappNumber")
    const [amount, setAmount] = useState('');
    const [order, setOrder] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);

    // console.log(location.state?.productId)
    // console.log(selectedAddress)
    // console.log(userAdd)
    // console.log(user) 
    // console.log(cartItems) 
    useEffect(() => {
        getCartItems()
    }, [])

    useEffect(() => {
        getAddress()
    }, [])
    const getCartItems = async () => {
        try {
            const response = await axios.get(`${base_url}/api/get/card`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            // console.log(response.data.items)
            if (location.state?.productId !== undefined) {
                const data = response.data.items.filter(item => item.product._id == location.state?.productId)
                setCartitems(data)
            } else {
                setCartitems(response.data.items)
            }
            setTotalAmt(response.data.total)
            setCardId(response.data._id)
        } catch (error) {
            console.log(error)
        }
    }
    // console.log(cartItems)
    const handleRemoveItem = async (productId) => {
        // console.log(productId)
        try {
            const response = await axios.delete(`${base_url}/api/delete/card/${productId}`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            // console.log(response)
            getCartItems()
        }
        catch (error) {
            console.log(error)
        }
    }
    const handlequantity = async (itemID, action) => {
        try {
            const response = await axios.put(
                `${base_url}/api/carts/${cardId}/items/${itemID}`,
                {
                    action: action
                },
                {
                    headers: {
                        Authorization: `${token}`
                    }
                }
            );
            // console.log(response) 
            if (response.status == 200) {
                getCartItems()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAddress = async () => {
        try {
            const response = await axios.get(`${base_url}/api/get/user/address`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            // console.log(response.data[0].address)
            setUseradd(response.data)
            setSelectedAddress(response.data[0].address)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async (addressId) => {
        try {
            const response = await axios.delete(`${base_url}/api/delete/address/${addressId}`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            // console.log(response)
            getAddress()
            alert("Are you sure ?")
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit = async (addressId) => {
        try {
            const response = await axios.put(`${base_url}/api/update/address/${addressId}`, {
                headers: {
                    Authorization: `${token}`
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        script.onload = () => {
            console.log("Razorpay SDK Loaded");
        };
        document.body.appendChild(script);
    }, []);
    useEffect(() => {
        // document.body.style.backgroundImage = "linear-gradient(to top, #35BCCB, #19155F)";
    }, []);

    const handleSubmit = async () => {
        if (typeof window.Razorpay === "undefined") {
            alert("Razorpay SDK not loaded. Please check your internet connection.");
            return;
        }

        // const options = {
        //     key: "rzp_live_lkuqq6mCeVxpCU",
        //     amount: 50000, // Amount in paise (₹500)
        //     currency: "INR",
        //     name: "Your Company Name",
        //     description: "Test Payment",
        //     handler: async function (response) {
        //         console.log("Payment Success:", response);

        //         try {
        //             const res = await fetch(`${base_url1}capture-payment`, {
        //                 method: "POST",
        //                 headers: { "Content-Type": "application/json" ,
        //                      "Authorization": `Bearer ${token}`
        //                 },
        //                 body: JSON.stringify({
        //                     paymentId: response.razorpay_payment_id,
        //                     signature: response.razorpay_signature,
        //                     amount: 50000, // In paise
        //                 }),
        //             });

        //             const data = await res.json();

        //             if (data.status === "captured") {
        //                 console.log("Payment Captured:", data);
        //             } else {
        //                 console.log("Payment Not Captured");
        //             }
        //         } catch (error) {
        //             console.error("Error capturing payment:", error);
        //         }
        //     },
        // };

        // const rzp = new window.Razorpay(options);
        // rzp.open();
        // const handlePay = async () => {
        const response = await axios.post(`${base_url}/api/order/create-razorpay-order`, { amount });
        console.log(response.data.status.id,"respppppp")
        setOrder(response.data);
        const options = {
            key: "rzp_test_wb1qzyNMKkyMw1"            ,
            amount: response.data.amount,
            currency: response.data.currency,
            name: 'Your Company Name',
            description: 'Test Transaction',
            order_id: response.data.id,
            handler: async (response) => {
                const verifyResponse = await axios.post(`${base_url}/api/order/verify-razorpay`, response);
                setPaymentStatus(verifyResponse.data.status);
            },
            prefill: {
                name: 'Your Name',
                email: 'your@example.com',
                contact: '9999999999',
            },
            notes: {
                address: 'Razorpay Corporate Office',
            },
            theme: {
                color: '#3399cc',
            },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
        //   };
    };
    const handleRadioChange = (event) => {
        const selectedMethod = event.target.value;

        // if (selectedMethod === "Net Banking") {
            if (selectedMethod === "Net Banking" || selectedMethod === "UPI" || selectedMethod === "Card") {
            // handleSubmit(); 
              setShowModal(true);
        }
    };
    const placeOrder = async () => {
        if (userAdd.length == 0) {
            alert("Please Add Address")
            return;
        }
        const payload = {
            userId: user._id,
            products: cartItems.map(item => ({
                productId: item.product._id,
                quantity: item.quantity,
                price: item.product.price,
                status: "Pending"
            })),
            paymentMethod: paymentMethod,
            address: userAdd[0]._id
        };

        // console.log("Order Payload:", payload);

        try {
            const response = await axios.post(`${base_url}/api/order/place`, payload, {
                headers: {
                    Authorization: `${token}`
                }
            });
            console.log("Place Order Response:", response.data);
            // alert(response.data.message)
            alert(response.data.msg)
        } catch (error) {
            console.error("Error placing order:", error);
            alert(error.response.data.message)
        }
    };
    const handleclear = ()=>{
        setAmount ('')
        setOrder ('')
    }
    return (
        <>
            {/* <Nav ></Nav> */}
            <section>
                <div className="container-fluid p-5">
                    <div className="row">
                        <div className="col-md-8" style={{ backgroundColor: 'aliceblue' }}>
                            <div className="step-container w-100 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: '10px' }}>
                                <div className="step-body">
                                    <div className="row">
                                        <div className='col-1'>
                                            <div className='bg-success text-white' style={{ border: '1px solid', height: 30, width: 30, textAlign: 'center', borderRadius: '50%' }}>
                                                1
                                            </div>
                                        </div>
                                        <div className="col-11">
                                            <h6>User <i className="fa-solid fa-check font-weight-bold ml-3 text-success"></i></h6>
                                        </div>
                                        <div className="col-1"></div>
                                        <div className="col-11">
                                            {user.mobileNo}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="step-container w-100 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: '10px' }}>
                                <div className="step-body">
                                    <div className="row">
                                        <div className='col-1'>
                                            <div className='bg-success text-white' style={{ border: '1px solid', height: 30, width: 30, textAlign: 'center', borderRadius: '50%' }}>
                                                2
                                            </div>
                                        </div>
                                        <div className="col-11"><h6>Select Delivery Address</h6></div>
                                        <div className="col-1"></div>
                                        <div className="col-11">
                                            {userAdd?.map((item, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                            <div className='ml-3'>
                                                                <input
                                                                    type='radio'
                                                                    name='address'
                                                                    value={item.address}
                                                                    checked={selectedAddress === item.address}
                                                                    onChange={(e) => setSelectedAddress(e.target.value)}
                                                                    style={{ height: "15px", width: "15px", marginRight: "5px", marginTop: "10px" }}
                                                                />
                                                                <label htmlFor="checkbox">{item?.address}</label>
                                                            </div>
                                                            <button className='btn btn-outline-danger p-1' style={{ alignSelf: 'end' }} onClick={() => handleDelete(item._id)}>
                                                                <i className="fa-solid fa-trash ml-1 mr-1"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="step-container w-100 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: '10px' }}>
                                <div className="step-body">
                                    <div className="row">
                                        <div className="col-12 text-primary" onClick={() => setAddressToggle(!addressToggle)}>
                                            Add a new address +
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                addressToggle &&
                                <DeliveryAddress getAddress={getAddress} setAddressToggle={setAddressToggle} />
                            }


                            <div className="step-container" onClick={() => setOrderToggle(!orderToggle)}>
                                <div className="step-body">
                                    <div className="row">
                                        <div className='col-1'>
                                            <div className='bg-success text-white' style={{ border: '1px solid ', height: 30, width: 30, textAlign: 'center' }}>
                                                3
                                            </div>
                                        </div>
                                        <div className="col-11"><h6> Order summary </h6></div>
                                        <div className="col-1"></div>
                                        <div className="col-11">
                                            <p>{cartItems.length} items</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {orderToggle &&
                                <div className="step-container">
                                    <div className="step-body">
                                        <div className="step-container">
                                            <div className="step-body">
                                                <div className="row">
                                                    <div className='col-1'>
                                                        <div className='bg-success text-white' style={{ border: '1px solid', height: 30, width: 30, textAlign: 'center' }}>
                                                            3
                                                        </div>
                                                    </div>
                                                    <div className="col-11">
                                                        <h6>Order summary</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {cartItems?.map((item, index) => {
                                            return (
                                                <div className="step-container mt-4 mb-5 p-2" key={index} style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                                    <div className="step-body">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className="col-12" style={{ height: 140, borderRadius: 20 }}>
                                                                    <img src={item?.product?.images[0].url} alt={item?.product?.title} className='w-75 h-100' style={{ borderRadius: 20 }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-5">
                                                                <h6>{item?.product?.title}</h6>
                                                                <p className='text-success'><i className="fa-solid fa-indian-rupee-sign"></i>{item?.product?.price}</p>
                                                                <p>{item?.product?.Category}</p>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className="col-12 text-center ">
                                                                    <button onClick={() => handlequantity(item._id, "decrement")} className='btn btn-outline-success'>-</button>&nbsp;&nbsp; {item.quantity}&nbsp;&nbsp; <button className='btn btn-outline-success' onClick={() => handlequantity(item._id, "increment")}>+</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-1">
                                                                <button onClick={() => handleRemoveItem(item._id)} className='btn btn-outline-danger'><i className="fa-solid fa-trash"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }

                            <div className="step-container" onClick={() => setPaymentToggle(!paymentToggle)}>
                                <div className="step-body">
                                    <div className="row">
                                        <div className='col-1'>
                                            <div className='bg-success text-white' style={{ border: '1px solid ', height: 30, width: 30, textAlign: 'center' }}>
                                                4
                                            </div>
                                        </div>
                                        <div className="col-11"><h6> Payment Options</h6></div>
                                    </div>
                                </div>
                            </div>

                            {paymentToggle &&
                                <div className="step-container">
                                    <div className="step-body">
                                        <div className="step-container">
                                            <div className="step-body">
                                                <div className="row">
                                                    <div className='col-1'>
                                                        <div className='bg-success text-white' style={{ border: '1px solid', height: 30, width: 30, textAlign: 'center' }}>
                                                            4
                                                        </div>
                                                    </div>
                                                    <div className="col-11">
                                                        <h6>Payment Options</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="payment-options">
                                                <div className="row">
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div className='ml-3 mt-3 mr-4'>
                                                            <input
                                                                type='radio'
                                                                name='paymethod'
                                                                value="Card"
                                                                onChange={handleRadioChange}
                                                                style={{ height: "15px", width: "15px", marginRight: "5px", marginTop: "10px" }}
                                                            />
                                                            <label htmlFor="checkbox">Credit/Debit/ATM Card</label>
                                                        </div>
                                                        <div className='ml-3 mt-3 mr-4'>
                                                            <input
                                                                type="radio"
                                                                name='paymethod'
                                                                value="Net Banking"
                                                                onChange={handleRadioChange}
                                                                style={{ height: "15px", width: "15px", marginRight: "5px" }}
                                                            />
                                                            <label htmlFor="checkbox">Net Banking</label>
                                                        </div>
                                                         {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 p-4">
          <div className="bg-white p-4 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-bold mb-4">Payment Form</h3>

            <input
              type="number"
              placeholder="Enter Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="border p-2 w-full mb-4"
            />

            <button
              onClick={handleSubmit}
              className="bg-blue-500 px-4 py-2 rounded-md w-full"
            >
              Pay Now
            </button>

            {order && (
              <div className="mt-4">
                <h6 className="text-lg font-semibold">Order ID: {order.status.id}</h6>
                <h6 className="text-lg">Amount: {order.status.amount / 100} INR</h6>
              </div>
            )}

            {paymentStatus && (
              <h3 className="text-lg mt-2 text-green-600">Payment Status: {paymentStatus}</h3>
            )}

            <button
              onClick={() => {setShowModal(false)
                handleclear()}}
              className="mt-4 bg-red-500 px-4 py-2 rounded-md w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
                                                        <div className='ml-3 mt-3 mr-4'>
                                                            <input
                                                                type="radio"
                                                                name='paymethod'
                                                                value="UPI"
                                                                onChange={handleRadioChange}
                                                                style={{ height: "15px", width: "15px", marginRight: "5px" }}
                                                            />
                                                            <label htmlFor="checkbox">UPI</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <button className='text-black'>Make Payment </button> */}
                                        </div>

                                    </div>
                                </div>
                            }

                            <div className="container mt-4 mb-5 p-4">
                                <div className="row">
                                    <div className="col-12 text-center text-lg-end">
                                        <button
                                            className="btn btn-lg btn-success"
                                            style={{ width: "100%", maxWidth: "250px" }}
                                            onClick={() => {
                                                placeOrder();
                                                // handleSubmit();
                                            }}
                                        >
                                            Place your order
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 ">
                            <Total totalAmt={totalAmt}></Total>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Checkout
