
import React, { useContext, useEffect, useState } from "react";
import Nav from "../Component/Nav";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import { base_url } from "../Config/Index";
import axios from "axios";
import { UserContext } from "../Context/CreateContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactModal from "react-modal";
import { useNavigate, useParams } from 'react-router-dom';
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Product_page = () => {
    const { user, token, login, logout } = useContext(UserContext);
    const [categories, setCategories] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedCategory, setSelectedcategory] = useState("");
    const [selectedProductType, setSelectedProductType] = useState("");
    const [openCategories, setOpenCategories] = useState(false);
    const [openProductType, setOpenProductType] = useState(false);
    const [priceRange, setPriceRange] = useState("");
    const [productType, setProductType] = useState(["Liquid", "Powder", "Granular"]);
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('')
    const [error, setError] = useState('');
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState("");
    const [message, setMessage] = useState("");
    const filteredProducts = product.filter(item =>
        item.category === selectedCategory || selectedCategory === ''
    );

    console.log(product, "product")
    useEffect(() => {
        getAllProducts();
    }, [selectedCategory, selectedProductType, priceRange]);

    useEffect(() => {
        getCategories();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // Handle Add to Cart
    const handleCart = async (id) => {
        if (!token) {
            navigate("/login");
            return;
        }
        const payload = { product: id, quantity: 1 };
        try {
            await axios.post(`${base_url}/api/post/card`, payload, {
                headers: { Authorization: `${token}` }
            });
            navigate("/checkout", { state: { productId: id } });
        } catch (error) {
            console.error('Error during buy now:', error);
        }
    };



    // Fetch Products
    async function getAllProducts() {
        setLoading(true);
        try {
            const response = await axios.get(
                `${base_url}/api/get/all/products?categoryId=${selectedCategory}&type=${selectedProductType}&priceRange=${priceRange}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );
            console.log(response.data, "response")
            setProduct(response.data);
            if (response.data.length === 0) {
            }
        } catch (error) {
            console.error("Error:", error);
            setProduct([]);
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    }
    // Fetch Categories
    const getCategories = async () => {
        try {
            const response = await axios.get(`${base_url}/api/getall/categories`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error:", error);
        }
    };
    // buy now
    const handleBuyNow = async (product_id) => {
        if (!token) {
            navigate("/login");
            return;
        }
        const payload = { product: product_id, quantity: 1 };
        console.log("payload", payload)
        try {
            const response = await axios.post(`${base_url}/api/post/card`, payload, {
                headers: { Authorization: `${token}` }
            }).then((response) => {
                console.log("res", response)
                toast.success("Item Added Successfully");
                navigate("/cart");
            })
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!address || !email || !name) {
            setError("All fields are required");
            return;
        }
        setError("");
        try {
            const response = await axios.post(`${base_url}/send-otp`, {
                email,
            });
            console.log(response.data);
        } catch {
            console.error(error);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault(); // Prevents page refresh
    
        const formData = new FormData(event.target);
        const payload = {
            fullName: formData.get("fullName"),
            phone: formData.get("phone"),
            email: formData.get("email"),
            companyName: formData.get("companyName"),
            message: formData.get("message"),
            productName: formData.get("productName"),
        };
    
        // Enquiry API call
        axios.post(`${base_url}/api/post/add/enquiry`, payload, {
            headers: { Authorization: `${token}` },
        })
        .then(() => {
            toast.success("Enquiry submitted successfully!");
            event.target.reset(); // Reset form after successful submission
        })
        .catch((error) => {
            console.error("Error during enquiry submission:", error);
            toast.error("Failed to submit enquiry. Please try again.");
        });
    }
    return (
        <>
            <ToastContainer />
            <section className="pt-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className="custom-container " style={{ border: "1px solid #ddd", borderRadius: "8px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                                        <div className="custom-header" style={{ padding: "10px", borderRadius: "8px 8px 0 0" }}>
                                            <h5>Filters</h5>
                                        </div>
                                        <div className="custom-body" style={{ padding: "15px" }}>
                                            <div
                                                className="row mb-3 align-items-center p-2"
                                                onClick={() => setOpenCategories(!openCategories)}
                                                style={{
                                                    backgroundColor: "#f5f5f5",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <div className="col-8">Categories</div>
                                                <div className="col-4 text-end">
                                                    <i className={`fa-solid fa-angle-${openCategories ? "up" : "down"}`}></i>
                                                </div>
                                            </div>
                                            {openCategories &&
                                                categories?.map((item) => (
                                                    <p
                                                        key={item._id}
                                                        onClick={() => {
                                                            setSelectedcategory(item._id);
                                                        }}
                                                        style={{
                                                            color: selectedProductType === product ? "black" : "black",
                                                            cursor: "pointer",
                                                            marginLeft: "80px",
                                                        }}
                                                    >
                                                        {item.category}
                                                    </p>
                                                ))}
                                            <hr />
                                            <div
                                                className="row mb-3 align-items-center p-2"
                                                onClick={() => setOpenProductType(!openProductType)}
                                                style={{
                                                    backgroundColor: "#f5f5f5",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <div className="col-8">Product Type</div>
                                                <div className="col-4 text-end">
                                                    <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                            </div>
                                            {/* product type filter */}
                                            <div
                                                className="row align-items-center"
                                                onClick={() => setOpenProductType(!openProductType)}
                                            >
                                                <div className="col-4 text-end">
                                                </div>
                                            </div>
                                            {openProductType &&
                                                productType.map((product) => (
                                                    <p
                                                        key={product}
                                                        onClick={() => setSelectedProductType(product)}
                                                        style={{
                                                            color: selectedProductType === product ? "black" : "black",
                                                            cursor: "pointer",
                                                            marginLeft: "80px",
                                                        }}
                                                    >
                                                        {product}
                                                    </p>
                                                ))}
                                            <hr />

                                            {/* <div className="row mb-2">
                                                <div className="col-12 mb-2">Price</div>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check ms-2">
                                                        <input
                                                            type="checkbox"
                                                            id="highToLow"
                                                            className="form-check-input"
                                                            checked={priceRange === "desc"}
                                                            onChange={() => setPriceRange("desc")}
                                                            style={{ height: "15px", width: "15px", cursor: "pointer" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="highToLow">
                                                            High to Low
                                                        </label>
                                                    </div>
                                                    <div className="form-check ms-2">
                                                        <input
                                                            type="checkbox"
                                                            id="lowToHigh"
                                                            className="form-check-input"
                                                            checked={priceRange === "asc"}
                                                            onChange={() => setPriceRange("asc")}
                                                            style={{ height: "15px", width: "15px", cursor: "pointer" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="lowToHigh">
                                                            Low to High
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 col-md-9">
                                    {loading ? (
                                        <div className="text-center mt-5">Loading...</div>
                                    ) : product.length === 0 ? (
                                        <div className="text-center mt-5" style={{ fontSize: '18px', color: 'red' }}>
                                            No products found for the selected category.
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {product.map((item, index) => (
                                                <div className="col-12 mb-4" key={index}>
                                                    <div className="d-flex flex-column flex-md-row bg-white border rounded p-3 align-items-start align-items-md-center">
                                                        {/* Product Image */}
                                                        <div
                                                            className="product-image d-flex flex-column justify-content-center align-items-center mb-3 mb-md-0"
                                                            style={{ width: '100%', maxWidth: '200px', padding: '10px' }}
                                                        >
                                                            <NavLink to={`/product_details/${item?._id}`} className="product-link">
                                                                <div className="product-image-container">
                                                                    <img
                                                                        src={item.images[0]?.url}
                                                                        alt={item.title}
                                                                        className="img-fluid"
                                                                        style={{
                                                                            maxHeight: '250px',
                                                                            objectFit: 'contain',
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </NavLink>
                                                            {/* <div
                                                                className="product-price mt-2"
                                                                style={{
                                                                    color: 'green',
                                                                    fontSize: '18px',
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                ₹{item.price}
                                                            </div> */}
                                                        </div>

                                                        {/* Product Description */}
                                                        <div className="product-description ms-0 ms-md-3" style={{ flex: '1', padding: '10px' }}>
                                                            <h5 className="fw-bold">{item.title}</h5>
                                                            <p className="text-muted mb-2">
                                                                {item.description}{" "}
                                                                <NavLink
                                                                    to={`/product_details/${item._id}`}
                                                                    className="btn btn-link text-primary p-0"
                                                                    style={{ fontSize: '14px' }}
                                                                >
                                                                    <b><h5>Know More</h5></b>
                                                                </NavLink>
                                                            </p>


                                                            <div className="d-flex flex-wrap gap-2">
                                                                {/* <button
                                                                    className="btn bg-success text-white"
                                                                    style={{
                                                                        border: 0,
                                                                        padding: '5px 10px',
                                                                        height: '30px',
                                                                        fontSize: '14px',
                                                                    }}

                                                                    onClick={() => handleBuyNow(item._id)}
                                                                >
                                                                    Add to cart
                                                                </button> */}
                                                                {/* <button
                                                                    className="btn bg-success text-white"
                                                                    style={{
                                                                        border: 0,
                                                                        padding: '5px 10px',
                                                                        height: '30px',
                                                                        fontSize: '14px',
                                                                    }}
                                                                    onClick={() => handleCart(item._id)}
                                                                >
                                                                    Buy Now
                                                                </button> */}


                                                                {showModal && (
                                                                    <div
                                                                        className="modal show"
                                                                        style={{
                                                                            display: "block",
                                                                            position: "fixed",
                                                                            top: 0,
                                                                            left: 0,
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            zIndex: 5000,
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="modal-dialog"
                                                                            style={{
                                                                                marginTop: "10%",
                                                                                zIndex: 50000,
                                                                            }}
                                                                        >
                                                                            <div className="modal-content">
                                                                                <div
                                                                                    className="modal-header"
                                                                                    style={{ backgroundColor: "#1F75FE" }}
                                                                                >
                                                                                    <h5 className="modal-title" style={{ textAlign: "center" }}>
                                                                                        Biocyll
                                                                                    </h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="close"
                                                                                        aria-label="Close"
                                                                                        onClick={() => setShowModal(false)}
                                                                                    >
                                                                                        <span aria-hidden="true">&times;</span>
                                                                                    </button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <form onSubmit={handleFormSubmit}>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="address">Delivery Address</label>
                                                                                            <input
                                                                                                type="text"
                                                                                                id="address"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Delivery Address"
                                                                                                value={address}
                                                                                                onChange={(e) => setAddress(e.target.value)}
                                                                                                required
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="email">Email</label>
                                                                                            <input
                                                                                                type="email"
                                                                                                id="email"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Email Address"
                                                                                                value={email}
                                                                                                onChange={(e) => setEmail(e.target.value)}
                                                                                                required
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="name">Name</label>
                                                                                            <input
                                                                                                type="text"
                                                                                                id="name"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Name"
                                                                                                value={name}
                                                                                                onChange={(e) => setName(e.target.value)}
                                                                                                required
                                                                                            />
                                                                                        </div>
                                                                                        {error && (
                                                                                            <div className="text-danger mt-2">{error}</div>
                                                                                        )}
                                                                                        {successMessage && (
                                                                                            <div className="text-success mt-2">{successMessage}</div>
                                                                                        )}
                                                                                        <div className="modal-footer">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-secondary"
                                                                                                onClick={() => setShowModal(false)}
                                                                                            >
                                                                                                Close
                                                                                            </button>
                                                                                            <button type="submit" className="btn btn-primary">
                                                                                                Submit
                                                                                            </button>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <button
                                                                    className="btn bg-success text-white"
                                                                    style={{
                                                                        border: 0,
                                                                        padding: '5px 10px',
                                                                        height: '30px',
                                                                        fontSize: '14px',
                                                                    }}
                                                                    onClick={openModal}
                                                                >
                                                                    Enquire Now
                                                                </button>
                                                            </div>
                                                            {/* <div className="d-flex justify-content-end mt-2">
                                                                <NavLink
                                                                    to={`/product_details/${item._id}`}
                                                                    className="btn btn-link text-primary p-0"
                                                                    style={{ fontSize: '14px' }}
                                                                >
                                                                    View More
                                                                </NavLink>
                                                            </div> */}
                                                           <ReactModal
                                                                                       isOpen={modalIsOpen}
                                                                                       onRequestClose={closeModal}
                                                                                       contentLabel="Add Your Review"
                                                                                       style={{
                                                                                           overlay: {
                                                                                               backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                                               zIndex: 1050
                                                                                           },
                                                                                           content: {
                                                                                               width: '600px',
                                                                                               margin: 'auto',
                                                                                               height: 'auto',
                                                                                               padding: '20px',
                                                                                               borderRadius: '10px',
                                                                                               zIndex: 1100
                                                                                           }
                                                                                       }}
                                                                                   >
                                                                                       <div className="modal-content" style={{ border: 0 }}>
                                                                                           <div className="">
                                                                                               <div className="modal-header p-2" style={{ border: 0 }}>
                                                                                                   <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                                                                                       <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
                                                                                                   </button>
                                                                                               </div>
                                                                                               <h5 className="text-center mb-3">Add Your Enquiry</h5>
                                                                                               <div className="col-md-12 bg-light p-4">
                                                                                               <form onSubmit={handleSubmit}>
                                                                       <div className="row">
                                                                           <div className="col-md-6 col-sm-12 mt-3">
                                                                               Full Name:
                                                                               <input type="text" placeholder="Enter Your Name" className="form-control" name="fullName" required />
                                                                           </div>
                                                                           <div className="col-md-6 col-sm-12 mt-3">
                                                                               Phone:
                                                                               <input type="text" placeholder="Enter Your Phone Number" className="form-control" name="phone" required />
                                                                           </div>
                                                                           <div className="col-md-6 col-sm-12 mt-3">
                                                                               Email:
                                                                               <input type="email" placeholder="Enter Your Email" className="form-control" name="email" required />
                                                                           </div>
                                                                           <div className="col-md-6 col-sm-12 mt-3">
                                                                               Company Name:
                                                                               <input type="text" placeholder="Enter Your Company Name" className="form-control" name="companyName" />
                                                                           </div>
                                                                           <div className="col-md-6 col-sm-12 mt-3">
                                                                               Product Name:
                                                                               <input type="text" placeholder="Enter Product Name" className="form-control" name="productName" />
                                                                           </div>
                                                                           <div className="col-12 mt-3">
                                                                               Message:
                                                                               <textarea placeholder="Message" className="form-control" name="message" style={{ height: "100px" }} required />
                                                                           </div>
                                                                           <div className="col-12 mt-3 text-center">
                                                                               <button type="submit" className="btn btn-success btn-lg">Submit</button>
                                                                               <button type="button" className="btn btn-danger btn-lg ml-2" onClick={closeModal}>Cancel</button>
                                                                           </div>
                                                                       </div>
                                                                   </form>
                                                                                               </div>
                                                                                           </div>
                                                                                       </div>
                                                                                   </ReactModal>

                                                        </div>


                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Product_page;
