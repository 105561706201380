// import React, { useContext, useEffect, useState } from 'react';
// import { NavLink, useParams } from 'react-router-dom';
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const CategoryWiseProducts = () => {
//     const { categoryId } = useParams(); // categoryId is fetched from URL
//     const { token } = useContext(UserContext);
//     const [categories, setCategories] = useState([]);
//     const [product, setProduct] = useState([]);
//     const [selectedCategory, setSelectedcategory] = useState("");
//     const [selectedProductType, setSelectedProductType] = useState("");
//     const [openCategories, setOpenCategories] = useState(false);
//     const [openProductType, setOpenProductType] = useState(false);
//     const [priceRange, setPriceRange] = useState("");
//     const [productType] = useState(["Liquid", "Powder"]);
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         getAllProducts(); // Call the function to fetch products whenever categoryId changes
//     }, [categoryId]);

//     useEffect(() => {
//         getCategories();
//     }, []);

//     // Handle Add to Cart
//     const handleCart = async (productId) => {
//         const payload = {
//             product: productId,
//             quantity: 1,
//         };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: {
//                     Authorization: `${token}`,
//                 },
//             });
//             toast.success('Item Added Successfully');
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     // Fetch Products based on categoryId
//     const getAllProducts = async () => {
//         setLoading(true);
//         try {
//             const response = await axios.get(
//                 `${base_url}/api/get/all/products?categoryId=${categoryId}`, // Ensure categoryId is passed in URL
//                 {
//                     headers: {
//                         Authorization: `${token}`,
//                     },
//                 }
//             );
//             setProduct(response.data);
//             if (response.data.length === 0) {
//                 alert("No Data Found For This Category");
//             }
//         } catch (error) {
//             if (error.response) {
//                 console.error("Error:", error.response.status, error.response.data);
//             } else if (error.request) {
//                 console.error("No response from the server");
//             } else {
//                 console.error("Error:", error.message);
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Fetch Categories
//     const getCategories = async () => {
//         try {
//             const response = await axios.get(`${base_url}/api/getall/categories`, {
//                 headers: {
//                     Authorization: `${token}`,
//                 },
//             });
//             setCategories(response.data);
//         } catch (error) {
//             if (error.response) {
//                 console.error("Error:", error.response.status, error.response.data);
//             } else if (error.request) {
//                 console.error("No response from the server");
//             } else {
//                 console.error("Error:", error.message);
//             }
//         }
//     };

//     return (
//         <>
//             <ToastContainer />
//             <section className="pt-5">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-12 text-center">
//                             <h3>All Products</h3>
//                         </div>
//                         <div className="col-12">
//                             <div className="row">
//                                 <div className="col-12 col-md-3 col-lg-3">
//                                     {/* Filter Section */}
//                                     <div className="card mt-4">
//                                         <div className="card-header">
//                                             <h5>Filters</h5>
//                                         </div>
//                                         <div className="card-body">
//                                             {/* Category Filter */}
//                                             <div
//                                                 className="row mb-2 align-items-center"
//                                                 onClick={() => setOpenCategories(!openCategories)}
//                                             >
//                                                 <div className="col-8">Categories</div>
//                                                 <div className="col-4 text-end">
//                                                     <i className="fa-solid fa-angle-down"></i>
//                                                 </div>
//                                             </div>
//                                             {openCategories &&
//                                                 categories?.map((item) => (
//                                                     <p
//                                                         key={item._id}
//                                                         onClick={() => setSelectedcategory(item._id)}
//                                                         style={{
//                                                             color: selectedCategory === item._id ? "red" : "black",
//                                                             cursor: "pointer",
//                                                         }}
//                                                     >
//                                                         {item.category}
//                                                     </p>
//                                                 ))}
//                                             <hr />
//                                             {/* Product Type Filter */}
//                                             <div
//                                                 className="row align-items-center"
//                                                 onClick={() => setOpenProductType(!openProductType)}
//                                             >
//                                                 <div className="col-8 mb-2">Product Type</div>
//                                                 <div className="col-4 text-end">
//                                                     <i className="fa-solid fa-angle-down"></i>
//                                                 </div>
//                                             </div>
//                                             {openProductType &&
//                                                 productType.map((product) => (
//                                                     <p
//                                                         key={product}
//                                                         onClick={() => setSelectedProductType(product)}
//                                                         style={{
//                                                             color: selectedProductType === product ? "red" : "black",
//                                                             cursor: "pointer",
//                                                         }}
//                                                     >
//                                                         {product}
//                                                     </p>
//                                                 ))}
//                                             <hr />
//                                             {/* Price Range Filter */}
//                                             <div className="row">
//                                                 <div className="col-12 mb-2">Price</div>
//                                                 <div className="d-flex flex-column">
//                                                     <div className="form-check ms-2">
//                                                         <input
//                                                             type="checkbox"
//                                                             id="highToLow"
//                                                             className="form-check-input"
//                                                             checked={priceRange === "desc"}
//                                                             onChange={() => setPriceRange("desc")}
//                                                             style={{ height: "15px", width: "15px", marginRight: "5px" }}
//                                                         />
//                                                         <label className="form-check-label" htmlFor="highToLow">
//                                                             High to Low
//                                                         </label>
//                                                     </div>
//                                                     <div className="form-check ms-2">
//                                                         <input
//                                                             type="checkbox"
//                                                             id="lowToHigh"
//                                                             className="form-check-input"
//                                                             checked={priceRange === "asc"}
//                                                             onChange={() => setPriceRange("asc")}
//                                                             style={{ height: "15px", width: "15px", marginRight: "5px" }}
//                                                         />
//                                                         <label className="form-check-label" htmlFor="lowToHigh">
//                                                             Low to High
//                                                         </label>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-9">
//                                     {loading ? (
//                                         <div className="text-center mt-5">
//                                             <div className="spinner-border text-primary" role="status">
//                                                 <span className="visually-hidden">Loading...</span>
//                                             </div>
//                                             <p>Loading Products...</p>
//                                         </div>
//                                     ) : (
//                                         <div className="row">
//                                             {product?.map((item, index) => (
//                                                 <div className="col-md-3 col-sm-6" key={index}>
//                                                     <div className="card mt-5">
//                                                         <NavLink to={`/product_details/${item?._id}`}>
//                                                             <div className="card-header bg-white" style={{ height: 250 }}>
//                                                                 <img src={item.images[0].url} className="h-100 w-100" alt={item.title} />
//                                                             </div>
//                                                         </NavLink>
//                                                         <div className="card-body bg-white text-center">
//                                                             <h6>{item.title}</h6>
//                                                             <small>
//                                                                 <b className="text-success">
//                                                                     Price: <i className="fa-solid fa-indian-rupee-sign text-success"></i>
//                                                                     {item.price}
//                                                                 </b>
//                                                             </small>
//                                                             <br />
//                                                             <button
//                                                                 className="btn bg-success text-white mt-2"
//                                                                 style={{ border: 0, alignSelf: "center", borderRadius: 50 }}
//                                                                 onClick={() => handleCart(item._id)}
//                                                             >
//                                                                 Add to Cart
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// };

// export default CategoryWiseProducts;
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { UserContext } from '../Context/CreateContext';
import axios from 'axios';
import { base_url } from '../Config/Index';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CategoryWiseProducts = () => {
    const { categoryId } = useParams(); // categoryId is fetched from URL
    const { token } = useContext(UserContext);
    const [categories, setCategories] = useState([]);
    const [product, setProduct] = useState([]);
    const [selectedCategory, setSelectedcategory] = useState("");
    const [selectedProductType, setSelectedProductType] = useState("");
    const [openCategories, setOpenCategories] = useState(false);
    const [openProductType, setOpenProductType] = useState(false);
    const [priceRange, setPriceRange] = useState("");
    const [productType] = useState(["Liquid", "Powder"]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllProducts(); // Call the function to fetch products whenever categoryId changes
    }, [categoryId]);

    useEffect(() => {
        getCategories();
    }, []);

    // Handle Add to Cart
    const handleCart = async (productId) => {
        const payload = {
            product: productId,
            quantity: 1,
        };
        try {
            await axios.post(`${base_url}/api/post/card`, payload, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            toast.success('Item Added Successfully');
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch Products based on categoryId
    const getAllProducts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${base_url}/api/get/all/products?categoryId=${categoryId}`, // Ensure categoryId is passed in URL
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );
            setProduct(response.data);
            if (response.data.length === 0) {
                alert("No Data Found For This Category");
            }
        } catch (error) {
            if (error.response) {
                console.error("Error:", error.response.status, error.response.data);
            } else if (error.request) {
                console.error("No response from the server");
            } else {
                console.error("Error:", error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    // Fetch Categories
    const getCategories = async () => {
        try {
            const response = await axios.get(`${base_url}/api/getall/categories`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            setCategories(response.data);
        } catch (error) {
            if (error.response) {
                console.error("Error:", error.response.status, error.response.data);
            } else if (error.request) {
                console.error("No response from the server");
            } else {
                console.error("Error:", error.message);
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <section className="pt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3>All Products</h3>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                {/* <div className="col-12 col-md-3 col-lg-3">
                                    <div className="card mt-4">
                                        <div className="card-header">
                                            <h5>Filters</h5>
                                        </div>
                                        <div className="card-body">
                                            <div
                                                className="row mb-2 align-items-center"
                                                onClick={() => setOpenCategories(!openCategories)}
                                            >
                                                <div className="col-8">Categories</div>
                                                <div className="col-4 text-end">
                                                    <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                            </div>
                                            {openCategories &&
                                                categories?.map((item) => (
                                                    <p
                                                        key={item._id}
                                                        onClick={() => setSelectedcategory(item._id)}
                                                        style={{
                                                            color: selectedCategory === item._id ? "red" : "black",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item.category}
                                                    </p>
                                                ))}
                                            <hr />
                                            <div
                                                className="row align-items-center"
                                                onClick={() => setOpenProductType(!openProductType)}
                                            >
                                                <div className="col-8 mb-2">Product Type</div>
                                                <div className="col-4 text-end">
                                                    <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                            </div>
                                            {openProductType &&
                                                productType.map((product) => (
                                                    <p
                                                        key={product}
                                                        onClick={() => setSelectedProductType(product)}
                                                        style={{
                                                            color: selectedProductType === product ? "red" : "black",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {product}
                                                    </p>
                                                ))}
                                            <hr />
                                            <div className="row">
                                                <div className="col-12 mb-2">Price</div>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check ms-2">
                                                        <input
                                                            type="checkbox"
                                                            id="highToLow"
                                                            className="form-check-input"
                                                            checked={priceRange === "desc"}
                                                            onChange={() => setPriceRange("desc")}
                                                            style={{ height: "15px", width: "15px", marginRight: "5px" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="highToLow">
                                                            High to Low
                                                        </label>
                                                    </div>
                                                    <div className="form-check ms-2">
                                                        <input
                                                            type="checkbox"
                                                            id="lowToHigh"
                                                            className="form-check-input"
                                                            checked={priceRange === "asc"}
                                                            onChange={() => setPriceRange("asc")}
                                                            style={{ height: "15px", width: "15px", marginRight: "5px" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="lowToHigh">
                                                            Low to High
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 col-md-3">
                                    <div className="card mt-4">
                                        <div className="card-header" style={{ backgroundColor: "green" }}>
                                            <h5 className="text-white">Filters</h5>
                                        </div>
                                        <div className="card-body">
                                            <div
                                                className="row mb-3 align-items-center p-2"
                                                onClick={() => setOpenCategories(!openCategories)}
                                                style={{
                                                    backgroundColor: "#f5f5f5",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <div className="col-8">Categories</div>
                                                <div className="col-4 text-end">
                                                    <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                            </div>
                                            {openCategories &&
                                                categories.map((item) => (
                                                    <p
                                                        key={item._id}
                                                        onClick={() => setSelectedcategory(item._id)}
                                                        style={{
                                                            marginLeft: "80px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {item.category}
                                                    </p>
                                                ))}
                                            <hr />
                                            <div
                                                className="row mb-3 align-items-center p-2"
                                                onClick={() => setOpenProductType(!openProductType)}
                                                style={{
                                                    backgroundColor: "#f5f5f5",
                                                    borderRadius: "8px",
                                                    cursor: "pointer",
                                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                                }}
                                            >
                                                <div className="col-8">Product Type</div>
                                                <div className="col-4 text-end">
                                                    <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                            </div>
                                            {openProductType &&
                                                productType.map((product) => (
                                                    <p
                                                        key={product}
                                                        onClick={() => setSelectedProductType(product)}
                                                        style={{
                                                            marginLeft: "80px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {product}
                                                    </p>
                                                ))}
                                            <hr />
                                            <div className="row mb-2">
                                                <div className="col-12 mb-2">Price</div>
                                                <div className="d-flex flex-column">
                                                    <div className="form-check ms-2">
                                                        <input
                                                            type="checkbox"
                                                            id="highToLow"
                                                            className="form-check-input"
                                                            checked={priceRange === "desc"}
                                                            onChange={() => setPriceRange("desc")}
                                                            style={{ height: "15px", width: "15px", cursor: "pointer" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="highToLow">
                                                            High to Low
                                                        </label>
                                                    </div>
                                                    <div className="form-check ms-2">
                                                        <input
                                                            type="checkbox"
                                                            id="lowToHigh"
                                                            className="form-check-input"
                                                            checked={priceRange === "asc"}
                                                            onChange={() => setPriceRange("asc")}
                                                            style={{ height: "15px", width: "15px", cursor: "pointer" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="lowToHigh">
                                                            Low to High
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-9">
                                    {loading ? (
                                        <div className="text-center mt-5">
                                            {/* <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> */}
                                            <p>Loading Products...</p>
                                        </div>
                                    ) : (
                                        <div className="row">
                                            {product?.map((item, index) => (
                                                <div className="col-md-4 col-sm-6" key={index}>
                                                    <div className="card mt-5">
                                                        <NavLink to={`/product_details/${item?._id}`}>
                                                            {/* <div className="card-header bg-white" style={{ height: 250 }}>
                                                                <img 
                                                                    src={item.images[0].url} 
                                                                    className="img-fluid" 
                                                                    alt={item.title} 
                                                                    style={{ height: '100px', width: '100px', objectFit: 'cover' }} 
                                                                />
                                                            </div> */}
                                                            <div className="card-header bg-white" style={{ height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <img
                                                                    src={item.images[0].url}
                                                                    className="img-fluid"
                                                                    alt={item.title}
                                                                    style={{ height: '200px', width: '200px', objectFit: 'cover' }}
                                                                />
                                                            </div>

                                                        </NavLink>
                                                        <div className="card-body bg-white text-center">
                                                            <h6>{item.title}</h6>
                                                            <small>
                                                                <b className="text-success">
                                                                    Price: <i className="fa-solid fa-indian-rupee-sign text-success"></i>
                                                                    {item.price}
                                                                </b>
                                                            </small>
                                                            <br />
                                                            <button
                                                                className="btn bg-success text-white mt-2"
                                                                style={{ border: 0, alignSelf: "center", borderRadius: 50 }}
                                                                onClick={() => handleCart(item._id)}
                                                            >
                                                                Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CategoryWiseProducts;
