
// import React from 'react';
// import "../Css/style.css"

// const Vision = () => {
//   return (
//     <>
//       <div className="row">      
//         <div className="col-12 col-md-8 text-center mx-auto mb-4">
//           <div className=" bg-light p-5 c1 headings">
//             <h3>Vision</h3>
//             <div className='responsive-text'>
//               <p style={{  textAlign: 'justify' }}>
//                 To be the leading global e-commerce platform for agricultural products, empowering farmers with innovative, sustainable, and high-quality solutions that enhance productivity and promote a thriving agricultural community.
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="col-12 col-md-8 text-center mx-auto mb-4">
//           <div className=" bg-light p-5 c1 headings">
//             <h3>Mission</h3>
//             <div className='responsive-text'>
//               <p style={{ fontStyle: 'Sans Serif', textAlign: 'justify' }}>
//                 To provide farmers and agricultural businesses with easy access to a comprehensive range of high-quality fertilizers, seeds, pesticides, and farming equipment. 
//                 To continuously innovate and introduce cutting-edge technologies that improve farming efficiency and sustainability. 
//                 To exceed customer expectations through exceptional product quality, reliable delivery, and outstanding customer service. 
//                 To create significant value for our customers, shareholders, employees, and society by maintaining ethical business practices and fostering a culture of excellence and integrity. 
//                 To contribute to the growth and success of the agricultural sector by supporting farmers in achieving their goals and enhancing their livelihoods.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Vision;
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Vision = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className='vision-mission-section py-5 mb-4' style={{  
    boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    textAlign: 'justify' }}>
      <div className='container'>
        {/* Vision Section */}
        <div className='row align-items-center mb-5' >
          {/* Image Section */}
          {/* <div className='col-md-6' data-aos="zoom-in">
            <img
              src="https://kaybeebio.com/wp-content/uploads/2023/05/vision-about.png"
              className="img-fluid"
              alt="vision about"
              width="500"
              height="500"
            />
          </div> */}

          {/* Text Section */}
          <div className='col-md-6' data-aos="fade-up">
            <h1 className='text-success'>Our Vision</h1>
            <p className='text-justify'>
              To be the world's leading provider of microbial agri inputs, pioneering cutting-edge microbial
              technologies that sustainably enhance agricultural yields, enrich the environment, and create
              prosperity for all the stakeholders.
            </p>
          </div>
          <div className='col-md-6' data-aos="zoom-in">
            <img
              // src="https://kaybeebio.com/wp-content/uploads/2023/05/vision-about.png"
              src="https://pan-india.org/wp-content/uploads/2024/03/safe-1.png"
              className="img-fluid"
              alt="vision about"
              width="500"
              height="500"
            />
          </div>
        </div>

        {/* Mission Section */}
        <div className='row align-items-center'>
          <div className='col-md-6 order-md-1' data-aos="zoom-in">
            <img
              // src="https://kaybeebio.com/wp-content/uploads/2023/05/mission-about.png"
              src='https://darachemicals.com/wp-content/uploads/2019/10/proteger-l-environnement.jpg'
              className="img-fluid"
              alt="mission about"
              width="500"
              height="499"
              style={{ borderRadius: '10px'}}
            />
          </div>
          {/* Text Section */}
          <div className='col-md-6 order-md-2' data-aos="fade-up">
            <h1 className='text-success'>Our Mission</h1>
            <p className='text-justify'>
              To sustainably revolutionize agriculture by developing innovative microbial products that
              empower farmers and protect the environment. Guided by Eco philosophy, our Lean and
              process oriented approach delivers future-ready solutions to address environmental
              challenges.
            </p>
          </div>

          {/* Image Section */}

        </div>
      </div>
    </section>
  );
};

export default Vision;
