
// import React, { useContext, useEffect, useState } from 'react'
// import "../Css/navbar.css"
// import logo from '../Assets/Images/logo.png';
// import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
// import { UserContext, useUser } from '../Context/CreateContext'
// import { useAuth } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { CiShoppingCart } from "react-icons/ci";
// import { Dropdown } from 'bootstrap';
// import { FaRegCircleUser } from "react-icons/fa6";
// import { FaAngleDown } from "react-icons/fa6";

// // new changed
// const Nav = () => {
//   const { id } = useParams();
//   const { user, token, login, logout } = useContext(UserContext);
//   // console.log(user)
//   const [isSearchOpen, setSearchOpen] = useState(false);
//   const [searchText, setSearchText] = useState("")
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);
//   const [searchResults, setSearchResults] = useState([])
//   const [cartItems, setCartitems] = useState(0)
//   const [isExpanded, setIsExpanded] = useState(false);


//   // Check if user is logged in (for example, by checking for a token in localStorage)
//   const isLoggedIn = !!localStorage.getItem('token');
//   const navigate = useNavigate();

//   const toggleNavbar = () => {
//     setIsNavbarOpen(!isNavbarOpen);
//   };

//   const toggleSearch = () => {
//     setSearchOpen(!isSearchOpen);
//   };

//   const handleSearchInputChange = (e) => {
//     setSearchText(e.target.value);
//   };

//   const clearSearchText = () => {
//     setSearchText('');
//   };

//   useEffect(() => {
//     getCartItems()
//   }, [token])
//   console.log(cartItems)

//   const handleSearch = (q) => {
//     axios.get(`${base_url}/api/search/products?search=${q}`)
//       .then((res) => {
//         setSearchResults(res.data)
//         console.log(res.data)
//       })
//       .catch((err) => {
//         console.log(err)
//       })
//   }

//   const getCartItems = async () => {
//     try {
//       const response = await axios.get(`${base_url}/api/get/card`, {
//         headers: {
//           Authorization: `${token}`
//         }
//       })
//       setCartitems(response.data.items.length)
//     } catch (error) {
//       // console.log(error)
//       setCartitems(0)
//     }
//   }

//   return (
//     <>
//       <section
//         className="nav1"
//         style={{
//           boxShadow:
//             "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
//           position: "sticky",
//           top: "0",
//           zIndex: "1000",
//           backgroundColor: "white"
//         }}
//       >
//         <div className="container-fluid">
//           <div className="row ">
//             <div className="col-md-12 ">
//               <ul
//                 className=''
//                 style={{
//                   paddingInlineStart: 0,
//                   display: 'flex',
//                   listStyleType: 'none',
//                   justifyContent: 'space-between',
//                   alignItems: 'center',
//                   // border:'1px solid red'
//                 }}
//               >
//                 <li >
//                   <NavLink to="/">
//                     <img src={logo}
//                       // className='border border-dark'
//                       style={{ width: 80 }}
//                     /></NavLink>
//                 </li>
//                 <li className='  '>
//                   <NavLink to="/" className="text-dark fs-3" >Home</NavLink>
//                 </li>
//                 <li className="" >
//                   <div className="dropdown">
//                     <button
//                       className="w-100  btn dropdown-toggle custom-dropdown-toggle"
//                       type="button"
//                       data-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       About Us
//                     </button>
//                     <div className="dropdown-menu" aria-labelledby="aboutDropdown">
//                       <Link className="dropdown-item" to="/aboutus/about-us">
//                         About us
//                       </Link>
//                       <Link className="dropdown-item" to="/aboutus/journey"
//                       // onMouseEnter={() => setHoveredItem('journey')}
//                       //   onMouseLeave={() => setHoveredItem('')}
//                       >
//                         Our Journey
//                       </Link>
//                       <Link className="dropdown-item" to="/aboutus/vision" >
//                         Vision and Mission
//                       </Link>
//                       <Link className="dropdown-item" to="/aboutus/values">
//                         Core Values
//                       </Link>
//                       <Link className="dropdown-item" to="/aboutus/team">
//                         Our Team
//                       </Link>
//                       {/* <Link className="dropdown-item" to="/aboutus/clients">
//                         Our Clients
//                       </Link> */}
//                       <Link className="dropdown-item" to="/aboutus/cert">
//                         Certifications
//                       </Link>
//                     </div>
//                   </div>
//                 </li>

//                 <li className="">
//                   <div className="dropdown">
//                     <button
//                       className="w-100  btn dropdown-toggle custom-dropdown-toggle"
//                       type="button"
//                       data-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       Innovations
//                     </button>
//                     <div
//                       className="dropdown-menu"
//                       aria-labelledby="innovationDropdown"
//                     >
//                       {/* <Link
//                         className="dropdown-item"
//                         to="/innovation/slider3content"
//                       >
//                         Our Premier Technology and Innovation Facility
//                       </Link> */}
//                       <Link className="dropdown-item" to="/innovation/r&d">
//                         R & D
//                       </Link>
//                       <Link className="dropdown-item" to="/innovation/production">
//                         Production Facility
//                       </Link>
//                       <Link className="dropdown-item" to="/innovation/quality">
//                         Quality Compliance
//                       </Link>
//                       <Link className="dropdown-item" to="/innovation/research">
//                         Research Farm
//                       </Link>
//                     </div>
//                   </div>
//                 </li>

//                 <li className="">
//                   <div className="dropdown">
//                     <button
//                       className="w-100  btn dropdown-toggle custom-dropdown-toggle"
//                       type="button"
//                       data-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       B2B Solutions
//                     </button>
//                     <div className="dropdown-menu" aria-labelledby="b2bDropdown">
//                       <Link className="dropdown-item" to="/b2b/contract_mfg">
//                         Contract Manufacturing
//                       </Link>
//                       <Link className="dropdown-item" to="/b2b/product-portfolio">
//                         Product Portfolio
//                       </Link>
//                       <Link className="dropdown-item" to="/b2b/why_choose">
//                         Why Choose Us?
//                       </Link>
//                     </div>
//                   </div>
//                 </li>

//                 <li className=" ">
//                   <NavLink to="/ourproduct" className="text-dark">
//                     Our Products
//                   </NavLink>
//                 </li>

//                 <li className="">
//                   <div className="dropdown">
//                     <button
//                       className="w-100  btn dropdown-toggle custom-dropdown-toggle"
//                       type="button"
//                       data-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       Careers
//                     </button>
//                     <div className="dropdown-menu" aria-labelledby="careersDropdown">
//                       {/* <Link className="dropdown-item" to="/career/openings">
//                         Current Openings
//                       </Link> */}
//                       <Link className="dropdown-item" to="/career/culture">
//                         Our Culture
//                       </Link>
//                       <Link className="dropdown-item" to="/career/openings">
//                         Current Openings
//                       </Link>
//                     </div>
//                   </div>
//                 </li>

//                 <li className="">
//                   <div className="dropdown">
//                     <button
//                       className="w-100  btn dropdown-toggle custom-dropdown-toggle"
//                       type="button"
//                       data-toggle="dropdown"
//                       aria-expanded="false"
//                     >
//                       Updates
//                     </button>
//                     <div className="dropdown-menu" aria-labelledby="updatesDropdown">
//                       <Link className="dropdown-item" to="/updates/news">
//                         News & Events
//                       </Link>
//                       <Link className="dropdown-item" to="/updates/blog">
//                         Blogs
//                       </Link>
//                     </div>
//                   </div>
//                 </li>
//                 <li className=" ">
//                   <NavLink to="/contact" className="text-dark">
//                     Contact Us
//                   </NavLink>
//                 </li>
//                 <li className="search-container">
//                   <div className="search-icon">
//                     <i class="fa-solid fa-magnifying-glass"></i>
//                   </div>
//                   <input
//                     type="text"
//                     className="search-bar "
//                     placeholder="Search..."
//                     onFocus={() => document.querySelector('.search-bar').classList.add('expanded')}
//                     onBlur={() => document.querySelector('.search-bar').classList.remove('expanded')}
//                     value={searchText}
//                     onChange={(e) => {
//                       setSearchText(e.target.value);
//                       handleSearch(e.target.value);
//                     }}
//                   />
//                   {searchResults.length > 0 && (
//                     <div className="search-suggestions mb-3">
//                       {searchResults.map((result) => (
//                         <div key={result._id} className="search-suggestion" onClick={() => {
//                           navigate(`/product_details/${result._id}`);
//                           setSearchResults([])
//                           setSearchText(result.title)
//                         }}>
//                           {result.title}
//                         </div>
//                       ))}
//                     </div>
//                   )}

//                 </li>

//                 {/* <li style={{ position: 'relative' }}>
//                   <CiShoppingCart
//                     style={{ fontSize: 25, position: 'relative' }}
//                     onClick={() => navigate('/cart')}
//                   />

//                   <span
//                     className="cart-count-badge"
//                     style={{
//                       position: 'absolute',
//                       top: '-28px',
//                       right: '-14px', 
//                       backgroundColor: 'white',
//                       color: 'red',
//                       borderRadius: '0',
//                       padding: '2px 6px',
//                       fontSize: '14px',
//                       fontWeight: 'bold',
//                       width:'25px'
//                     }}
//                   >
//                     {cartItems}
//                   </span>
//                 </li> */}
//                 <li style={{ position: 'relative' }}>
//                   <CiShoppingCart
//                     style={{ fontSize: 25, position: 'relative' }}
//                     onClick={() => navigate('/cart')}
//                   />
//                   {/* Conditionally render the badge only if the user is logged in */}
//                   {isLoggedIn && (
//                     <span
//                       className="cart-count-badge"
//                       style={{
//                         position: 'absolute',
//                         top: '-28px',
//                         right: '-14px',
//                         backgroundColor: 'white',
//                         color: 'red',
//                         borderRadius: '0',
//                         padding: '2px 6px',
//                         fontSize: '14px',
//                         fontWeight: 'bold',
//                         width: '25px',
//                         cursor:'pointer'
//                       }}
//                     >
//                       {cartItems}
//                     </span>
//                   )}
//                 </li>
//                 {!user ? (
//                   <>
//                     <li>
//                       <button className='btn btn-success btn-sm txet-sm' onClick={() => navigate('/login')}>Login</button>
//                     </li>
//                   </>
//                 ) : (
//                   <>
//                     {/* <div className="dropdown">
//                       <div className='p-1 dropdown-toggle text-light' role="button" data-toggle="dropdown" aria-expanded="false" style={{ background: 'linear-gradient(to right, lightgreen, green)', borderTopLeftRadius: 20, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
//                         <text style={{ fontSize: 18, color: 'white' }}>👋🏻Hello, <b>{user.username.split(" ")[0]}</b></text>
//                       </div>

//                       <div className="dropdown-menu p-2">
//                         <a className="dropdown-item" href="#">
//                           <div className="row">
//                             <div className="col-3">
//                               <i className="fa-solid fa-circle-user text-success mt-2" style={{ fontSize: 30 }} ></i>
//                             </div>
//                             <div className="col-9  " >
//                               Hello<br /><small>{user.username}</small>
//                             </div>
//                           </div>
//                         </a>
//                         <NavLink to="/my_orders"><text className="dropdown-item p-1">My Orders</text></NavLink>
//                         <NavLink to="/manage_add"><text className="dropdown-item p-1"> Manage Addresses</text></NavLink>
//                         <a className="  dropdown-item p-1" onClick={logout}>logout</a>
//                       </div>
//                     </div> */}
//                     <div className='dropdown'>
//                       <div className=' border p-1' role="button" data-toggle="dropdown" aria-expanded="false">
//                         <FaRegCircleUser style={{ fontSize: 15 }} /> &nbsp;<FaAngleDown style={{ fontSize: 15 }} />
//                       </div>
//                       <div className="dropdown-menu p-2">
//                         <a className="dropdown-item" href="#">
//                           <div className="row">
//                           </div>
//                         </a>
//                         <NavLink to="/my_orders"><text className="dropdown-item p-1">My Orders</text></NavLink>
//                         <NavLink to="/manage_add"><text className="dropdown-item p-1"> Manage Addresses</text></NavLink>
//                         <a className="  dropdown-item p-1 cursor-pointer" onClick={logout}>logout</a>
//                       </div>
//                     </div>
//                   </>
//                 )}

//                 <li>
//                   <button className='btn btn-success btn-sm text-sm' onClick={() => navigate('/product_page')}>Shop Now</button>
//                 </li>
//                 {/* <li>
//                   <button className='btn btn-success btn-sm text-sm' onClick={() => navigate('/product_page')}>Our Products</button>
//                 </li> */}
//               </ul>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* <section className='nav2'>
//         <div className="container-fluid mb-2">
//           {!isSearchOpen && (
//             <div className="row">
//               <div className="col-6 col-md-8">
//                 <img src={logo} className='NavLogo' />
//               </div>
//               <div className="col-6 col-md-4 mt-4 text-right">
//                 <i className="fa-solid fa-cart-shopping text-success mr-4"></i>
//                 <i className="fa-solid fa-magnifying-glass mr-4" onClick={toggleSearch}></i>
//                 <button className='btn btn-light' onClick={toggleNavbar}><i className="fa-solid fa-bars"></i></button>
//               </div>
//             </div>
//           )}
//           <hr />
//           {isNavbarOpen && (
//             <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', gap: 10, alignItems: 'center' }}>
//               <li className='mt-2 '>
//                 <Link to="/" ><button className=' font-weight-bold btn'>Home</button></Link>
//               </li>
//               <li className='mt-2'>
//                 <div className="dropdown">
//                   <button className="w-100 font-weight-bold btn dropdown-toggle custom-dropdown-toggle"
//                     type="button"
//                     data-toggle="dropdown"
//                     aria-expanded="false">
//                     About Us
//                   </button>
//                   <div className="dropdown-menu">
//                     <Link className="dropdown-item" to="/aboutus">About us</Link>
//                     <Link className="dropdown-item" to={`/aboutus/journey`}>Our Journey</Link>
//                     <Link className="dropdown-item" to={`/aboutus/vision`}>Vision and Mission</Link>
//                     <Link className="dropdown-item" to={`/aboutus/values`}>Core Values</Link>
//                     <Link className="dropdown-item" to={`/aboutus/team`}>Our Team</Link>
//                     <Link className='dropdown-item' to={`/aboutus/clients`}>Our Clients</Link>
//                     <Link className='dropdown-item' to={`/aboutus/cert`}>Certifications</Link>
//                   </div>
//                 </div>
//               </li>
//               <li className='mt-2'>
//                 <div className="dropdown">
//                   <button className=" w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                     Innovations
//                   </button>
//                   <div className="dropdown-menu">
//                     <Link className="dropdown-item" to="/">R & D</Link>
//                     <Link className="dropdown-item" to="/">Production Facility</Link>
//                     <Link className="dropdown-item" to="/">Quality Compliance</Link>
//                     <Link className="dropdown-item" to="/">Research Farm</Link>
//                   </div>
//                 </div>
//               </li>
//               <li className='mt-2'>
//                 <div className="dropdown">
//                   <button className=" w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                     B2B Solutions
//                   </button>
//                   <div className="dropdown-menu">
//                     <Link className="dropdown-item" to="/">Contract Manufacturing</Link>
//                     <Link className="dropdown-item" to="/">Why Choose Us</Link>
//                   </div>
//                 </div>
//               </li>
//               <li className='mt-2'>
//                 <div className="dropdown">
//                   <button className=" w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                     Careers
//                   </button>
//                   <div className="dropdown-menu">
//                     <Link className="dropdown-item" to="/">Current Openings</Link>
//                     <Link className="dropdown-item" to="/">Our Culture</Link>
//                   </div>
//                 </div>
//               </li>
//               <li className='mt-2'>
//                 <div className="dropdown">
//                   <button className=" w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                     Updates
//                   </button>
//                   <div className="dropdown-menu">
//                     <Link className="dropdown-item" to="/">News & Events</Link>
//                     <Link className="dropdown-item" to="/">Blogs</Link>
//                   </div>
//                 </div>
//               </li>
//               <li className='mt-2'>
//                 <NavLink to="/" ><button className=' font-weight-bold btn'>Contact Us</button></NavLink>
//               </li>
//             </ul>
//           )}
//           {!isSearchOpen && !isNavbarOpen && (
//             <div className="row nav2row2">
//               <div className="col-6">
//                 <button className='btn btn-success btn-sm float-left' onClick={() => navigate('/product_page')}>Shop Now</button>
//               </div>
//               {!user ? (
//                 <>
//                   <div className="col-6 text-right">
//                     <div className="dropdown">
//                       <button className="btn btn-success" style={{ display: 'flex', margin: 'auto' }} type="button" data-toggle="dropdown" aria-expanded="false">
//                         Login
//                       </button>
//                     </div>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <div className="col-6 text-right">
//                     <div className="dropdown">
//                       <a role="button" data-toggle="dropdown" aria-expanded="false">
//                         <text style={{ fontSize: 16, color: 'black' }}>👋🏻Hello, {user.username.split(" ")[0]}</text>
//                       </a>

//                       <div className="dropdown-menu p-2">
//                         <a className="dropdown-item" >
//                           <div className="row">
//                             <div className="col-3">
//                               <i className="fa-solid fa-circle-user text-success mt-2" style={{ fontSize: 30 }} ></i>
//                             </div>
//                             <div className="col-9">
//                               Hello<br /><small>{user.username}</small>
//                             </div>
//                           </div>
//                         </a>
//                         <NavLink className="dropdown-item p-1" to="/my_orders">My Orders</NavLink>
//                         <NavLink className="dropdown-item p-1" to="/manage_add">Manage Addresses</NavLink>
//                         <a className="dropdown-item p-1" onClick={logout}>logout</a>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               )}
//             </div>
//           )}
//         </div>

//         {isSearchOpen && (
//           <div className="container-fluid">
//             <div className="row p-3">
//               <div className="col-12 ">
//                 <div style={{ position: 'relative' }}>
//                   <i
//                     className="fa-solid fa-arrow-left"
//                     onClick={toggleSearch}
//                     style={{
//                       position: 'absolute',
//                       top: '50%',
//                       left: '-20px',
//                       transform: 'translateY(-50%)',
//                       cursor: 'pointer'
//                     }}
//                   />
//                   <input
//                     type="text"
//                     placeholder="Search for a Product.."
//                     value={searchText}
//                     onChange={(e) => {
//                       setSearchText(e.target.value);
//                       handleSearch(e.target.value);
//                     }}
//                   />
//                   {searchText && (
//                     <i
//                       className="fa-solid fa-xmark"
//                       onClick={clearSearchText}
//                       style={{
//                         position: 'absolute',
//                         top: '50%',
//                         right: '10px',
//                         transform: 'translateY(-50%)',
//                         cursor: 'pointer'

//                       }}
//                     />
//                   )}
//                   {searchResults?.length > 0 && (
//                     <div className="search-suggestions mb-3">
//                       {searchResults.map((result) => (
//                         <div key={result._id} className="search-suggestion">
//                           {result.title}
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </section> */}
//       <section className='nav2'>
//             <div className="container-fluid mb-2">
//                 {!isSearchOpen && (
//                     <div className="row">
//                         <div className="col-6 col-md-8">
//                             <img src={logo} className='NavLogo' alt="Logo" />
//                         </div>
//                         <div className="col-6 col-md-4 mt-4 text-right">
//                             <i className="fa-solid fa-cart-shopping text-success mr-4"></i>
//                             <i className="fa-solid fa-magnifying-glass mr-4" onClick={toggleSearch}></i>
//                             <button className='btn btn-light' onClick={toggleNavbar}>
//                                 <i className="fa-solid fa-bars"></i>
//                             </button>
//                         </div>
//                     </div>
//                 )}
//                 <hr />
//                 {isNavbarOpen && (
//                     <ul style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', gap: 10, alignItems: 'center' }}>
//                         <li className='mt-2'>
//                             <Link to="/"><button className=' font-weight-bold btn'>Home</button></Link>
//                         </li>
//                         <li className='mt-2'>
//                             <div className="dropdown">
//                                 <button className="w-100 font-weight-bold btn dropdown-toggle custom-dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                                     About Us
//                                 </button>
//                                 <div className="dropdown-menu">
//                                     <Link className="dropdown-item" to="/aboutus">About us</Link>
//                                     <Link className="dropdown-item" to={`/aboutus/journey`}>Our Journey</Link>
//                                     <Link className="dropdown-item" to={`/aboutus/vision`}>Vision and Mission</Link>
//                                     <Link className="dropdown-item" to={`/aboutus/values`}>Core Values</Link>
//                                     <Link className="dropdown-item" to={`/aboutus/team`}>Our Team</Link>
//                                     <Link className='dropdown-item' to={`/aboutus/clients`}>Our Clients</Link>
//                                     <Link className='dropdown-item' to={`/aboutus/cert`}>Certifications</Link>
//                                 </div>
//                             </div>
//                         </li>
//                         <li className='mt-2'>
//                             <div className="dropdown">
//                                 <button className="w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                                     Innovations
//                                 </button>
//                                 <div className="dropdown-menu">
//                                     <Link className="dropdown-item" to="/">R & D</Link>
//                                     <Link className="dropdown-item" to="/">Production Facility</Link>
//                                     <Link className="dropdown-item" to="/">Quality Compliance</Link>
//                                     <Link className="dropdown-item" to="/">Research Farm</Link>
//                                 </div>
//                             </div>
//                         </li>
//                         <li className='mt-2'>
//                             <div className="dropdown">
//                                 <button className="w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                                     B2B Solutions
//                                 </button>
//                                 <div className="dropdown-menu">
//                                     <Link className="dropdown-item" to="/">Contract Manufacturing</Link>
//                                     <Link className="dropdown-item" to="/">Why Choose Us</Link>
//                                 </div>
//                             </div>
//                         </li>
//                         <li className="">
//                             <NavLink to="/ourproduct" className="text-dark font-weight-bold">
//                                 Our Products
//                             </NavLink>
//                         </li>
//                         <li className='mt-2'>
//                             <div className="dropdown">
//                                 <button className="w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                                     Careers
//                                 </button>
//                                 <div className="dropdown-menu">
//                                     <Link className="dropdown-item" to="/">Current Openings</Link>
//                                     <Link className="dropdown-item" to="/">Our Culture</Link>
//                                 </div>
//                             </div>
//                         </li>
//                         <li className='mt-2'>
//                             <div className="dropdown">
//                                 <button className="w-100 font-weight-bold btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                                     Updates
//                                 </button>
//                                 <div className="dropdown-menu">
//                                     <Link className="dropdown-item" to="/">News & Events</Link>
//                                     <Link className="dropdown-item" to="/">Blogs</Link>
//                                 </div>
//                             </div>
//                         </li>
//                         <li className='mt-2'>
//                             <NavLink to="/"><button className='font-weight-bold btn'>Contact Us</button></NavLink>
//                         </li>
//                     </ul>
//                 )}
//                 {!isSearchOpen && !isNavbarOpen && (
//                     <div className="row nav2row2">
//                         <div className="col-6">
//                             <button className='btn btn-success btn-sm float-left' onClick={() => navigate('/product_page')}>Shop Now</button>
//                         </div>
//                         {!user ? (
//                             <div className="col-6 text-right">
//                                 <div className="dropdown">
//                                     <button className="btn btn-success" style={{ display: 'flex', margin: 'auto' }} type="button" data-toggle="dropdown" aria-expanded="false">
//                                         Login
//                                     </button>
//                                 </div>
//                             </div>
//                         ) : (
//                             <div className="col-6 text-right">
//                                 <div className="dropdown">
//                                     <a role="button" data-toggle="dropdown" aria-expanded="false">
//                                         <text style={{ fontSize: 16, color: 'black' }}>👋🏻Hello, {user.username.split(" ")[0]}</text>
//                                     </a>
//                                     <div className="dropdown-menu p-2">
//                                         <a className="dropdown-item">
//                                             <div className="row">
//                                                 <div className="col-3">
//                                                     <i className="fa-solid fa-circle-user text-success mt-2" style={{ fontSize: 30 }}></i>
//                                                 </div>
//                                                 <div className="col-9">
//                                                     Hello<br /><small>{user.username}</small>
//                                                 </div>
//                                             </div>
//                                         </a>
//                                         <NavLink className="dropdown-item p-1" to="/my_orders">My Orders</NavLink>
//                                         <NavLink className="dropdown-item p-1" to="/manage_add">Manage Addresses</NavLink>
//                                         <a className="dropdown-item p-1" onClick={logout}>logout</a>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 )}
//             </div>

//             {isSearchOpen && (
//                 <div className="container-fluid">
//                     <div className="row p-3">
//                         <div className="col-12 ">
//                             <div style={{ position: 'relative' }}>
//                                 <i
//                                     className="fa-solid fa-arrow-left"
//                                     onClick={toggleSearch}
//                                     style={{
//                                         position: 'absolute',
//                                         top: '50%',
//                                         left: '-20px',
//                                         transform: 'translateY(-50%)',
//                                         cursor: 'pointer'
//                                     }}
//                                 />
//                                 <input
//                                     type="text"
//                                     placeholder="Search for a Product.."
//                                     value={searchText}
//                                     onChange={(e) => {
//                                         setSearchText(e.target.value);
//                                         handleSearch(e.target.value);
//                                     }}
//                                 />
//                                 {searchText && (
//                                     <i
//                                         className="fa-solid fa-xmark"
//                                         onClick={clearSearchText}
//                                         style={{
//                                             position: 'absolute',
//                                             top: '50%',
//                                             right: '10px',
//                                             transform: 'translateY(-50%)',
//                                             cursor: 'pointer'
//                                         }}
//                                     />
//                                 )}
//                                 {searchResults?.length > 0 && (
//                                     <div className="search-suggestions mb-3">
//                                         {searchResults.map((result) => (
//                                             <div key={result._id} className="search-suggestion">
//                                                 {result.title}
//                                             </div>
//                                         ))}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </section>
//     </>
//   )
// }

// export default Nav


import React, { useContext, useEffect, useState } from 'react'
import "../Css/navbar.css"
import logo from '../Assets/Images/logo.png';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { UserContext, useUser } from '../Context/CreateContext'
import { useAuth } from '../Context/CreateContext';
import axios from 'axios';
import { base_url } from '../Config/Index';
import { CiShoppingCart } from "react-icons/ci";
import { Dropdown } from 'bootstrap';
import { FaRegCircleUser } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import biocyll_Logo from '../Assets/Images/biocyll_Logo.png'
// new changed
const Nav = () => {
  const { id } = useParams();
  const { user, token, login, logout } = useContext(UserContext);
  // console.log(user)
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("")
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([])
  const [cartItems, setCartitems] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false);
  const isLoggedIn = !!localStorage.getItem('token');
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };
  const clearSearchText = () => {
    setSearchText('');
  };
  const goToCartPage = () => {
    navigate('/cart'); // Navigate to the cart page (update the path as needed)
  };

  const [activeItem, setActiveItem] = useState('');

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  useEffect(() => {
    getCartItems()
  }, [token])
  console.log(cartItems)

  const handleSearch = (q) => {
    axios.get(`${base_url}/api/search/products?search=${q}`)
      .then((res) => {
        setSearchResults(res.data)
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getCartItems = async () => {
    try {
      const response = await axios.get(`${base_url}/api/get/card`, {
        headers: {
          Authorization: `${token}`
        }
      })
      setCartitems(response.data.items.length)
    } catch (error) {
      // console.log(error)
      setCartitems(0)
    }
  }



  return (
    <>
      <section className="nav1"
        style={{
          boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          position: "sticky",
          top: "0",
          zIndex: "1000",
          backgroundColor: "white",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <ul style={{
                paddingInlineStart: 0,
                display: 'flex',
                listStyleType: 'none',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <li>
                  <NavLink to="/" className="nav-link">
                    <img src={biocyll_Logo} style={{ width: 80, height: 90 }} />
                  </NavLink>
                </li>
                <li>
                  <button
                    className="btn btn-sm text-sm"
                    type="button"
                    onClick={() => navigate('/')}
                    style={{
                      backgroundColor: "#388E3C",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    Home
                  </button>
                </li>
                <li
                  style={{
                    backgroundColor: activeItem === "/aboutus/:section" ? "#388E3C" : "transparent", // Highlight on click
                    transition: "background-color 0.3s",
                  }}
                >
                  <button
                    className="btn btn-sm text-sm"
                    type="button"
                    onClick={() => navigate('/aboutus/:section')}
                    style={{
                      backgroundColor: "#388E3C",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    About Us
                  </button>
                </li>
                <li
                  style={{
                    backgroundColor: activeItem === "aboutus" ? "#388E3C" : "transparent", // Highlight on click
                    transition: "background-color 0.3s",
                  }}
                >
                  <button
                    className="btn btn-sm text-sm"
                    type="button"
                    onClick={() => navigate('/innovation')}
                    style={{
                      backgroundColor: "#388E3C",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    Innovations
                  </button>
                </li>
                <li className="" >
                  <div className="dropdown">
                    <button
                      className="btn  btn-sm text-sm"
                      type="button"
                      data-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => navigate('/')} a
                      style={{
                        backgroundColor: "#388E3C",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    >
                      Products and Services
                    </button>

                    <div className="dropdown-menu" aria-labelledby="b2bDropdown">
                      <Link className="dropdown-item" to="/b2b">
                        Our Products
                      </Link>
                      <Link className="dropdown-item" to="/whychooseus">
                        Bulk and contract manufacturing
                      </Link>
                      <Link className="dropdown-item" to="/r_and_d">
                        Contract R & D
                      </Link>
                    </div>
                  </div>
                </li>
                <li className="">
                  <div className="dropdown">
                    <button
                      className="btn  btn-sm text-sm"
                      type="button"
                      onClick={() => navigate('/whyus')}
                      style={{
                        backgroundColor: "#388E3C",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    >
                      Why Us
                    </button>
                  </div>
                </li>

                <li className="">
                  <div className="dropdown">
                    <button
                      className="btn btn-sm text-sm"
                      type="button"
                      onClick={() => navigate('/career')}
                      style={{
                        backgroundColor: "#388E3C",
                        color: "white",
                        padding: "8px 16px",
                        borderRadius: "5px",
                        border: "none",
                      }}
                    >
                      Career
                    </button>
                  </div>
                </li>


                <li>
                  <button
                    className="btn btn-sm text-sm"
                    type="button"
                    onClick={() => navigate('/contact')}
                    style={{
                      backgroundColor: "#388E3C",
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "5px",
                      border: "none",
                    }}
                  >
                    Contact Us
                  </button>
                </li>
                <li className="search-container">
                  <div className="search-icon" style={{ cursor: 'pointer' }}>
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                  <input
                    type="text"
                    className="search-bar"
                    placeholder="Search..."
                    onFocus={() => document.querySelector('.search-bar').classList.add('expanded')}
                    onBlur={() => document.querySelector('.search-bar').classList.remove('expanded')}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  {searchResults.length > 0 && (
                    <div className="search-suggestions mb-3">
                      {searchResults.map((result) => (
                        <div
                          key={result._id}
                          className="search-suggestion"
                          style={{ padding: '10px', cursor: 'pointer' }}
                          onClick={() => {
                            navigate(`/product_details/${result._id}`);
                            setSearchResults([]);
                            setSearchText(result.title);
                          }}
                        >
                          {result.title}
                        </div>
                      ))}
                    </div>
                  )}
                </li>
                {/* <li style={{ position: 'relative' }}>
                  <CiShoppingCart
                    style={{ fontSize: 25, cursor:'pointer'  }}
                    onClick={() => navigate('/cart')}
                  />
                  {isLoggedIn && (
                    <span
                      className="cart-count-badge"
                      style={{
                        position: 'absolute',
                        top: '-28px',
                        right: '-14px',
                        backgroundColor: 'white',
                        color: 'red',
                        borderRadius: '0',
                        padding: '2px 6px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        width: '25px',
                        cursor: 'pointer',
                      }}
                    >
                      {cartItems}
                    </span>
                  )}
                </li> */}
                {!user ? (
                  <>
                    <li>
                      <button className='btn btn-success btn-sm' onClick={() => navigate('/login')}>Login</button>
                    </li>
                  </>
                ) : (
                  <>
                    <div className='dropdown d-flex justify-content-end align-items-center'>
                      <div className='border p-1' role="button" data-toggle="dropdown" aria-expanded="false">
                        <FaRegCircleUser style={{ fontSize: 15 }} /> &nbsp;<FaAngleDown style={{ fontSize: 15 }} />
                      </div>
                      <div className="dropdown-menu p-2">
                        <NavLink to="/my_orders"><text className="dropdown-item p-1">My Enquiries</text></NavLink>
                        <NavLink to="/manage_add"><text className="dropdown-item p-1">Manage Addresses</text></NavLink>
                        <a className="dropdown-item p-1 cursor-pointer" onClick={logout}>Logout</a>
                      </div>
                    </div>
                  </>
                )}
                <li>
                  <button
                    className="btn btn-sm text-sm"
                    onClick={() => navigate('/product_page/:id')}
                    style={{backgroundColor: '#367c2b', color:"white", padding: "8px 16px", borderRadius: "5px",

                    }}
                  >
                    Shop Now
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <marquee behavior="scroll" direction="left" style={{ color: 'white', backgroundColor: '#367c2b', cursor: 'pointer' }}
            onClick={() => navigate('/product_page/:id')}>
            To buy a sample now, Click here!
          </marquee>
        </div>
      </section>



      <section className='nav2'>
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <NavLink to="/" className="nav-link">
                <img src={biocyll_Logo} style={{ height: '50px', width: 'auto', marginLeft: '10px', marginTop: '5px' }} />
              </NavLink>
            </div>

            <div className="d-flex align-items-center">
              <i className="fa-solid fa-cart-shopping text-success mr-4" style={{ fontSize: '14' }}></i>
              <i className="fa-solid fa-magnifying-glass mr-4" onClick={toggleSearch} style={{ fontSize: '14', cursor: 'pointer' }}
              ></i>
              <span
                className="cart-count-badge"
                style={{
                  position: 'relative',
                  top: '-20px',
                  right: '75px',
                  backgroundColor: 'white',
                  color: 'red',
                  borderRadius: '0',
                  padding: '2px 6px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  width: '10px',
                  cursor: 'pointer',
                  height: '10px'
                }}
                onClick={goToCartPage}
              >

                {cartItems}
              </span>
              <button className='btn btn-light' onClick={toggleNavbar}>
                <i className="fa-solid fa-bars" style={{ fontSize: '14' }}></i>
              </button>
            </div>
          </div>
        </div>
        <hr />

        {isNavbarOpen && (
          <ul className={`navbar-links ${isNavbarOpen ? 'open' : ''}`} style={{ display: 'flex', flexDirection: 'column', listStyleType: 'none', gap: 10, alignItems: 'center' }}>
            <li>
              <NavLink to="/" onClick={'closeNavbar'} className="nav-link">Home</NavLink>
            </li>
            <li
              style={{
                backgroundColor: activeItem === "/aboutus/:section" ? "#388E3C" : "transparent", // Highlight on click
                transition: "background-color 0.3s",
              }}
            >
              <button
                className="w-100 btn navbar-button"
                type="button"
                onClick={() => {
                  closeNavbar(); // Close navbar
                  navigate('/aboutus/:section'); // Navigate to About Us
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#388E3C")}
                onMouseLeave={(e) => (e.target.style.backgroundColor = activeItem === "aboutus" ? "#388E3C" : "transparent")}
              >
                About Us
              </button>
            </li>
            <li
              style={{
                backgroundColor: activeItem === "aboutus" ? "#388E3C" : "transparent", // Highlight on click
                transition: "background-color 0.3s",
              }}
            >
              <button
                className="w-100 btn navbar-button"
                type="button"
                onClick={() => {
                  closeNavbar(); // Close navbar
                  navigate('/innovation'); // Navigate to About Us
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = "#388E3C")} // Hover effect
                onMouseLeave={(e) => (e.target.style.backgroundColor = activeItem === "aboutus" ? "#388E3C" : "transparent")} // Reset hover effect
              >
                Innovations
              </button>
            </li>
            <li className="" >
              <div className="dropdown">
                <button
                  className="w-100  btn dropdown-toggle custom-dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Products and Services
                </button>
                <div className="dropdown-menu" aria-labelledby="b2bDropdown">
                  <Link className="dropdown-item" to="contractmfg" onClick={() => {
                    closeNavbar(); // Close navbar
                    navigate('/contractmfg'); // Navigate to About Us
                  }}>
                    Contract Manufacturing
                  </Link>
                  <Link className="dropdown-item" to="/whychooseus">
                    Why Choose Us?
                  </Link>
                  <Link className="dropdown-item" to="/b2b">
                    Our Products
                  </Link>
                </div>

              </div>
            </li>
            <li className="">
              <div className="dropdown">
                <button
                  className="w-100 btn navbar-button"
                  type="button"
                  // onClick={() => navigate('/career')}]
                  onClick={() => {
                    closeNavbar(); // Close navbar
                    navigate('/career'); // Navigate to About Us
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = "#388E3C")} // Hover effect
                  onMouseLeave={(e) => (e.target.style.backgroundColor = activeItem === "aboutus" ? "#388E3C" : "transparent")} // Reset hover effect
                >
                  Career
                </button>
              </div>
            </li>
            <li className='mt-2'>
              <NavLink to="/contact" className="w-100 btn navbar-button"
                onClick={() => {
                  closeNavbar(); // Close navbar
                  navigate('/contact'); // Navigate to About Us
                }}>
                Contact Us
              </NavLink>
            </li>
          </ul>
        )}
        {!isSearchOpen && !isNavbarOpen && (
          <div className="row nav2row2 mb-2">
            <div className="col-6">
              <button className='btn btn-success btn-sm float-left ml-1' onClick={() => navigate('/product_page/:id')}>Shop Now</button>
            </div>
            {!user ? (
              <>
                <div className="col-6 text-right">
                  <div className="dropdown">
                    <button className="btn btn-success btn-sm"
                      onClick={() => {
                        console.log('Navigating to login...');
                        navigate('/login')
                      }}
                      style={{ margin: 'auto', marginRight: '2px' }} type="button" data-toggle="dropdown" aria-expanded="true">
                      Login
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>

                {!user ? (
                  <>
                    <li>
                      <button className='btn btn-success btn-sm text-sm' onClick={() => navigate('/login')}>Login</button>
                    </li>
                  </>
                ) : (
                  <>
                    <div className='col-6 dropdown d-flex justify-content-end align-items-center' >
                      <div className=' border p-1' role="button" data-toggle="dropdown" aria-expanded="false">
                        <FaRegCircleUser style={{ fontSize: 15 }} /> &nbsp;<FaAngleDown style={{ fontSize: 15 }} />
                      </div>
                      <div className="dropdown-menu p-2">
                        <a className="dropdown-item" href="#">
                          <div className="row">

                          </div>
                        </a>
                        <NavLink to="/my_orders"><text className="dropdown-item p-1">My Orders</text></NavLink>
                        <NavLink to="/manage_add"><text className="dropdown-item p-1"> Manage Addresses</text></NavLink>
                        <a className="  dropdown-item p-1 cursor-pointer" onClick={logout}>logout</a>
                      </div>
                    </div>
                  </>
                )}

              </>
            )}
          </div>
        )}

        {isSearchOpen && (
          <div className="container-fluid">
            <div className="row p-3">
              <div className="col-12 ">
                <div style={{ position: 'relative' }}>
                  <i
                    className="fa-solid fa-arrow-left"
                    onClick={toggleSearch}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '-20px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer'
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search for a Product.."
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  {searchText && (
                    <i
                      className="fa-solid fa-xmark"
                      onClick={clearSearchText}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  {searchResults?.length > 0 && (
                    <div className="search-suggestions mb-3">
                      {searchResults.map((result) => (
                        <div key={result._id} className="search-suggestion">
                          {result.title}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default Nav
