
import React from 'react';
import cert1 from '../Assets/cert1.jpg';
import cert2 from '../Assets/cert2.jpg';
import cert3 from '../Assets/cert3.jpg';
import "../Css/style.css";
import cert4 from "../Assets/Images/cert4.jpg"

const Certifications = () => {
  return (
    <div className="container mb-4 mt-4" style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}
    >
      <div className="row">
        <div className="col-12 text-center mb-4">
          <div className="bg-white p-5">
            <h1 className="mb-5 text-success">Certification </h1>
            <p className="lead text-justify" data-aos="fade-in">
              With ISO 9001:2015 certification for Quality Management Systems, we guarantee the utmost
              quality and safety in the manufacturing process of our Bio-fertilizers, Bio-fungicides, Bioinsecticides, and Bio-stimulants
            </p>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mb-4" data-aos="zoom-in">
            <img src={cert4} className="img-fluid" alt="Certification 1" style={{ maxWidth: "100%", height: "auto" }} />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Certifications;
