import React from 'react'
// import "../Css/style.css"
import AOS from 'aos'
import { useEffect } from 'react';

const Biopesticide = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    })
  }, [])

  return (
    <>
      <div className="row"
      style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}>
        <div className="col-12  text-center mx-auto mb-4">
          <div className=" p-5 headings">
            <h3>Biopesticides</h3>
            <div className='responsive-text text-justify' data-aos="zoom-in">
              <p>
                We specialize in manufacturing both extract-based and microbial-based biopesticides that
                offer a sustainable alternative to chemical pesticides. Our **extract-based biopesticides**
                harness the power of plant-derived compounds, delivering potent protection against pests and
                diseases while maintaining ecological balance. On the microbial side, our **microbial-based
                biopesticides** employ beneficial bacteria, fungi, and viruses that target specific pests,
                reducing harmful infestations without leaving toxic residues. These solutions offer precision
                targeting, environmental safety, and reduced pest resistance.                   </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Biopesticide
