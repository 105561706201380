
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Journey from '../Component/Journey';
import Vision from '../Component/Vision';
import Values from '../Component/Values';
import Team from '../Component/Team';
import Certifications from '../Component/Certifications';
import { UserContext } from '../Context/CreateContext';
import '../Css/style.css';
import AwardsAndRecognization from '../Component/AwardsAndRecognization';
import biocyll_Logo from '../Assets/Images/biocyll_Logo.png'

const About_us = () => {
  const { section } = useParams();
  const { user, token, login, logout } = useContext(UserContext);
  const [currentComp, setCurrentComp] = useState(section || "journey");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  // useEffect(() => {
  //   setCurrentComp(section || "journey");
  // }, [section]);

  return (
    // <>
    //   <section>
    //     <div className="container-fluid">
    //       <div className="row">
    //       </div>
    //       <div className="row pt-2 pl-3 pr-3 pb-5 background-image-opacity" style={{ backgroundColor: '#e0f7e7' }}>
    //         <div className="col-md-3 col-12 p-3 bg-white sidebar-fixed-height mb-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
    //         <h5 className='text-center text-success font-weight-bold'>About Us</h5><br/>
    //           <div
    //             className={`col-12 ${currentComp === 'journey' ? 'text-success font-weight-bold' : ''} cursor-pointer`}
    //             onClick={() => setCurrentComp('journey')}
    //           >
    //             Our Journey
    //           </div>
    //           <hr style={{ backgroundColor: 'green' }} />
    //           <div
    //             className={`col-12 ${currentComp === 'vision' ? 'text-success font-weight-bold' : ''} cursor-pointer`}
    //             onClick={() => setCurrentComp('vision')}
    //           >
    //             Vision and Mission
    //           </div>
    //           <hr style={{ backgroundColor: 'green' }} />
    //           <div
    //             className={`col-12 ${currentComp === 'values' ? 'text-success font-weight-bold' : ''} cursor-pointer`}
    //             onClick={() => setCurrentComp('values')}
    //           >
    //             Core Values
    //           </div>
    //           <hr style={{ backgroundColor: 'green' }} />
    //           <div
    //             className={`col-12 ${currentComp === 'team' ? 'text-success font-weight-bold' : ''} cursor-pointer`}
    //             onClick={() => setCurrentComp('team')}
    //           >
    //             Our Team
    //           </div>
    //           <hr style={{ backgroundColor: 'green' }} />
    //           <div
    //             className={`col-12 ${currentComp === 'cert' ? 'text-success font-weight-bold' : ''} cursor-pointer`}
    //             onClick={() => setCurrentComp('cert')}
    //           >
    //             Certifications
    //           </div>
    //           <hr style={{ backgroundColor: 'green' }} />
    //           <div
    //             className={`col-12 ${currentComp === 'AwardsAndRecognization' ? 'text-success font-weight-bold' : ''} cursor-pointer`}
    //             onClick={() => setCurrentComp('AwardsAndRecognization')}
    //           >
    //             Awards and Recognization
    //           </div>
    //         </div>
    //         <div className="col-md-9 col-12">
    //           {currentComp === 'journey' && <Journey />}
    //           {currentComp === 'vision' && <Vision />}
    //           {currentComp === 'values' && <Values />}
    //           {currentComp === 'team' && <Team />}
    //           {currentComp === 'cert' && <Certifications />}
    //           {currentComp === 'AwardsAndRecognization' && <AwardsAndRecognization />}

    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </>

    // <div>
    //   <Journey></Journey>
    //   <Vision></Vision>
    //   <Values></Values>
    //   <Team></Team>
    //   <Certifications></Certifications>
    // </div>

    // loading ? (
    //   <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }} >
    //     <img src={biocyll_Logo} alt="Biocyll Logo" style={{ width: '150px', height: 'auto' }} />
    //   </div >
    // ) : (


      <>
        <div className="container">
          <div className="fs-5">
            <Journey />
          </div>
          <div className="fs-5">
            <Vision />
          </div>
          <div className="fs-5">
            <Values />
          </div>
          <div className="fs-5">
            <Team />
          </div>
          {/* <div className="fs-5">
            <AwardsAndRecognization />
          </div> */}
        </div>
      </>
    // )
  );
};

export default About_us;
