// import React from 'react'
// import "../Css/style.css"
// const Production_fac = () => {
//   return (
//     <section className='journey-section'>
//       <div class="container-fluid">
//         <div class="row">
//           <div className="col-md-6">
//             <img src='http://iplbiologicals.com/wp-content/uploads/2023/09/Production-Technology.jpg'/>
//           </div>
//           <div class="col-md-6">
//             <div class="journey-text">
//               <h3 className='mb-2'>Production Facilities</h3>
//               <p> The manufacturing facility of Biocyll Laboratories Pvt. Ltd. in Pune, is furnished with contemporary, cutting-edge machinery, which includes bioreactors and associated utilities, an aseptic filling unit, as well as downstream equipment tailored for the production of liquid, powder, and granule formulations.</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// export default Production_fac
import React from 'react';
// import "../Css/style.css";
import AOS from 'aos'
import { useEffect } from 'react';
import prod from "../Assets/Images/prod.JPG";
const Production_fac = () => {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 2000, // Animation duration
  //   })
  // }, [])
  return (
    <section className='journey py-4 px-4 pb-4'style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className='col-md-12'>
            <h1 className='mb-2 text-success text-center'>Production Facility</h1><br />
          </div>
          <div className="journey-text col-md-6 text-justify" data-aos="zoom-in">
          <p style={{ marginTop: '50px', }}>The manufacturing facility of Biocyll Laboratories Pvt. Ltd. in Pune, is furnished with contemporary, cutting-edge machinery, which includes bioreactors and associated utilities, an aseptic filling unit, as well as downstream equipment tailored for the production of liquid, powder, and granule formulations.</p>

          
          </div>
          <div className="col-md-6" >
            <div className="journey-text" data-aos="fade-left">
            <img
              src={prod}
              className='img-fluid'
              alt='Production Facilities'
              style={{ maxWidth: '90%', height: 'auto', borderRadius: '10px'}}
            />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Production_fac;
