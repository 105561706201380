// import React from 'react'
// import "../Css/style.css"

// const Why_choose = () => {
//   return (
//     <>
//       <section className="journey-section" style={{ fontSize: "115%" }}>
//         <div className="container-fluid px-4 py-5">
//           <div className="row justify-content-center">
//             <div className="col-md-10">
//               <div className="journey-text p-4 bg-light rounded shadow">
//                 <h3 className="mb-4 text-center text-success">Why Choose Us as Your Sustainable Solution Partner?</h3>
//                 <h5 className=" mt-4 text-success"><b>Customized Solutions</b></h5>
//                 <p>
//                   Tailored formulations to meet your brand's specific needs.
//                 </p>
//                 <h5 className=" mt-4 text-success"><b>Advanced R&D</b></h5>
//                 <p>
//                   Cutting-edge research ensures superior quality and performance.
//                 </p>
//                 <h5 className=" mt-4 text-success"><b>Scalable Production</b></h5>
//                 <p>
//                   From small batches to large volumes, we’ve got you covered.
//                 </p>
//                 <h5 className=" mt-4 text-success"><b>Stringent Quality Control</b></h5>
//                 <p>
//                   Each product undergoes rigorous testing to ensure
//                   compliance and efficacy.
//                 </p>
//                 <h5 className=' mt-4 text-success'> <b>Reliable Partner</b></h5>
//                 <p>Over a decade of experience catering to agri-input marketers and
//                   distributors.</p>
//                 <b className="d-block my-3">Industries We Serve:</b>
//                 <ul className="list-unstyled">
//                   <li><i className="fa fa-check-circle text-success"></i> Agri-input companies </li>
//                   <li><i className="fa fa-check-circle text-success"></i>  Organic farming solution providers </li>
//                   <li><i className="fa fa-check-circle text-success"></i> Fertilizer and pesticide distributors</li>
//                   <li><i className="fa fa-check-circle text-success"></i> Exporters of eco-friendly agricultural products</li>
//                 </ul>
//                 <h5 className=" mt-4 text-success"><b>Take the Next Step: Request a Sample Today! </b></h5>
//                 <p>
//                   Partner with Biocyll Laboratories Pvt. Ltd. to deliver innovative, sustainable agricultural
//                   solutions. Whether you're looking for bulk manufacturing, contract manufacturing, or
//                   custom R&D services, we are here to help your brand grow.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//     </>

//   )
// }

// export default Why_choose
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const researchTopics = [
  {
    title: 'Specialized Vesicular Arbuscular Mycorrhiza (VAM)',
      description:
  'Biocyll is renowned for its expertise in the production of Vesicular Arbuscular Mycorrhiza (VAM). Our VAM solutions improve nutrient uptake, water retention, and overall soil fertility, making them an essential addition to any agricultural input portfolio.'
    },
  {
    title: 'Biofertilizers Manufacturing',
    description:
      'We specialize in producing a diverse range of biofertilizers, including nitrogen-fixing, phosphate solubilizing, and potassium - mobilizing strains.These products promote ,sustainable farming by improving nutrient availability, soil health, and plant growth. '
  },
{
  title: 'Biopesticides Manufacturing (Extract-Based and Microbial-Based) ',
    description:
  'Our biopesticides are derived from natural sources and microbial strains, offering ecofriendly solutions for pest and disease management. Whether it’s neem-based extracts or Bacillus-based microbial pesticides, we deliver tailored solutions',
  },
{
  title: 'Biostimulants Manufacturing',
    description:
'Our biostimulants are designed to improve plant metabolism, enhance stress tolerance, and optimize growth. They include amino acid-based, humic acid, and seaweed extract formulations to meet diverse agricultural needs.'
  },
{
  title: 'Micronutrient Manufacturing ',
    description:
'We produce a range of chelated and non-chelated micronutrients that address soil deficiencies and ensure optimal plant nutrition. Custom formulations are available to meet market-specific requirements.'
  },
];

const R_and_d = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    AOS.init({ duration: 1000 });
  }, []);

  const [selectedTopic, setSelectedTopic] = useState(researchTopics[0]);

  return (
    <section className="container-fluid bg-light p-4"  style={{
      boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
    }}>
      <div className="row justify-content-center">
        {/* Sidebar */}
        <div className="col-md-3 col-sm-4 ">
          <div className="list-group shadow-sm rounded">
            {researchTopics.map((topic, index) => (
              <button
                key={index}
                className={`list-group-item list-group-item-action fw-bold text-dark ${selectedTopic.title === topic.title ? 'bg-success text-white shadow' : 'hover-effect'
                  }`}
                onClick={() => setSelectedTopic(topic)}
                style={{ borderRadius: '8px', marginBottom: '8px' }}
              >
                {topic.title}
              </button>
            ))}
          </div>
        </div>

        {/* Content Section */}
        <div className="col-md-7 bg-white p-4 rounded shadow-sm" data-aos="fade-left"  style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }}>
          <h2 className="text-success text-center fw-bold p-4">What we offer</h2>
          <p className="mt-3 text-justify">{selectedTopic.description}</p>
        </div>
      </div>
    </section>
  );
};

export default R_and_d;