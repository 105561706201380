import React from 'react'

const AwardsAndRecognization=()=> {
  return (
    <div>
      <h1 className='text-success'>Awards and Recognization</h1>
    </div>
  )
}

export default AwardsAndRecognization
