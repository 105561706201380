import React from 'react'
// import "../Css/style.css"
import AOS from 'aos'
import { useEffect } from 'react';
const Biofertilizer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    })
  }, [])

  return (
    <>
      <div className="row" style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}>
        <div className="col-12 text-center mx-auto mb-4">
          <div className=" p-5 headings">
            <h3>Biofertilizers</h3>
            <div className='responsive-text text-justify' data-aos="zoom-in">
              <p>
                Our biofertilizers are designed to enhance soil fertility by introducing beneficial
                microorganisms that naturally improve nutrient availability. Through nitrogen fixation,
                phosphate solubilization, and potassium mobilization, our biofertilizers optimize nutrient
                uptake, fostering stronger and healthier crops. Each formulation is carefully crafted to ensure
                maximum efficacy in different soil types and climatic conditions, promoting sustainable and
                organic farming practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Biofertilizer
