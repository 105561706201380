
// import React from 'react';
// import AOS from 'aos';
// import { useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import 'aos/dist/aos.css'; // Import AOS CSS

// const R_and_d = () => {
//   useEffect(() => {
//     AOS.init({
//       duration: 1000, // Animation duration
//     });
//   }, []);

//   return (
//     <section className="journey">
//       <div className="container">
//         <div className="row">
//           {/* Heading */}
//           <div className="col-12 text-center mb-4">
//             <h1 className="text-success">
//               Our Expertise in Contract R&D
//             </h1>
//           </div>

//           {/* Image Section */}
//           <div className="col-md-6 mb-4 mb-md-0" data-aos="fade-right">
//             <img
//               src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxepJDRf9DAt9m_u2niFxkFJF6sNUi-KxM4Q&s"
//               className="img-fluid rounded"
//               alt="Research and Development"
//               style={{ height: '300px', width: '500px' }}
//             />
//           </div>

//           {/* Text Section */}
//           <div className="col-md-6 d-flex align-items-center" data-aos="fade-left">
//             <p className="journey-text">
//               <b>1. Biofertilizer Research and Development </b><br />
//               We offer extensive R&D support for formulating and enhancing microbial fertilizers such as
//               nitrogen-fixing, phosphate-solubilizing, and potassium-mobilizing biofertilizers. Our research
//               ensures optimal strain efficacy, product stability, and compatibility with different cropping
//               systems.
//             </p>
//           </div>
//           <div className="col-md-12 d-flex align-items-center" data-aos="fade-right">
//             <p className="journey-text mt-2">
//               <b>2. Biopesticide R&D (Extract-Based and Microbial-Based) </b><br />
//               Our team develops environmentally friendly biopesticides from natural extracts and microbial
//               strains, targeting pests and pathogens effectively while ensuring ecological safety. Whether
//               neem-based formulations or microbial bioagents, we deliver innovative solutions.
//             </p>
//           </div>
//           <div className="col-md-12 d-flex align-items-center" data-aos="fade-left">
//             <p className="journey-text">
//               <b>3. Biostimulant Formulation Development </b><br />
//               From amino acids to seaweed extracts, we create biostimulants that enhance plant growth,
//               stress tolerance, and crop yield. Our R&D ensures product consistency and efficacy for
//               diverse environmental conditions.
//             </p>
//           </div>
//           <div className="col-md-12 d-flex align-items-center" data-aos="fade-left">
//             <p className="journey-text">
//               <b>4. Micronutrient Product Research </b><br />
//               We focus on developing chelated and non-chelated micronutrient formulations tailored to
//               improve crop nutrition and soil fertility. Custom blends are designed to meet specific market
//               demands.
//             </p>
//           </div>
//           <div className="col-md-12 d-flex align-items-center" data-aos="fade-left">
//             <p className="journey-text">
//               <b>5. Specialized Vesicular Arbuscular Mycorrhiza (VAM) Research </b><br />
//               Biocyll Laboratories leads in R&D for Vesicular Arbuscular Mycorrhiza (VAM), offering
//               enhanced formulations for improved phosphorus uptake, root development, and water
//               retention. Our VAM solutions are optimized for various soil types and crops.

//             </p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default R_and_d;
import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const researchTopics = [
  {
    title: 'Specialized Vesicular Arbuscular Mycorrhiza (VAM) Research',
    description:
      'Biocyll Laboratories leads in R&D for Vesicular Arbuscular Mycorrhiza (VAM), offering enhanced formulations for improved phosphorus uptake, root development, and water retention. Our VAM solutions are optimized for various soil types and crops.',
  },
  {
    title: 'Biofertilizer Research and Development',
    description:
      'We offer extensive R&D support for formulating and enhancing microbial fertilizers such as nitrogen-fixing, phosphate-solubilizing, and potassium-mobilizing biofertilizers. Our research ensures optimal strain efficacy, product stability, and compatibility with different cropping systems.',
  },
  {
    title: 'Biopesticide R&D (Extract-Based and Microbial-Based)',
    description:
      'Our team develops environmentally friendly biopesticides from natural extracts and microbial strains, targeting pests and pathogens effectively while ensuring ecological safety. Whether neem-based formulations or microbial bioagents, we deliver innovative solutions.',
  },
  {
    title: 'Biostimulant Formulation Development',
    description:
      'From amino acids to seaweed extracts, we create biostimulants that enhance plant growth, stress tolerance, and crop yield. Our R&D ensures product consistency and efficacy for diverse environmental conditions.',
  },
  {
    title: 'Micronutrient Product Research',
    description:
      'We focus on developing chelated and non-chelated micronutrient formulations tailored to improve crop nutrition and soil fertility. Custom blends are designed to meet specific market demands.',
  },
];

const R_and_d = () => {
  useEffect(() => {
      window.scrollTo(0, 0);
    AOS.init({ duration: 1000 });
  }, []);

  const [selectedTopic, setSelectedTopic] = useState(researchTopics[0]);

  return (
    <section className="container-fluid bg-light p-4">
      <div className="row justify-content-center">
        {/* Sidebar */}
        <div className="col-md-3 col-sm-4 ">
          <div className="list-group shadow-sm rounded">
            {researchTopics.map((topic, index) => (
              <button
                key={index}
                className={`list-group-item list-group-item-action fw-bold text-dark ${
                  selectedTopic.title === topic.title ? 'bg-success text-white shadow' : 'hover-effect'
                }`}
                onClick={() => setSelectedTopic(topic)}
                style={{ borderRadius: '8px', marginBottom: '8px' }}
              >
                {topic.title}
              </button>
            ))}
          </div>
        </div>

        {/* Content Section */}
        <div className="col-md-7 bg-white p-4 rounded shadow-sm" data-aos="fade-left">
          <h2 className="text-success text-center fw-bold p-4">Our Expertise in Contract R&D</h2>
          <p className="mt-3 text-justify">{selectedTopic.description}</p>
        </div>
      </div>
    </section>
  );
};

export default R_and_d;
