
// import React, { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { useNavigate, useParams } from 'react-router-dom';
// import StarRating from '../Component/StarRating';
// import ReactModal from 'react-modal';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { IoStar, IoStarOutline } from 'react-icons/io5';


// const Product_details = () => {
//     const { token } = useContext(UserContext);
//     const [product, setProduct] = useState(null);
//     const [mainImage, setMainImage] = useState("");
//     const [relatedImages, setRelatedImages] = useState([]);
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [rating, setRating] = useState(null);
//     const [text, setText] = useState("");

//     useEffect(() => {
//         getProduct();
//     }, [id]);

//     const getProduct = async () => {
//         try {
//             const response = await axios.get(`${base_url}/api/get/product/${id}`, {
//                 headers: {
//                     Authorization: `${token}`
//                 }
//             });
//             setProduct(response.data);
//             setMainImage(response.data.images.find(img => img.name === "Product_Main_Image")?.url || "");
//             setRelatedImages(response.data.images);
//         } catch (error) {
//             console.error('Error fetching product:', error);
//         }
//     };

//     const handleRelatedImageClick = (imageUrl) => {
//         setMainImage(imageUrl);
//     };

//     const handleCart = async () => {
//         const payload = { product: id, quantity: 1 };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: { Authorization: `${token}` }
//             });
//             toast.success("Item Added Successfully");
//             navigate("/cart");
//         } catch (error) {
//             console.error('Error adding to cart:', error);
//         }
//     };

//     const handleBuyNow = async () => {
//         const payload = { product: id, quantity: 1 };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: { Authorization: `${token}` }
//             });
//             navigate("/checkout", { state: { productId: id } });
//         } catch (error) {
//             console.error('Error during buy now:', error);
//         }
//     };

//     const openModal = () => setModalIsOpen(true);
//     const closeModal = () => setModalIsOpen(false);

//     const handleSubmit = async () => {
//         try {
//             await axios.patch(`${base_url}/add-review/${id}`, { rating, text }, {
//                 headers: { Authorization: `${token}` }
//             });
//             toast.success("Review Added Successfully");
//             closeModal();
//             getProduct();
//         } catch (error) {
//             console.error('Error adding review:', error);
//         }
//     };
//     return (
//         <>
//             <ToastContainer />
//             <div className="container-fluid p-5">
//                 <div className="row">
//                     <div className="col-md-2">
//                         {relatedImages.map((item, index) => (
//                             <div
//                                 key={index}
//                                 className="col-12"
//                                 style={{ width: 120, height: 130, borderRadius: 30, marginBottom: 10, alignSelf: 'center' }}
//                                 onClick={() => handleRelatedImageClick(item.url)}
//                             >
//                                 {/* <img src={item.url} className='w-100 h-100' style={{ borderRadius: 10 }} alt={item.name} /> */}
//                             </div>
//                         ))}
//                     </div>
//                     <div className="col-md-4">
//                         <div className="col-12">
//                             <img src={mainImage} className='w-100 h-100' style={{ borderRadius: 20 }} alt="Main Product" />
//                             <div className="row mt-2">
//                                 <div className="col-12">
//                                     <button className="btn btn-success w-50 mt-2" style={{ borderRadius: 50 }} onClick={handleCart}>Add to Cart</button>
//                                     <button className="btn btn-warning w-50 mt-2" style={{ borderRadius: 50 }} onClick={handleBuyNow}>Buy Now</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='col-md-6'>
//                         <h3>{product?.title}</h3>
//                         <p>
//                             <h6 className='text-center text-danger' style={{ border: '1px solid green', borderRadius: 20, padding: 6, borderTopRightRadius: 0, backgroundColor: 'lightgreen', width: 100 }}>
//                                 {product?.Category?.category}
//                             </h6>
//                             <StarRating star={product?.averageRating} />
//                         </p>
//                         <p className='mt-2'><i className="fa-solid fa-indian-rupee-sign"></i> {product?.price}/- <b>Offer:</b> {product?.offer}%</p>
//                         <p>{product?.description}</p>
//                         <p><b>Mode of Action:</b> {product?.ModeOfAction}</p>
//                         <p><b>Dosage:</b> {product?.dosage}</p>
//                         {/* <p><b>Benefit:</b> {product?.benefit}</p> */}
//                         <div className="col-md-12">
//                             <div className="card mt-5">
//                                 <div className="card-body">
//                                     <div className="row">
//                                         <div className="col-8">
//                                             <h6>Product Ratings & Reviews</h6>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <button className='btn btn-success' onClick={openModal}>Rate Now</button>
//                                             <ReactModal
//                                                 isOpen={modalIsOpen}
//                                                 onRequestClose={closeModal}
//                                                 contentLabel="Add Your Review"
//                                                 style={{
//                                                     overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
//                                                     content: { width: '400px', margin: 'auto', height: '350px' }
//                                                 }}
//                                             >
//                                                 <div className="modal-content" style={{ border: 0 }}>
//                                                     <div className="modal-header p-2" style={{ border: 0 }}>
//                                                         <button type="button" className="close" onClick={closeModal} aria-label="Close">
//                                                             <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
//                                                         </button>
//                                                     </div>
//                                                     <div className="modal-body">
//                                                         <h4 className="modal-title text-center mb-3">Add Your Review</h4>
//                                                         <div className='text-center' style={{ fontSize: 25, display: 'flex', gap: 10, justifyContent: 'center', marginTop: 10, marginBottom: 20 }}>
//                                                             {[1, 2, 3, 4, 5].map(value => (
//                                                                 rating >= value
//                                                                     ? <IoStar key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'yellow' }} />
//                                                                     : <IoStarOutline key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'black' }} />
//                                                             ))}
//                                                         </div>
//                                                         <textarea placeholder='Description' name='text' className='form-control' value={text} onChange={(e) => setText(e.target.value)} />
//                                                     </div>
//                                                     <div className="modal-footer" style={{ border: 0 }}>
//                                                         <button type="button" className="btn btn-success" onClick={handleSubmit}>Submit Review</button>
//                                                     </div>
//                                                 </div>
//                                             </ReactModal>
//                                         </div>
//                                         <div className="col-md-12">
//                                             {product?.reviews?.map((item, index) => (
//                                                 <div key={index} className="row">
//                                                     <div className="col-12">{item.name}</div>
//                                                     <div className="col-4"><StarRating star={Number(item.rating)} /></div>
//                                                     <div className="col-8">{item.review}</div>
//                                                     <hr />
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
// export default Product_details;
// import React, { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { useNavigate, useParams } from 'react-router-dom';
// import StarRating from '../Component/StarRating';
// import ReactModal from 'react-modal';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { IoStar, IoStarOutline } from 'react-icons/io5';
// import { FaStrikethrough } from 'react-icons/fa6';

// const Product_details = () => {
//     const { token } = useContext(UserContext);
//     const [product, setProduct] = useState(null);
//     const [mainImage, setMainImage] = useState("");
//     const [relatedImages, setRelatedImages] = useState([]);
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [rating, setRating] = useState(null);
//     const [text, setText] = useState("");

//     useEffect(() => {
//         getProduct();
//     }, [id]);

//     const getProduct = async () => {
//         try {
//             const response = await axios.get(`${base_url}/api/get/product/${id}`, {
//                 headers: {
//                     Authorization: `${token}`
//                 }
//             });
//             setProduct(response.data);
//             setMainImage(response.data.images.find(img => img.name === "Product_Main_Image")?.url || "");
//             setRelatedImages(response.data.images);
//         } catch (error) {
//             console.error('Error fetching product:', error);
//         }
//     };

//     const handleRelatedImageClick = (imageUrl) => {
//         setMainImage(imageUrl);
//     };

//     const handleCart = async () => {
//         const payload = { product: id, quantity: 1 };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: { Authorization: `${token}` }
//             });
//             toast.success("Item Added Successfully");
//             navigate("/cart");
//         } catch (error) {
//             console.error('Error adding to cart:', error);
//         }
//     };

//     const handleBuyNow = async () => {
//         const payload = { product: id, quantity: 1 };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: { Authorization: `${token}` }
//             });
//             navigate("/checkout", { state: { productId: id } });
//         } catch (error) {
//             console.error('Error during buy now:', error);
//         }
//     };

//     const openModal = () => setModalIsOpen(true);
//     const closeModal = () => setModalIsOpen(false);

//     const handleSubmit = async () => {
//         try {
//             await axios.patch(`${base_url}/add-review/${id}`, { rating, text }, {
//                 headers: { Authorization: `${token}` }
//             });
//             toast.success("Review Added Successfully");
//             closeModal();
//             getProduct();
//         } catch (error) {
//             console.error('Error adding review:', error);
//         }
//     };

//     return (
//         <>
//             <ToastContainer />
//             <div className="container-fluid p-5" style={{ fontFamily: 'Verdana, Tahoma, sans-serif' }}>
//                 <div className="row">
//                     <div className="col-md-2">
//                         {/* This is where the related images are displayed. You can keep or remove it based on your requirements. */}
//                         {relatedImages.length > 0 && (
//                             <div
//                                 className="col-12"
//                                 style={{ width: 300, height: 250, borderRadius: 30, marginBottom: 10, alignSelf: 'center' }}
//                                 onClick={() => handleRelatedImageClick(relatedImages[0].url)}
//                             >

//                                 <img src={relatedImages[0].url} className='w-100 h-100' style={{ borderRadius: 10 }} alt="Related" />
//                                 <div className="col-md-4">
//                                     <div className="col-12">


//                                     </div>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                     <div className="col-md-4">
//                         <div className="col-12">
//                             {relatedImages.length > 0 && (
//                                 <div
//                                     className="col-12"
//                                     style={{ width: 250, height: 300, borderRadius: 30, marginBottom: 10, alignSelf: 'center' }}
//                                     onClick={() => handleRelatedImageClick(relatedImages[0].url)}
//                                 >
//                                     <img src={relatedImages[0].url} className='w-100 h-100' style={{ borderRadius: 10 }} alt="Related" />
//                                     <div className="col-md-4">
//                                         <div className="col-12">
//                                         </div>
//                                     </div>
//                                 </div>
//                             )}
//                             {/* <img src={mainImage} className='w-100 h-100' style={{ borderRadius: 20 }} alt="Main Product" /> */}
//                             <div className="row mt-2 mr-5">
//                                 <div className="col-12">
//                                     <button className="btn btn-success w-50 mt-2" style={{ borderRadius: 50 }} onClick={handleCart}>Add to Cart</button>
//                                     <button className="btn btn-warning w-50 mt-2" style={{ borderRadius: 50 }} onClick={handleBuyNow}>Buy Now</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='col-md-6'>
//                         <h3>{product?.title}</h3>
//                         <p>
//                             <h6 className='text-center text-danger' style={{ border: '1px solid green', borderRadius: 20, padding: 6, borderTopRightRadius: 0, backgroundColor: 'lightgreen', width: 200 }}>
//                                 {product?.Category?.category}
//                             </h6>
//                             <StarRating star={product?.averageRating} />
//                         </p>
//                         {/* <p className='mt-2'><i className="fa-solid fa-indian-rupee-sign"></i> {product?.price}/-  <b>Offer:</b> {product?.offer}%</p> */}
//                         <p className='mt-2'>
//                             <del><i className="fa-solid fa-indian-rupee-sign"></i> {product?.price}/-</del>
//                             &nbsp;<b>Offer:</b> {product?.offer}%
//                         </p>

//                         <p>{product?.description}</p>
//                         <p><b>Mode of Action:</b> {product?.ModeOfAction}</p>
//                         <p><b>Dosage:</b> {product?.dosage}</p>
//                         <p><b>Crops Recommended:</b> {product?.corps}</p>
//                         <div className="col-md-12">
//                             <div className="card mt-5">
//                                 <div className="card-body">
//                                     <div className="row">
//                                         <div className="col-8">
//                                             <h6>Product Ratings & Reviews</h6>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <button className='btn btn-success' onClick={openModal}>Rate Now</button>
// <ReactModal
//     isOpen={modalIsOpen}
//     onRequestClose={closeModal}
//     contentLabel="Add Your Review"
//     style={{
//         overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
//         content: { width: '400px', margin: 'auto', height: '350px' }
//     }}
// >
//     <div className="modal-content" style={{ border: 0 }}>
//         <div className="modal-header p-2" style={{ border: 0 }}>
//             <button type="button" className="close" onClick={closeModal} aria-label="Close">
//                 <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
//             </button>
//         </div>
//         <div className="modal-body">
//             <h4 className="modal-title text-center mb-3">Add Your Review</h4>
//             <div className='text-center' style={{ fontSize: 25, display: 'flex', gap: 10, justifyContent: 'center', marginTop: 10, marginBottom: 20 }}>
//                 {[1, 2, 3, 4, 5].map(value => (
//                     rating >= value
//                         ? <IoStar key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'yellow' }} />
//                         : <IoStarOutline key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'black' }} />
//                 ))}
//             </div>
//             <textarea placeholder='Description' name='text' className='form-control' value={text} onChange={(e) => setText(e.target.value)} />
//         </div>
//         <div className="modal-footer" style={{ border: 0 }}>
//             <button type="button" className="btn btn-success" onClick={handleSubmit}>Submit Review</button>
//         </div>
//     </div>
// </ReactModal>
//                                         </div>
// <div className="col-md-12">
//     {product?.reviews?.map((item, index) => (
//         <div key={index} className="row">
//             <div className="col-12">{item.name}</div>
//             <div className="col-4"><StarRating star={Number(item.rating)} /></div>
//             <div className="col-8">{item.review}</div>
//             <hr />
//         </div>
//     ))}
// </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };
// export default Product_details;



// import React, { useContext, useEffect, useState } from 'react';
// import { UserContext } from '../Context/CreateContext';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { useNavigate, useParams } from 'react-router-dom';
// import StarRating from '../Component/StarRating';
// import ReactModal from 'react-modal';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { IoStar, IoStarOutline } from 'react-icons/io5';
// // import "../Css/style.css";
// import "bootstrap/dist/css/bootstrap.min.css";

// const Product_details = ({ image }) => {
//     const [zoomStyle, setZoomStyle] = useState({});
//     const [showZoom, setShowZoom] = useState(false);
//     const [zoomPosition, setZoomPosition] = useState({});
//     const { token } = useContext(UserContext);
//     const [product, setProduct] = useState(null);
//     const [mainImage, setMainImage] = useState("");
//     const [relatedImages, setRelatedImages] = useState([]);
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [rating, setRating] = useState(null);
//     const [text, setText] = useState("");
//     const [showModal, setShowModal] = useState(false);
//     const [mobileNumber, setMobileNumber] = useState('')
//     const [error, setError] = useState('');
//     const [name, setName] = useState('')
//     const [address, setAddress] = useState('')
//     const openModal2 = () => setModalIsOpen2(true);
//     const [modalIsOpen2, setModalIsOpen2] = useState(false);
//     const closeModal2 = () => setModalIsOpen2(false);
//     const [showModal2, setShowModal2] = useState(false);
//     const [values, setValues] = useState({
//         username: "",
//         email: "",
//         mobileNo: "",
//         address: "",
//     });
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);
//     useEffect(() => {
//         getProduct();
//     }, [id]);
//     const handleMouseMove = (e) => {
//         const { left, top, width, height } = e.target.getBoundingClientRect();
//         const x = ((e.clientX - left) / width) * 100;
//         const y = ((e.clientY - top) / height) * 100;

//         setZoomStyle({
//             backgroundImage: `url(${image})`,
//             backgroundPosition: `${x}% ${y}%`,
//             backgroundSize: "200%", // Adjust zoom level here
//         });

//         setZoomPosition({
//             top: top,
//             left: left + width + 10, // Position it to the right with a small gap
//         });

//         setShowZoom(true);
//     };
//     const handleMouseLeave = () => {
//         setShowZoom(false);
//     };
//     const getProduct = async () => {
//         try {
//             const response = await axios.get(`${base_url}/api/get/product/${id}`, {
//                 headers: {
//                     Authorization: `${token}`
//                 }
//             });
//             setProduct(response.data);
//             setMainImage(response.data.images.find(img => img.name === "Product_Main_Image")?.url || "");
//             setRelatedImages(response.data.images);
//         } catch (error) {
//             console.error('Error fetching product:', error);
//         }
//     };

//     const handleRelatedImageClick = (imageUrl) => {
//         setMainImage(imageUrl);
//     };

//     const handleCart = async () => {
//         const payload = { product: id, quantity: 1 };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: { Authorization: `${token}` }
//             });
//             toast.success("Item Added Successfully");
//             navigate("/cart");
//         } catch (error) {
//             console.error('Error adding to cart:', error);
//         }
//     };

//     const handleBuyNow = async () => {
//         const payload = { product: id, quantity: 1 };
//         try {
//             await axios.post(`${base_url}/api/post/card`, payload, {
//                 headers: { Authorization: `${token}` }
//             });
//             navigate("/checkout", { state: { productId: id } });
//         } catch (error) {
//             console.error('Error during buy now:', error);
//         }
//     };
//     const openModal = () => setModalIsOpen(true);
//     const closeModal = () => setModalIsOpen(false);

//     const handleSubmit = async () => {
//         try {
//             await axios.patch(`${base_url}/add-review/${id}`, { rating, text }, {
//                 headers: { Authorization: `${token}` }
//             });
//             toast.success("Review Added Successfully");
//             closeModal();
//             getProduct();
//         } catch (error) {
//             console.error('Error adding review:', error);
//         }
//     };
//     // buy now
//     const buynow = () => {
//         axios.post(`${base_url}/api/signup`, values)
//             .then((response) => {
//                 if (response.data.status === true) {
//                     alert(response.data.message); // Show success message
//                     navigate("/login"); // Redirect to the login page
//                 }
//             })
//             .catch((error) => {
//                 if (error.response && error.response.data && error.response.data.message) {
//                     toast.error(error.response.data.message); // Display error message
//                 } else {
//                     console.error('Unexpected error:', error);
//                     toast.error('Something went wrong. Please try again.'); // Fallback error message
//                 }
//             });
//     }

//     return (
//         <>
//             <ToastContainer />
//             <div className="container-fluid p-5 product-details">
//                 <div className="row">
//                     <div className="col-md-3 d-flex flex-column align-items-center">
//                         {relatedImages.length > 0 && (
//                             <div className="d-flex flex-wrap">
//                                 {relatedImages.slice(0, 2).map((image, index) => (
//                                     <div
//                                         key={index}
//                                         className="position-relative overflow-hidden border"
//                                         onMouseMove={(e) => handleMouseMove(e, image.url)}
//                                         onMouseLeave={handleMouseLeave}
//                                         style={{
//                                             width: 350,
//                                             height: 300,
//                                             borderRadius: 30,
//                                             marginBottom: 10,
//                                             alignSelf: "center",
//                                             transition: "transform 0.2s ease-in-out",
//                                         }}
//                                     >
//                                         <img
//                                             src={image.url}
//                                             className="w-100 h-100"
//                                             style={{ borderRadius: 10 }}
//                                         // alt="Related"
//                                         />
//                                     </div>
//                                 ))}
//                             </div>
//                         )}
//                         <button
//                             className="btn btn-success mt-2"
//                             style={{ width: '200px', borderRadius: '10px' }}
//                             onClick={openModal2}
//                         >
//                             Enquire Now
//                         </button>
//                         <ReactModal
//                             isOpen={modalIsOpen2}
//                             onRequestClose={closeModal}
//                             contentLabel="Add Your Review"
//                             style={{
//                                 overlay: {
//                                     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                                     zIndex: 1050  // Ensure modal is above navbar
//                                 },
//                                 content: {
//                                     width: '600px',
//                                     margin: 'auto',
//                                     height: 'auto',
//                                     padding: '20px',
//                                     borderRadius: '10px',
//                                     zIndex: 1100 // Ensure modal content is above everything
//                                 }
//                             }}
//                         >
//                             <div className="modal-content" style={{ border: 0 }}>
//                                 <div className="">
//                                     {/* Rating Section */}
//                                     <div className="modal-header p-2" style={{ border: 0 }}>
//                                         <button type="button" className="close" onClick={closeModal2} aria-label="Close">
//                                             <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
//                                         </button>
//                                     </div>
//                                     <h5 className="text-center mb-3">Add Your Enquiry</h5>
//                                     <div className="col-md-12 bg-light p-4">
//                                         <form onSubmit={handleSubmit}>
//                                             <div className="row">
//                                                 <div className="col-md-6 col-sm-12 mt-3">
//                                                     Full Name:
//                                                     <input placeholder='Enter Your Name' className='form-control' name='Name' required />
//                                                 </div>
//                                                 <div className="col-md-6 col-sm-12 mt-3">
//                                                     Phone:
//                                                     <input placeholder='Enter Your Phone Number' className='form-control' name='Phone' required />
//                                                 </div>
//                                                 <div className="col-md-6 col-sm-12 mt-3">
//                                                     Email:
//                                                     <input placeholder='Enter Your Email' className='form-control' name='Email' />
//                                                 </div>
//                                                 <div className="col-md-6 col-sm-12 mt-3">
//                                                     Company Name:
//                                                     <input placeholder='Enter Your Company Name' className='form-control' name='Company' />
//                                                 </div>
//                                                 <div className='col-12 mt-3'>
//                                                     Message:
//                                                     <textarea placeholder='Message' className='form-control' name='Message' style={{ height: '100px' }} />
//                                                 </div>
//                                                 <div className='col-12 mt-3 text-center'>
//                                                     <button type='submit' className='btn btn-success btn-lg'>Submit</button>
//                                                     <button type='submit' className='btn btn-success btn-lg ml-2' onClick={closeModal2}>Cancel</button>
//                                                 </div>
//                                             </div>
//                                         </form>
//                                     </div>
//                                 </div>
//                             </div>
//                         </ReactModal>

//                     </div>
//                     <div className="col-md-8 col-12 product-info ml-4">
//                         <h3>{product?.title}</h3>
//                         <p className='category-badge'>{product?.Category?.category}</p>
//                         <StarRating star={product?.averageRating} />
//                         <p>
//                         </p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Technical Name :</b>  {product?.ProductSpecification?.technicalNameL}</p>
//                         <p>
//                             <b className="text-sm"
//                                 style={{
//                                     backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                                 }}
//                             >
//                                 Product Code :</b> {product?.productcode}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Product Form :</b>   {product?.productform}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Packaging Type :</b> {product?.packagingtype}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Packing Available :</b> {product?.packagingavailable}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             What is product? : </b>  {product?.description}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Specification as per FCO Act, 1985 :</b> {product?.purpose}</p>
//                         <ul>
//                             <li><b>pH Range:</b> {product?.phrange}</li>
//                             <li><b>CFU Count:</b> {product?.cfuCount}</li>
//                         </ul>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Mode of Action:</b> {product?.ModeOfAction}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Benefits:</b> {product?.benfit}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Crops Recommended:</b> {product?.corps}</p>
//                         <p><b className="text-sm"
//                             style={{
//                                 backgroundColor: '#367c2b', color: "white", padding: "4px 18px", borderRadius: "5px",
//                             }}
//                         >
//                             Method of Application and Dosage:</b> {product?.dosage}</p>

//                         <div className="reviews-section">
//                             <h6>Product Ratings & Reviews</h6>
//                             <button className="btn" 
//                             style={{  backgroundColor: '#367c2b', color:"white"}}
//                             onClick={openModal}><b>Rate Now</b></button>
//                             <ReactModal
//                                 isOpen={modalIsOpen}
//                                 onRequestClose={closeModal}
//                                 contentLabel="Add Your Review"
//                                 style={{
//                                     overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
//                                     content: { width: '400px', margin: 'auto', height: '350px' }
//                                 }}
//                             >
//                                 <div className="modal-content" style={{ border: 0 }}>
//                                     <div className="modal-header p-2" style={{ border: 0 }}>
//                                         <button type="button" className="close" onClick={closeModal} aria-label="Close">
//                                             <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
//                                         </button>
//                                     </div>
//                                     <div className="modal-body">
//                                         <h4 className="modal-title text-center mb-3">Add Your Rating</h4>
//                                         <div className='text-center' style={{ fontSize: 25, display: 'flex', gap: 10, justifyContent: 'center', marginTop: 10, marginBottom: 20 }}>
//                                             {[1, 2, 3, 4, 5].map(value => (
//                                                 rating >= value
//                                                     ? <IoStar key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'yellow' }} />
//                                                     : <IoStarOutline key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'black' }} />
//                                             ))}
//                                         </div>
//                                         <textarea placeholder='Description' name='text' className='form-control' value={text} onChange={(e) => setText(e.target.value)} />
//                                     </div>
//                                     <div className="modal-footer" style={{ border: 0 }}>
//                                         <button type="button" className="btn btn-success" onClick={handleSubmit}>Submit Review</button>
//                                     </div>
//                                 </div>
//                             </ReactModal>
//                             {/* <ReactModal
//                                 isOpen={modalIsOpen}
//                                 onRequestClose={closeModal}
//                                 contentLabel="Add Your Review"
//                                 style={{
//                                     overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
//                                     content: {
//                                         // width: '90%', // Default width for smaller screens
//                                         maxWidth: '300px', // Cap the maximum width
//                                         margin: 'auto',
//                                         height: 'auto', // Allow height to adjust automatically
//                                         padding: '20px',
//                                         borderRadius: '10px',
//                                         boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
//                                         overflow: 'hidden', // Ensure content doesn't overflow
//                                     }
//                                 }}
//                             >
//                                 <div className="modal-content">
//                                     <div className="modal-header p-2">
//                                         <button type="button" className="close" onClick={closeModal} aria-label="Close">
//                                             <span aria-hidden="true">
//                                                 <i className="fa-solid fa-xmark text-dark"></i>
//                                             </span>
//                                         </button>
//                                     </div>
//                                     <div className="modal-body">
//                                         <h4 className="modal-title text-center mb-3">Add Your Review</h4>
//                                         <div
//                                             className="text-center"
//                                             style={{
//                                                 fontSize: 25,
//                                                 display: 'flex',
//                                                 gap: 10,
//                                                 justifyContent: 'center',
//                                                 marginTop: 10,
//                                                 marginBottom: 20,
//                                             }}
//                                         >
//                                             {[1, 2, 3, 4, 5].map((value) =>
//                                                 rating >= value ? (
//                                                     <IoStar
//                                                         key={value}
//                                                         onClick={() => setRating(value)}
//                                                         style={{ cursor: 'pointer', color: 'yellow' }}
//                                                     />
//                                                 ) : (
//                                                     <IoStarOutline
//                                                         key={value}
//                                                         onClick={() => setRating(value)}
//                                                         style={{ cursor: 'pointer', color: 'black' }}
//                                                     />
//                                                 )
//                                             )}
//                                         </div>
//                                         <textarea
//                                             placeholder="Description"
//                                             name="text"
//                                             className="form-control"
//                                             value={text}
//                                             onChange={(e) => setText(e.target.value)}
//                                             style={{ resize: 'none', minHeight: '100px', width: '100%', marginBottom: '10px' }}
//                                         />
//                                     </div>
//                                     <div className="modal-footer">
//                                         <button type="button" className="btn btn-success" onClick={handleSubmit}>
//                                             Submit Review
//                                         </button>
//                                     </div>
//                                 </div>
//                             </ReactModal> */}

//                         </div>
//                         <div className="col-md-12 mt-5">
//                             {product?.reviews?.map((item, index) => (
//                                 <div key={index} className="row">
//                                     <div className="col-12">{item.name}</div>
//                                     <div className="col-4"><StarRating star={Number(item.rating)} /></div>
//                                     <div className="col-8">{item.review}</div>
//                                     <hr />
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div >
//         </>
//     );
// };

// export default Product_details;



import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../Context/CreateContext';
import axios from 'axios';
import { base_url } from '../Config/Index';
import { useNavigate, useParams } from 'react-router-dom';
import StarRating from '../Component/StarRating';
import ReactModal from 'react-modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoStar, IoStarOutline } from 'react-icons/io5';
import "bootstrap/dist/css/bootstrap.min.css";


const Product_details = ({ image }) => {
    const [zoomStyle, setZoomStyle] = useState({});
    const [showZoom, setShowZoom] = useState(false);
    const [zoomPosition, setZoomPosition] = useState({});
    const { token } = useContext(UserContext);
    const [product, setProduct] = useState(null);
    const [mainImage, setMainImage] = useState("");
    const [relatedImages, setRelatedImages] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [rating, setRating] = useState(null);
    const [text, setText] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('')
    const [error, setError] = useState('');
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const openModal2 = () => setModalIsOpen2(true);
    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const closeModal2 = () => setModalIsOpen2(false);
    const [showModal2, setShowModal2] = useState(false);
    const [fullName, setFullname] = useState("");
    const [phone,setphone] = useState("");
    const [email,setemail] = useState("");
    const [companyName,setcompanyname] = useState("");
    const [message,setmessage] = useState("");
    const [productName,setproductname] = useState("");
    const [values, setValues] = useState({
        username: "",
        email: "",
        mobileNo: "",
        address: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getProduct();
    }, [id]);

    const handleMouseMove = (e, url) => {
        console.log(url, "url")
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100;
        const y = ((e.clientY - top) / height) * 100;

        setZoomStyle({
            backgroundImage: `url(${url})`,
            backgroundPosition: `${x}% ${y}%`,
            backgroundSize: "200%",
        });

        setZoomPosition({
            top: top,
            left: left + width + 10,
        });

        setShowZoom(true);
    };

    const handleMouseLeave = () => {
        setShowZoom(false);
    };

    const getProduct = async () => {
        try {
            const response = await axios.get(`${base_url}/api/get/product/${id}`, {
                headers: {
                    Authorization: `${token}`
                }
            });
            setProduct(response.data);
            setMainImage(response.data.images.find(img => img.name === "Product_Main_Image")?.url || "");
            setRelatedImages(response.data.images);
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };


   
    const handleRelatedImageClick = (imageUrl) => {
        setMainImage(imageUrl);
    };

    const handleCart = async () => {
        const payload = { product: id, quantity: 1 };
        try {
            await axios.post(`${base_url}/api/post/card`, payload, {
                headers: { Authorization: `${token}` }
            });
            toast.success("Item Added Successfully");
            navigate("/cart");
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    };

   

    const enquirenow = () => {
        const payload = {
            fullName:fullName,
            phone : phone,
            email : email ,
            companyName : companyName,
            message : message,
            productName : productName
        };
    
        axios.post(`${base_url}/api/post/add/enquiry`, payload, {
            headers: { Authorization: `${token}` }
        })
        .then(() => {
            console.log('Enquiry submitted successfully!');
        })
        .catch((error) => {
            console.error('Error during enquire now:', error);
        });
    };
    
    
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const handleSubmit = async () => {
        try {
            await axios.patch(`${base_url}/add-review/${id}`, { rating, text }, {
                headers: { Authorization: `${token}` }
            });
            toast.success("Review Added Successfully");
            closeModal();
            getProduct();
        } catch (error) {
            console.error('Error adding review:', error);
        }
    };
    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents page refresh
    
        const formData = new FormData(event.target);
        const payload = {
            fullName: formData.get("fullName"),
            phone: formData.get("phone"),
            email: formData.get("email"),
            companyName: formData.get("companyName"),
            message: formData.get("message"),
            productName: formData.get("productName"),
        };
    
        // Enquiry API call
        axios.post(`${base_url}/api/post/add/enquiry`, payload, {
            headers: { Authorization: `${token}` },
        })
        .then(() => {
            toast.success("Enquiry submitted successfully!");
            event.target.reset(); // Reset form after successful submission
        })
        .catch((error) => {
            console.error("Error during enquiry submission:", error);
            toast.error("Failed to submit enquiry. Please try again.");
        });
    };
    
    
    return (
        <>
            <ToastContainer />
            <div className="container-fluid p-5 product-details">
                <div className="row">
                    <div className="col-md-3 d-flex flex-column align-items-center">
                        {relatedImages.length > 0 && (
                            <div className="d-flex flex-wrap position-relative">
                                {relatedImages.slice(0, 2).map((image, index) => (
                                    <div
                                        key={index}
                                        className="position-relative overflow-hidden border"
                                        onMouseMove={(e) => handleMouseMove(e, image.url)}
                                        onMouseLeave={handleMouseLeave}
                                        style={{
                                            width: 350,
                                            height: 300,
                                            borderRadius: 30,
                                            marginBottom: 10,
                                            alignSelf: "center",
                                            transition: "transform 0.2s ease-in-out",
                                        }}
                                    >
                                        <img
                                            src={image.url}
                                            className="w-100 h-100"
                                            style={{ borderRadius: 10, objectFit: "cover" }}
                                            alt="Related"
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        {console.log(zoomStyle.backgroundImage, "backgroundimage")}
                        {showZoom && zoomStyle.backgroundImage && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: zoomPosition.top,
                                    left: zoomPosition.left,
                                    width: '350px',
                                    height: '300px',
                                    backgroundImage: zoomStyle.backgroundImage,
                                    backgroundPosition: zoomStyle.backgroundPosition,
                                    backgroundSize: zoomStyle.backgroundSize || "200%",
                                    // backgroundRepeat: "no-repeat",
                                    border: '1px solid #ccc',
                                    borderRadius: '10px',
                                    zIndex: 1000,
                                }}
                            />
                        )}

                        <button
                            className="btn btn-success mt-2"
                            style={{ width: '200px', borderRadius: '10px' }}
                            onClick={openModal2}
                        >
                            Enquire Now
                        </button>
                        <ReactModal
                            isOpen={modalIsOpen2}
                            onRequestClose={closeModal2}
                            contentLabel="Add Your Review"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    zIndex: 1050
                                },
                                content: {
                                    width: '600px',
                                    margin: 'auto',
                                    height: 'auto',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    zIndex: 1100
                                }
                            }}
                        >
                            <div className="modal-content" style={{ border: 0 }}>
                                <div className="">
                                    <div className="modal-header p-2" style={{ border: 0 }}>
                                        <button type="button" className="close" onClick={closeModal2} aria-label="Close">
                                            <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
                                        </button>
                                    </div>
                                    <h5 className="text-center mb-3">Add Your Enquiry</h5>
                                    <div className="col-md-12 bg-light p-4">
                                    <form onSubmit={handleFormSubmit}>
            <div className="row">
                <div className="col-md-6 col-sm-12 mt-3">
                    Full Name:
                    <input type="text" placeholder="Enter Your Name" className="form-control" name="fullName" required />
                </div>
                <div className="col-md-6 col-sm-12 mt-3">
                    Phone:
                    <input type="text" placeholder="Enter Your Phone Number" className="form-control" name="phone" required />
                </div>
                <div className="col-md-6 col-sm-12 mt-3">
                    Email:
                    <input type="email" placeholder="Enter Your Email" className="form-control" name="email" required />
                </div>
                <div className="col-md-6 col-sm-12 mt-3">
                    Company Name:
                    <input type="text" placeholder="Enter Your Company Name" className="form-control" name="companyName" />
                </div>
                <div className="col-md-6 col-sm-12 mt-3">
                    Product Name:
                    <input type="text" placeholder="Enter Product Name" className="form-control" name="productName" />
                </div>
                <div className="col-12 mt-3">
                    Message:
                    <textarea placeholder="Message" className="form-control" name="message" style={{ height: "100px" }} required />
                </div>
                <div className="col-12 mt-3 text-center">
                    <button type="submit" className="btn btn-success btn-lg">Submit</button>
                    <button type="button" className="btn btn-danger btn-lg ml-2" onClick={closeModal2}>Cancel</button>
                </div>
            </div>
        </form>
                                    </div>
                                </div>
                            </div>
                        </ReactModal>
                    </div>
                    <div className="col-md-8 col-12 product-info ml-4">
                        <h3>{product?.title}</h3>
                        <p className='category-badge'>{product?.Category?.category}</p>
                        <StarRating star={product?.averageRating} />
                        <p>
                        </p>
                        <div className="border p-2">
                            <b>Technical Name:</b> {product?.ProductSpecification?.technicalNameL}
                        </div>
                        <br />
                        <div className="border p-2">
                            <b className="text-sm">
                                Product Code :</b> {product?.productcode}</div>
                        <br />
                        <div className="border p-2">
                            <b className="text-sm">
                                Product Form :</b>
                            <ul>
                                {product?.productform?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />

                        <div className="border p-2">
                            <b className="text-sm"> Packaging Type :</b>
                            <ul>
                                {product?.packagingtype?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />
                        <div className="border p-2">
                            <b className="text-sm"   >
                                Packing Available :</b>
                            <ul>
                                {product?.packagingavailable?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />
                        <div className="border p-2">
                            <b className="text-sm">
                                What is product? :
                            </b>
                            <ul>
                                {product?.description?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />
                        <div className="border p-2">
                            <b className="text-sm">
                                Specification as per FCO Act, 1985 :</b>
                            <ul>
                                {product?.purpose?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>

                            <ul>
                                <li><b>pH Range:</b> {product?.phrange}</li>
                                <li><b>CFU Count:</b> {product?.cfuCount}</li>
                            </ul>
                        </div>
                        <br />
                        <div className="border p-2">
                            <b className="text-sm"
                            >
                                Mode of Action:</b>
                            <ul>
                                {product?.ModeOfAction?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />
                        {/* <p><b className="text-sm">
                            Benefits:</b></p>
                            <ul>
                            {product?.benfit?.split('. ').map((sentence, index) =>
                                sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                            )}
                        </ul> */}
                        <div className="border p-2">
                            <b className="text-sm" >
                                Crops Recommended:</b>
                            <ul>
                                {product?.corps?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />
                        {/* {product?.corps} */}
                        <div className="border p-2">
                            <b className="text-sm"
                            >
                                Method of Application and Dosage:</b>
                            <ul>
                                {product?.dosage?.split('. ').map((sentence, index) =>
                                    sentence.trim() !== '' ? <li key={index}>{sentence}.</li> : null
                                )}
                            </ul>
                        </div>
                        <br />
                        <div className="reviews-section">
                            <h6>Product Ratings & Reviews</h6>
                            <button className="btn"
                                style={{ backgroundColor: '#367c2b', color: "white" }}
                                onClick={openModal}><b>Rate Now</b></button>
                            <ReactModal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Add Your Review"
                                style={{
                                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                                    content: { width: '400px', margin: 'auto', height: '350px' }
                                }}
                            >
                                <div className="modal-content" style={{ border: 0 }}>
                                    <div className="modal-header p-2" style={{ border: 0 }}>
                                        <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                            <span aria-hidden="true"><i className="fa-solid fa-xmark text-dark"></i></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <h4 className="modal-title text-center mb-3">Add Your Rating</h4>
                                        <div className='text-center' style={{ fontSize: 25, display: 'flex', gap: 10, justifyContent: 'center', marginTop: 10, marginBottom: 20 }}>
                                            {[1, 2, 3, 4, 5].map(value => (
                                                rating >= value
                                                    ? <IoStar key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'yellow' }} />
                                                    : <IoStarOutline key={value} onClick={() => setRating(value)} style={{ cursor: 'pointer', color: 'black' }} />
                                            ))}
                                        </div>
                                        <textarea placeholder='Description' name='text' className='form-control' value={text} onChange={(e) => setText(e.target.value)} />
                                    </div>
                                    <div className="modal-footer" style={{ border: 0 }}>
                                        <button type="button" className="btn btn-success" onClick={handleSubmit}>Submit Review</button>
                                    </div>
                                </div>
                            </ReactModal>
                        </div>
                        <div className="col-md-12 mt-5">
                            {product?.reviews?.map((item, index) => (
                                <div key={index} className="row">
                                    <div className="col-12">{item.name}</div>
                                    <div className="col-4"><StarRating star={Number(item.rating)} /></div>
                                    <div className="col-8">{item.review}</div>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Product_details;