import React from 'react'
import AOS from 'aos'
import { useEffect } from 'react';
function Micronutrients() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    })
  }, [])
  return (
    <>
      <div className="row" style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}>
        <div className="col-12 text-center mx-auto mb-4">
          <div className="p-5 headings">
            <h3>Micronutrients</h3>
            <div className='responsive-text text-justify' data-aos="zoom-in">
              <p>
                Micronutrients play a critical role in plant nutrition, and our high-quality formulations are
                designed to address specific deficiencies in the soil. Our range of micronutrients ensures
                optimal crop health by improving chlorophyll production, boosting immune responses, and
                enhancing enzymatic activities in plants. Each product is developed with precise attention to
                bioavailability, ensuring that nutrients are delivered effectively to crops for maximum impact
                on growth and productivity.                   </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Micronutrients
