import logo from './logo.svg';
import './App.css';
import Index from './Routes/Index';
import { UserContext } from './Context/CreateContext';
import Nav from './Component/Nav';
import Footer from './Component/Footer';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom

function App() {
  const { user } = useContext(UserContext);
  const location = useLocation(); // Use useLocation to get the current route

  // Define paths where navbar and footer should not appear
  const hideNavbarFooter = ['/login', '/register'];

  return (
    <>
      {/* Conditionally render Nav and Footer */}
      {!hideNavbarFooter.includes(location.pathname) && <Nav />}
      <Index />
      {!hideNavbarFooter.includes(location.pathname) && <Footer />}
    </>
  );
}

export default App;
