
// import React from 'react';
// // import "../Css/style.css";
// import AOS from 'aos'
// import { useEffect } from 'react';

// const Research_farm = () => {
//   useEffect(() => {
//       AOS.init({
//         duration: 2000, // Animation duration
//       })
//     }, [])

//   return (
//     <>
//       <section className='journey'>
//         <div className="container-fluid">
//         <h1 className='mb-2 text-success' style={{justifyContent:'center', display:'flex'}}>Research Farm</h1><br/>
//           <div className="row" data-aos="zoom-in">
//             <div className="col-md-4 d-flex justify-content-center mb-4">
//               <img 
//                 src='https://media.istockphoto.com/id/1373227608/photo/shot-young-scientist-using-a-digital-tablet-while-working-with-crops-on-a-farm.jpg?s=612x612&w=0&k=20&c=TxFmjFHa2MeiLKrugFXMTzYLWyKaokBkgCGdA0qGBpo=' 
//                 alt="Research Farm Image 1" 
//                 style={{ width: '100%', maxWidth: '400px', height: 'auto' }} 
//               />
//             </div>
//             <div className="col-md-4 d-flex justify-content-center mb-4">
//               <img 
//                 src='https://media.istockphoto.com/id/948055100/photo/young-technical-woman-working-in-a-field-of-lettuces-with-a-folder.jpg?s=612x612&w=0&k=20&c=xia6qnRnRFy9Cs92P6NMY3tOtT3r0YXgjFLnbEFvzJg=' 
//                 alt="Research Farm Image 2" 
//                 style={{ width: '100%', maxWidth: '300px', height: 'auto' }} 
//               />
//             </div>
//             <div className="col-md-4 d-flex justify-content-center mb-4">
//               <img 
//                 src='https://media.istockphoto.com/id/948055100/photo/young-technical-woman-working-in-a-field-of-lettuces-with-a-folder.jpg?s=612x612&w=0&k=20&c=xia6qnRnRFy9Cs92P6NMY3tOtT3r0YXgjFLnbEFvzJg=' 
//                 alt="Research Farm Image 2" 
//                 style={{ width: '100%', maxWidth: '300px', height: 'auto' }} 
//               />
//             </div>
//             <div className="col-md-12">
//               <div className="journey-text text-justify">
//                 {/* <h3 className='mb-2' style={{justifyContent:'center', display:'flex'}}>Research Farm</h3><br/> */}
//                 <p style={{}}>Biocyll Laboratories Pvt. Ltd. boasts an advanced Research Farm featuring a Polyhouse and extensive farmland, integral to our dedication to innovation, product excellence, and sustainable agriculture. The Polyhouse, equipped with cutting-edge technology for precise experimentation, ensures optimal conditions for bio-efficacy trials of agricultural products. Our researchers meticulously assess various formulations under simulated real-world conditions to ensure effectiveness. Complementing this, our Farm Land allows large-scale experimentation and validation of product performance. Through continuous monitoring and analysis, we refine our formulations and optimize application methods to maximize crop yields while minimizing environmental impact. Additionally, our Research Farm serves as a platform for knowledge dissemination and capacity building through training programs and demonstrations. Overall, it embodies our commitment to delivering high-performance solutions that meet farmers' needs and promote environmental stewardship.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Research_farm;



import React from 'react';
import AOS from 'aos';
import { useEffect } from 'react';
import research from "../Assets/Images/research.JPG"
import research1 from "../Assets/Images/research1.JPG"
const Research_farm = () => {
  useEffect(() => {
      AOS.init({
        duration: 2000, 
      })
    }, [])

  return (
    <>
      <section className='journey p-4' style={{
            boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
      }}
      >
        <div className="container-fluid">
        <h1 className='mb-2 text-success' style={{justifyContent:'center', display:'flex'}}>Research Farm</h1><br/>
          <div className="row" data-aos="zoom-in">
            <div className="col-md-6 d-flex justify-content-center mb-4">
              <img 
                src={research} 
                alt="Research Farm Image 1" 
                style={{ width: '100%', maxWidth: '500px', height: 'auto',  borderRadius: '10px' }} 
              />
            </div>
            <div className="col-md-6 d-flex justify-content-center mb-4">
              <img 
                src={research1} 
                alt="Research Farm Image 2" 
                style={{ width: '100%', maxWidth: '500px', height: 'auto', borderRadius: '10px'}} 
              />
            </div>
            <div className="col-md-12">
              <div className="journey-text text-justify">
                <p>Biocyll Laboratories Pvt. Ltd. boasts an advanced Research Farm featuring a Polyhouse and extensive farmland, integral to our dedication to innovation, product excellence, and sustainable agriculture. The Polyhouse, equipped with cutting-edge technology for precise experimentation, ensures optimal conditions for bio-efficacy trials of agricultural products. Our researchers meticulously assess various formulations under simulated real-world conditions to ensure effectiveness. Complementing this, our Farm Land allows large-scale experimentation and validation of product performance. Through continuous monitoring and analysis, we refine our formulations and optimize application methods to maximize crop yields while minimizing environmental impact. Additionally, our Research Farm serves as a platform for knowledge dissemination and capacity building through training programs and demonstrations. Overall, it embodies our commitment to delivering high-performance solutions that meet farmers' needs and promote environmental stewardship.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Research_farm;
