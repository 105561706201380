// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { useNavigate, Link } from "react-router-dom";
// import Logo from "../Assets/Images/logo.png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "../Css/style.css";
// import { base_url } from "../Config/Index";
// import { UserContext } from "../Context/CreateContext";
// import Biocyll_Logo from "../Assets/Images/biocyll_Logo.png"
// export default function Login() {
//     const navigate = useNavigate();
//     const [values, setValues] = useState({ email: "", password: "" });
//     const [showPassword, setShowPassword] = useState(false);
//     const toastOptions = {
//         position: "bottom-right",
//         autoClose: 8000,
//         pauseOnHover: true,
//         draggable: true,
//         theme: "dark",
//     };
//     const { user, token, login, logout } = useContext(UserContext);

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };

//     //   useEffect(() => {
//     //     if (localStorage.getItem("userData")) {
//     //       navigate("/");
//     //     }
//     //   }, []);

//     const handleChange = (event) => {
//         setValues({ ...values, [event.target.name]: event.target.value });
//     };

//     const validateForm = () => {
//         const { email, password } = values;
//         if (email === "" || password === "") {
//             toast.error("email and Password are required.", toastOptions);
//             return false;
//         }
//         return true;
//     };
//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         if (validateForm()) {
//             try {
//                 // First declare the response variable and assign its value
//                 const response = await axios.post(`${base_url}/api/login`, values);

//                 console.log(response, "hii");  // Now you can log the response after it has been initialized

//                 const Id = response.data.user._id;
//                 toast.success("Logged in Successfully");

//                 if (response.data.status == true) {
//                     // Proceed with the login process
//                     login(response.data.user, response.data.token);
//                     navigate(`/`);
//                 }

//             } catch (error) {
//                 console.log(error);
//                 toast.error(error.response.data.message, toastOptions);
//             }
//         }
//     }


//     return (
//         <div className="con">
//             <div className="form-container">
//                 <form onSubmit={(event) => handleSubmit(event)}>
//                     <div className="brand">
//                         <img src={Biocyll_Logo} alt="logo" />
//                         {/* <h1>Biocyll</h1> */}
//                     </div>
//                     <input
//                         type="email"
//                         placeholder="Email"
//                         name="email"
//                         onChange={(e) => handleChange(e)}
//                         minLength="3"
//                     />
//                     <div className="password-input-container">
//                         <div className="input-container">
//                             <input
//                                 type={showPassword ? 'text' : 'password'}
//                                 onChange={(e) => handleChange(e)}
//                                 placeholder="Password"
//                                 name="password"
//                             />
//                             <span className="password-toggle" onClick={togglePasswordVisibility}>
//                                 {showPassword ? <i class="fa-solid fa-eye text-dark"></i> : <i class="fa-solid fa-eye-slash text-dark"></i>}
//                             </span>
//                         </div>
//                     </div>
//                     <button className="login_btn" type="submit">Log In</button>
//                     <div >
//                         <text className="text-dark text-lwr mr-6">Don't have an account?</text><Link to="/register" className="">Register Now</Link>
//                     </div>
//                 </form>
//             </div>
//             <ToastContainer />
//         </div>
//     );
// }
import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../Assets/Images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Css/style.css";
import { base_url } from "../Config/Index";
import { UserContext } from "../Context/CreateContext";
import Biocyll_Logo from "../Assets/Images/biocyll_Logo.png";

export default function Login() {
    const navigate = useNavigate();
    const [values, setValues] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);

    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
    };

    const { login } = useContext(UserContext);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const validateForm = () => {
        const { email, password } = values;
        if (email === "" || password === "") {
            toast.error("Email and Password are required.", toastOptions);
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                const response = await axios.post(`${base_url}/api/login`, values);
                toast.success("Logged in Successfully");

                if (response.data.status === true) {
                    login(response.data.user, response.data.token);
                    navigate(`/`);
                }
            } catch (error) {
                console.error(error);
                toast.error(error.response.data.message, toastOptions);
            }
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="container shadow p-4" style={{ maxWidth: "400px", width: "100%" }}>
                <form onSubmit={handleSubmit}>
                    <div className="text-center mb-4">
                        <img src={Biocyll_Logo} alt="logo" className="img-fluid mb-3" style={{ maxWidth: "100px" }} />
                    </div>
                    <div className="mb-3">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            minLength="3"
                            required
                        />
                    </div>
                    <div className="mb-3 position-relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            required
                        />
                        {/* <span
                            className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? (
                                <i className="fa-solid fa-eye text-dark"></i>
                            ) : (
                                <i className="fa-solid fa-eye-slash text-dark"></i>
                            )}
                        </span> */}
                        <span className="password-toggle" onClick={togglePasswordVisibility}>
                            {showPassword ? <i class="fa-solid fa-eye text-dark"></i> : <i class="fa-solid fa-eye-slash text-dark"></i>}
                        </span>
                    </div>
                    <button className="btn  w-100 mb-3" type="submit" style={{ backgroundColor: 'yellowgreen' }}>
                        Log In
                    </button>
                    <div className="text-center">
                        <span className="text-success">Don't have an account?</span>{" "}
                        <Link to="/register" className="text-primary">
                            Register Now
                        </Link>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
}
