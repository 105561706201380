
// import React, { useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../Css/style.css';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import { UserContext } from '../Context/CreateContext';
// import design1 from "../Assets/design1.jpeg";
// import design2 from "../Assets/design2.jpg";
// import design3 from "../Assets/Images/design3.jpg";
// import design4 from "../Assets/Images/design4.png";

// const Design = () => {
//   const [categories, setCategories] = useState([]);
//   const { token } = useContext(UserContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (token) {
//       getCategories();
//     }
//   }, [token]);

//   const getCategories = async () => {
//     try {
//       const response = await axios.get(`${base_url}/api/getall/categories`, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       });
//       // Add image URLs dynamically
//       const categoriesWithImages = response.data.map((category, index) => {
//         let imageUrl;
//         switch (index % 4) { // Cycle through the images
//           case 0:
//             imageUrl = design1;
//             break;
//           case 1:
//             imageUrl = design2;
//             break;
//           case 2:
//             imageUrl = design3;
//             break;
//           case 3:
//             imageUrl = design4;
//             break;
//           default:
//             imageUrl = design1;
//         }
//         return { ...category, imageUrl };
//       });
//       setCategories(categoriesWithImages);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleCategoryClick = (categoryId) => {
//     navigate(`/categoriwise_product/${categoryId}`);
//   };

//   // Check if the token is available (user is logged in)
//   if (!token) {
//     return null; // Don't render anything if the user is not logged in
//   }
//   return (
//     <section>
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-12 text-center">
//             <h3>Shop By Categories</h3>
//           </div>
//         </div>
//         <div className="row p-5" style={{ backgroundColor: '#90d576', boxShadow: '0 -4px 20px 0 #90d576' }}>
//           {categories.map((item) => (
//             <div className="col-sm-6 col-md-3 col-lg-3  card category-card p-0" key={item._id}>
//               <div className="card-header p-0 position-relative">
//                 <img
//                   src={item.imageUrl} 
//                   className="w-100"
//                   alt={item.category}
//                   style={{height:"200px"}}
//                 />
//                 <div className="overlay">
//                   <button className="btn btn-light" onClick={() => handleCategoryClick(item._id)}>
//                     Shop Now
//                   </button>
//                 </div>
//               </div>
//               <div className="card-body text-center p-2" style={{ fontWeight: 'bolder' }}>
//                 <h4>{item.category}</h4>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Design;

import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Css/style.css";
import VAM1 from "../Assets/Images/VAM1.jpg";
import Azotobacter from "../Assets/Images/Azotobacter.jpg";
import psb from "../Assets/Images/psb.jpg";
import mobilizing from "../Assets/Images/mobilizing.jpg";
import { Link } from "react-router-dom";
const images = [
  {
    src: VAM1,
    title: "Vesicular Arbuscular Mycorrhiza Biofertilizer ",
    description: "Vesicular Arbuscular Mycorrhiza (VAM) is a biofertilizer containing beneficial fungi that form symbiotic associations with plant roots."
  },
  {
    src: Azotobacter,
    title: "Azotobacter Biofertilizer",
    description: "Azotobacter is a nitrogen-fixing biofertilizer containing beneficial microorganisms that enhance soil fertility by fixing atmospheric nitrogen. "
  },
  {
    src: psb,
    title: "Phosphate Solubilizing Bacteria",
    description: "Phosphate Solubilizing Biofertilizer is a microbial product containing beneficial bacteria that solubilize insoluble phosphates in the soil, making them available for plant uptake."
  },
  {
    src: mobilizing,
    title: "Potash Mobilizing Bacteria",
    description: "Potash Mobilizing Biofertilizer is a microbial product containing beneficial bacteria that mobilize insoluble potassium in the soil, making it available to plants."
  }
];

const FeatureProducts = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("zoom-in");
          } else {
            entry.target.classList.remove("zoom-in");
          }
        });
      },
      { threshold: 0.3 }
    );

    const cards = document.querySelectorAll(".card");
    cards.forEach((card) => observer.observe(card));

    return () => {
      cards.forEach((card) => observer.unobserve(card));
    };
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Feature Products</h2>

      {/* Carousel */}
      <div
        id="productCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="2000"
      >
        <div className="carousel-inner">
          {[...Array(Math.ceil(images.length / 4))].map((_, slideIndex) => (
            <div
              key={slideIndex}
              className={`carousel-item ${slideIndex === 0 ? "active" : ""}`}
            >
              <div className="row">
                {images
                  .slice(slideIndex * 4, slideIndex * 4 + 4)
                  .map((image, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <div className="card">
                        <Link to={`/product_page/:id`}>
                          <img
                            src={image.src}
                            alt={image.title}
                            className="card-img-top"
                          />
                        </Link>
                        <div className="card-body">
                          <h5 className="card-title">{image.title}</h5>
                          <p className="card-text">{image.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureProducts;
