
// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../Css/style.css'; // Ensure your CSS is updated as shown below
// import Biocyll_Logo from '../Assets/Images/biocyll_Logo.png';

// const Footer = () => {
//   return (
//     <>
//       <footer className="footer bg-light py-4">
//         <div className="container">
//           <div className="row">
//             {/* <div className="col-md-3 col-sm-6 col-12 text-center text-md-start mb-3">
//               <img
//                 src={Biocyll_Logo}
//                 style={{ width: '150px', height: 'auto', marginTop: '5px' }}
//                 alt="Biocyll Logo"
//               />
//             </div> */}
//             <div className="col-md-3 col-sm-6 col-12 text-center text-md-start mb-3">
//               <img
//                 src={Biocyll_Logo}
//                 style={{ width: '90px', height: '90px' }}
//                 alt="Biocyll Logo"
//               />
//               <p className="mt-2">
//                 Biocyll Laboratories Pvt. Ltd. is a trusted leader in bulk manufacturing, contract
//                 manufacturing, and contract R&D of high-quality biofertilizers, biopesticides (extract and
//                 microbial-based), biostimulants, and micronutrients. We specialize in the large-scale
//                 production of Vesicular Arbuscular Mycorrhiza (VAM), making us a preferred partner for
//                 agri-input marketers across India.
//               </p>
//             </div>

//             <div className="col-md-3 col-sm-6 col-12 mb-3 text-center text-md-start">
//               <h5>Quick Links</h5>
//               <ul className="list-unstyled">
//                 <li><a href="#" className="text-dark">About Us</a></li>
//                 <li><a href="#" className="text-dark">Gallery</a></li>
//                 <li><a href="#" className="text-dark">Contact Us</a></li>
//                 <li><a href="#" className="text-dark">Blog</a></li>
//                 <li><a href="#" className="text-dark">News</a></li>
//                 <li><a href="/career" className="text-dark">Career</a></li>
//               </ul>
//             </div>
//             <div className="col-md-3 col-sm-6 col-12 mb-3 text-center text-md-start">
//               <h5>Policy</h5>
//               <ul className="list-unstyled">
//                 <li><Link to="/privacypolicies" className="text-dark">Privacy Policy</Link></li>
//                 <li><Link to="/return" className="text-dark">Refund & Return Policy</Link></li>
//                 <li><Link to="/shipping" className="text-dark">Shipping & Delivery Policy</Link></li>
//                 <li><Link to="/terms-of-services" className="text-dark">Terms of Services</Link></li>
//               </ul>
//             </div>
//             <div className="col-md-3 col-sm-6 col-12 mb-3 text-center text-md-start">
//               <h5>Contact</h5>
//               <p><a href="tel:18005322612" className="text-dark">18005322612</a></p>
//               <p><a href="mailto:enquiry@kaybeebio.com" className="text-dark">enquiry@kaybeebio.com</a></p>
//               <p>
//                 Kay Bee Bio Organics Pvt. Ltd., Office No. 208, 209 & 210. 2nd Floor,
//                 SPRINT Antaaya, Opposite Balewadi Stadium, Near Marvel Cascada, Balewadi,
//                 Pune, Maharashtra 411045
//               </p>
//             </div>
//           </div>
//         </div>
//       </footer>
//       <div className="copyright-sec">
//         <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center py-2">
//           <p className="mb-2 mb-md-0 text-center">
//             COPYRIGHT © 2024 - ALL RIGHTS RESERVED. MADE BY{' '}
//             <a href="https://goanny.com/" target="_blank" rel="nofollow">Goanny Technologies</a>
//           </p>
//           <div className="social-links">
//             <a href="https://www.facebook.com/kaybeebioorganics" target="_blank">
//               <i className="icon icon-facebook"></i>
//             </a>
//             <a href="https://www.instagram.com/kay_bee_bio_organic/" target="_blank">
//               <i className="icon icon-instagram"></i>
//             </a>
//             <a href="https://www.linkedin.com/company/kay-bee-bio-organics-pvt-ltd" target="_blank">
//               <i className="icon icon-linkedin"></i>
//             </a>
//             <a href="https://twitter.com/kaybeebio" target="_blank">
//               <i className="icon icon-twitter"></i>
//             </a>
//             <a href="https://www.youtube.com/channel/UCV4pzAmGsjm919MB6f48UBg" target="_blank">
//               <i className="icon icon-youtube"></i>
//             </a>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Footer;





// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../Css/style.css'; 
// import Biocyll_Logo from '../Assets/Images/biocyll_Logo.png';

// const Footer = () => {
//   return (
//     <footer className="footer bg-gray text-black py-5 ">
//       <div className="container">
//         <div className="row g-4">
          
//           <div className="col-md-3 col-sm-6 flex-column h-100 mt-0 ">
//             <img src={Biocyll_Logo} style={{ width: '90px', height: '100px' }} alt="Biocyll Logo" />
//             <p className="mt-3 small text-justify">
//               Biocyll Laboratories Pvt. Ltd. is a leader in biofertilizers, biopesticides, and biostimulants. 
//               We specialize in Vesicular Arbuscular Mycorrhiza (VAM), catering to agri-input marketers across India.
//             </p>
//           </div>

//           <div className="col-md-3 col-sm-6 d-flex flex-column h-100">
//             <h5 className="mb-3">Quick Links</h5>
//             <ul className="list-unstyled">
//               <li><a href="#" className="text-black text-decoration-none">About Us</a></li>
//               <li><a href="#" className="text-black text-decoration-none">Gallery</a></li>
//               <li><a href="#" className="text-black text-decoration-none">Contact Us</a></li>
//               {/* <li><a href="#" className="text-black text-decoration-none">Blog</a></li>
//               <li><a href="#" className="text-black text-decoration-none">News</a></li> */}
//               <li><Link to="/career" className="text-black text-decoration-none">Career</Link></li>
//             </ul>
//           </div>

//           {/* <div className="col-md-3 col-sm-6 d-flex flex-column h-100">
//             <h5 className="mb-3">Policy</h5>
//             <ul className="list-unstyled">
//               <li><Link to="/privacypolicies" className="text-black text-decoration-none">Privacy Policy</Link></li>
//               <li><Link to="/return" className="text-black text-decoration-none">Refund & Return Policy</Link></li>
//               <li><Link to="/shipping" className="text-black text-decoration-none">Shipping & Delivery Policy</Link></li>
//               <li><Link to="/cancellation" className="text-black text-decoration-none">Cancellation policy </Link></li>
//             </ul>
//           </div> */}

//           <div className="col-md-3 col-sm-6 d-flex flex-column h-100">
//             <h5 className="mb-3">Contact</h5>
//             <p className="small"><a href="tel:18005322612" className="text-black text-decoration-none">+9120-67506783</a></p>
//             <p className="small"><a href="mailto:enquiry@kaybeebio.com" className="text-black text-decoration-none">info@biocyll.com</a></p>
//             <p className="small text-justify">
//             Office No 404, Karle Chowk, Karle Empire, Nanded City Phase 2, Sinhagad Road Pune 411041            </p>
//           </div>

//         </div>
//       </div>

//       <div className="copyright-sec bg-secondary text-center py-3 mt-4">
//         <p className="mb-0 small">
//           COPYRIGHT © 2024 - ALL RIGHTS RESERVED. MADE BY{' '}
//           <a href="https://goanny.com/" target="_blank" className="text-yellow text-decoration-none">Goanny Technologies</a>
//         </p>
//         <div className="social-links mt-2">
//           <a href="https://www.facebook.com/kaybeebioorganics" target="_blank" className="mx-2 text-black">
//             <i className="icon icon-facebook"></i>
//           </a>
//           <a href="https://www.instagram.com/kay_bee_bio_organic/" target="_blank" className="mx-2 text-black">
//             <i className="icon icon-instagram"></i>
//           </a>
//           <a href="https://www.linkedin.com/company/kay-bee-bio-organics-pvt-ltd" target="_blank" className="mx-2 text-black">
//             <i className="icon icon-linkedin"></i>
//           </a>
//           <a href="https://twitter.com/kaybeebio" target="_blank" className="mx-2 text-black">
//             <i className="icon icon-twitter"></i>
//           </a>
//           <a href="https://www.youtube.com/channel/UCV4pzAmGsjm919MB6f48UBg" target="_blank" className="mx-2 text-black">
//             <i className="icon icon-youtube"></i>
//           </a>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;






import React from 'react';
import { Link } from 'react-router-dom';
import '../Css/style.css'; 
import Biocyll_Logo from '../Assets/Images/biocyll_Logo.png';

const Footer = () => {
  return (
    <footer className="footer bg-gray text-black py-5 ">
      <div className="container">
        <div className="row g-4">
          
          <div className="col-md-3 col-sm-6 flex-column h-100 mt-0 ">
            <img src={Biocyll_Logo} style={{ width: '90px', height: '100px' }} alt="Biocyll Logo" />
            <p className="mt-3 small text-justify">
              Biocyll Laboratories Pvt. Ltd. is a leader in biofertilizers, biopesticides, and biostimulants. 
              We specialize in Vesicular Arbuscular Mycorrhiza (VAM), catering to agri-input marketers across India.
            </p>
          </div>

          <div className="col-md-3 col-sm-6 d-flex flex-column h-100">
            <h5 className="mb-3">Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="aboutus" className="text-black text-decoration-none small">About Us</a></li>
              <li><a href="#" className="text-black text-decoration-none small">Gallery</a></li>
              <li><a href="/contact" className="text-black text-decoration-none small">Contact Us</a></li>
              <li><Link to="/career" className="text-black text-decoration-none small">Career</Link></li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-6 d-flex flex-column h-100">
            <h5 className="mb-3">Contact</h5>
            <p className="small"><a href="tel:18005322612" className="text-black text-decoration-none">+9120-67506783</a></p>
            <p className="small"><a href="mailto:enquiry@kaybeebio.com" className="text-black text-decoration-none">info@biocyll.com</a></p>
            <p className="small text-justify">
            Office No. 404, Karle Chowk, Karle Empire, Nanded City, Phase 2, Sinhagad Road, Pune, 411041.
            </p>
          </div>

          <div className="col-md-3 col-sm-6 d-flex flex-column h-100">
            <h5 className="mb-3">Follow Us</h5>
            <ul className="list-unstyled">
              <li><a href="https://www.facebook.com/share/18nHCAmJah/?mibextid=wwXIfr" target="_blank" className="text-black text-decoration-none small">Facebook</a></li>
              {/* <li><a href="https://www.instagram.com/kay_bee_bio_organic/" target="_blank" className="text-black text-decoration-none small">Instagram</a></li>
              <li><a href="https://www.linkedin.com/company/kay-bee-bio-organics-pvt-ltd" target="_blank" className="text-black text-decoration-none small">LinkedIn</a></li>
              <li><a href="https://twitter.com/kaybeebio" target="_blank" className="text-black text-decoration-none small">Twitter</a></li>
              <li><a href="https://www.youtube.com/channel/UCV4pzAmGsjm919MB6f48UBg" target="_blank" className="text-black text-decoration-none small">YouTube</a></li> */}
            </ul>
          </div>
        </div>
      </div>

      <div className="copyright-sec bg-secondary text-center py-3 mt-4">
        <p className="mb-0 small">
          COPYRIGHT © 2024 - ALL RIGHTS RESERVED. MADE BY{' '}
          <a href="https://goanny.com/" target="_blank" className="text-yellow text-decoration-none">Goanny Technologies</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;