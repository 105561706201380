import React from 'react'
import AOS from 'aos'
import { useEffect } from 'react';

function Biostimulants() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    })
  }, [])
  return (
    <>
      <div className="row" 
      style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}
      >
        <div className="col-12 text-center mx-auto mb-4">
          <div className="p-5 headings">
            <h3>Biostimulants</h3>
            <div className='responsive-text text-justify' data-aos="zoom-in">
              <p>
                Our biostimulants are meticulously developed to enhance plant growth, boost resistance to
                environmental stress, and improve overall yield. By stimulating natural processes within
                plants, they increase nutrient efficiency and optimize metabolic functions. Our biostimulants
                also promote root development, nutrient absorption, and stress tolerance, empowering crops
                to thrive under challenging environmental conditions.                   </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Biostimulants
