
// import React, { useState, useEffect } from 'react';
// import Current_openings from '../Component/Current_openings';
// import Our_culture from '../Component/Our_culture';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { base_url } from '../Config/Index';
// import "../Css/style.css";
// import biocyll_Logo from '../Assets/Images/biocyll_Logo.png'

// const Career = () => {
//   const { section } = useParams();
//   const [currentComp, setCurrentComp] = useState(section || "culture");

//   useEffect(() => {
//     setCurrentComp(section || "culture");
//   }, [section]);

//   return (
//     <>

//       <section>

//         <div className="container-fluid" style={{ backgroundColor: '#e0f7e7' }}>
//           <div className="row">
//             <div className="col-md-12">
//               <div className="d-flex flex-wrap justify-content-center perks-container">
//                 <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
//                   <i className="fa-solid fa-clock perks-icon"></i>
//                   <h5 className="mt-3">Training And Development Opportunities</h5>
//                 </div>
//                 <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
//                   <i className="fa-solid fa-people-group perks-icon"></i>
//                   <h5 className="mt-3">Team Outings</h5>
//                 </div>
//                 <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
//                   <i className="fa-solid fa-wallet perks-icon"></i>
//                   <h5 className="mt-3">PF/ESIC Facility</h5>
//                 </div>
//                 <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
//                   <i className="fa-solid fa-award perks-icon"></i>
//                   <h5 className="mt-3">Quarterly Rewards and Recognitions</h5>
//                 </div>
//               </div>

//             </div>
//           </div>

//           loading ? (
//           <div
//             className="d-flex justify-content-center align-items-center"
//             style={{ height: '100vh' }}
//           >
//             <img src={biocyll_Logo} alt="Biocyll Logo" style={{ width: '150px', height: 'auto' }} />
//           </div>
//           ) : (
//           <>
//             <div className="container">
//               <div className="fs-5">
//                 <Our_culture />
//               </div>
//               <div className="fs-5">
//                 <Current_openings />
//               </div>
//             </div>
//           </>
//           )
//         </div>
//       </section>

//     </>
//   );
// }

// export default Career;
import React, { useState, useEffect } from 'react';
import Current_openings from '../Component/Current_openings';
import Our_culture from '../Component/Our_culture';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../Config/Index';
import biocyll_Logo from '../Assets/Images/biocyll_Logo.png';
import AOS from 'aos';

const Career = () => {
  const { section } = useParams();
  const [currentComp, setCurrentComp] = useState(section || "culture");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust delay as needed

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <>
      <section>
        <div className="container-fluid">
          {/* {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              <img src={biocyll_Logo} alt="Biocyll Logo" style={{ width: '150px', height: 'auto' }} />
            </div>
          ) : ( */}
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex flex-wrap justify-content-center perks-container" data-aos="zoom-in">
                  <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
                    <i className="fa-solid fa-clock perks-icon"></i>
                    <h5 className="mt-3">Training And Development Opportunities</h5>
                  </div>
                  <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
                    <i className="fa-solid fa-people-group perks-icon"></i>
                    <h5 className="mt-3">Team Outings</h5>
                  </div>
                  <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
                    <i className="fa-solid fa-wallet perks-icon"></i>
                    <h5 className="mt-3">PF/ESIC Facility</h5>
                  </div>
                  <div className="perks-item text-center m-3 bg-white p-4 flex-equal">
                    <i className="fa-solid fa-award perks-icon"></i>
                    <h5 className="mt-3">Quarterly Rewards and Recognitions</h5>
                  </div>
                </div>
                <div className="container">
                  <div className="fs-5">
                    <Our_culture />
                  </div>
                  <div className="fs-5">
                    <Current_openings />
                  </div>
                </div>
              </div>
            </div>


          {/* ) */}
          {/* } */}
        </div>
      </section>
    </>
  );
};

export default Career;
