// import React, { useEffect, useState } from 'react'
// import Contract_mfg from '../Component/Contract_mfg'
// import Why_choose from '../Component/Why_choose'
// import { useParams } from 'react-router-dom'
// import banner from "../Assets/Images/banner.jpg"
// import ProductPortfolio from '../Component/ProductPortfolio.jsx'
// import OurProduct from './OurProduct.js'
// import ContactForRD from '../Component/ContactForR&D.js'
// import Biofertilizer from '../Component/Biofertilizer.jsx'
// import Biopesticide from '../Component/Biopesticide.jsx'
// import Biostimulants from '../Component/Biostimulants.jsx'
// import Micronutrients from '../Component/Micronutrients.jsx'
// const B2b = () => {
//   const { section, categoryId } = useParams();
//   const [currentComp, setCurrentComp] = useState(section || "biofertilizer")

//   useEffect(() => {
//     setCurrentComp(section || "biofertilizer")
//   }, [section])

//   return (
//     <>
//       <section>
//         <div className="container-fluid">
//           <div className="row">
//           </div>
//           <div className="row pt-2 pl-3 pr-3 pb-5" style={{backgroundColor:'#e0f7e7'}}>
//             <div className="col-md-3 p-3 bg-white sidebar-fixed-height" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
//             <h5 className='text-center text-success font-weight-bold'>Our Products</h5><br/>
//               <div className={`col-12 ${currentComp === "biofertilizer" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("biofertilizer")}>Biofertilizers</div><hr style={{ backgroundColor: 'green' }} />
//               <div className={`col-12 ${currentComp === "biopesticide" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("biopesticide")}>Biopesticides</div><hr style={{ backgroundColor: 'green' }} />
//               <div className={`col-12 ${currentComp === "biostimulants" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("biostimulants")}>Biostimulants</div><hr style={{ backgroundColor: 'green' }} />
//               <div className={`col-12 ${currentComp === "micronutrients" ? "text-success font-weight-bold" : ""} cursor-pointer`} onClick={() => setCurrentComp("micronutrients")}>Micronutrients</div><hr style={{ backgroundColor: 'green' }} />

//             </div>
//             <div className="col-9 ">
//               {currentComp === "biofertilizer" && <Biofertilizer />}
//               {currentComp === "biopesticide" && <Biopesticide />}
//               {currentComp === "biostimulants" && <Biostimulants />}
//               {currentComp === "micronutrients" && <Micronutrients/>}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   )
// }

// export default B2b
import React, { useEffect, useState } from 'react'
import Contract_mfg from '../Component/Contract_mfg'
import Why_choose from '../Component/Why_choose'
import { useParams } from 'react-router-dom'
import banner from "../Assets/Images/banner.jpg"
import ProductPortfolio from '../Component/ProductPortfolio.jsx'
import OurProduct from './OurProduct.js'
import ContactForRD from '../Component/ContactForR&D.js'
import Biofertilizer from '../Component/Biofertilizer.jsx'
import Biopesticide from '../Component/Biopesticide.jsx'
import Biostimulants from '../Component/Biostimulants.jsx'
import Micronutrients from '../Component/Micronutrients.jsx'
import biocyll_Logo from '../Assets/Images/biocyll_Logo.png'
import Harnessing from '../Component/Harnessing.jsx'

const B2b = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    // loading ? (
    //   <div
    //     className="d-flex justify-content-center align-items-center"
    //     style={{ height: '100vh' }}
    //   >
    //     <img src={biocyll_Logo} alt="Biocyll Logo" style={{ width: '150px', height: 'auto' }} />
    //   </div>
    // ) : (
    <>
      <div className="container">
        <h1 className='text-success mb-2'>Our Products</h1>
        <div className="fs-5">
          <Biofertilizer />
        </div>
        <div className="fs-5">
          <Biopesticide />
        </div>
        <div className="fs-5">
          <Biostimulants />
        </div>
        <div className="fs-5">
          <Micronutrients />
        </div>
        <div className="fs-5">
          <Harnessing />
        </div>
      </div>
      {/* <div className="col-md-10 text-justify m-4 text-center " data-aos="zoom-in">
        <h3 className="heading-text fs-5 ">
          Harnessing the potential of microbes, we propel sustainable agriculture forward.
        </h3>
        <br />
        <p className="description-text fs-5 text-justify text-center">
          Crafted with high CFU counts and extended shelf life, our innovative microbial formulations are tailored to bolster farmers in fostering sustainable development across the crop lifecycle. We aspire to transform the agricultural landscape by engineering products that surpass the efficacy of existing offerings in the market.
        </p>
      </div> */}
      <div className="p-4 rounded fs-5">
        <div className="row text-center" data-aos="zoom-in">
          {/* Card 1 */}
          <div className="col-12 col-md-6 col-lg-3 mb-4"
            style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }} >
            <div className=" h-100 p-3 border-0 shadow-sm">
              <h6 className="text-success fs-5 ">Augmented Effectiveness</h6>
              <p className="card-text text-muted fs-5 text-justify">Discovering the most potent strains and amplifying their potency manifold.</p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in"
            style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }}>
            <div className=" h-100 p-3 border-0 shadow-sm">
              <h6 className="text-success fs-5">Cutting-edge Microbial Solutions</h6>
              <p className="card-text text-muted fs-5 text-justify">Manufacturing high CFU, contaminant-free, and enduring products in diverse forms.</p>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in"  style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }}>
            <div className=" h-100 p-3 border-0 shadow-sm">
              <h6 className="text-success fs-5">Extended Shelf Life</h6>
              <p className="card-text text-muted fs-5 text-justify">Prolonged durability even under adverse storage conditions.</p>
            </div>
          </div>

          {/* Card 4 */}
          <div className="col-12 col-md-6 col-lg-3 mb-4" data-aos="zoom-in"  style={{
              boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)",
            }}>
            <div className=" h-100 p-3 border-0 shadow-sm" >
              <h6 className="text-success fs-5">Resilience in Adverse Environments</h6>
              <p className="card-text text-muted fs-5 text-justify">Highly effective solutions in broad and unfavorable environmental conditions.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  // );
};

export default B2b;
