
import React from 'react';
// import "../Css/style.css";
import AOS from 'aos'
import { useEffect } from 'react';

const Our_culture = () => {
   useEffect(() => {
      AOS.init({
        duration: 1000, 
      })
    }, [])
    
  return (
    <section className="journey p-4 mb-4" style={{
          boxShadow: "0px 4px 20px rgba(128, 128, 128, 0.5)", // Gray shadow with spread
    }}
    >
      {/* <div className="container"> */}
        <div className="row">
          <div className="col-12 text-left">
            <div className="p-4" data-aos="zoom-in">
              <h1 className='mb-4 text-center text-success'>Our Culture</h1>

              <div className="mb-4">
                <h5 className='font-weight-bold'>Innovation-Driven</h5>
                <p>At Biocyll Laboratories, innovation is at the core of our operations. We are dedicated to pushing the boundaries of science and technology to develop cutting-edge solutions that address complex agricultural challenges. Our culture encourages creative problem-solving and supports continuous learning and experimentation.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Commitment to Excellence</h5>
                <p>We pride ourselves on maintaining the highest standards of quality and precision in our research and development processes and implementing lean management. Excellence is not just a goal but a fundamental aspect of our everyday work. We strive for perfection in every project and product we handle.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Collaborative Environment</h5>
                <p>Teamwork is essential to our success. At Biocyll Laboratories, we foster a collaborative environment where ideas are shared openly, and cross-functional teams work together towards common objectives. We believe that the best results come from diverse perspectives and collective efforts.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Integrity and Ethics</h5>
                <p>Integrity is the cornerstone of our business. We adhere to strict ethical standards and regulatory requirements, ensuring that our research and products are safe, effective, and reliable. Transparency and honesty in all our dealings are non-negotiable.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>People Development</h5>
                <p>We invest in our people by providing opportunities for professional growth and development. Training programs, mentorship, and career advancement initiatives are designed to help our people reach their full potential and stay ahead in the rapidly evolving biotech field.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Customer-Centric Approach</h5>
                <p>Our customers are at the heart of everything we do. We are committed to understanding their needs and delivering solutions that exceed their expectations. Customer satisfaction drives our innovation and operational strategies.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Inclusivity and Diversity</h5>
                <p>Biocyll Laboratories values diversity and inclusivity, recognizing that a diverse team brings a wealth of perspectives and ideas. We are dedicated to creating a work environment where everyone feels valued and respected.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Safety and Compliance</h5>
                <p>In the Biotechnology Industry, safety and compliance are paramount. Our culture emphasizes rigorous adherence to safety protocols and regulatory standards to ensure the well-being of our people and the integrity of our products.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Sustainability and Social Responsibility</h5>
                <p>We are committed to making a positive impact on society and the environment. Our sustainability initiatives aim to reduce our ecological footprint, and our social responsibility programs focus on contributing to community well-being and ethical practices.</p>
              </div>
              
              <div className="mb-4">
                <h5 className='font-weight-bold'>Work-Life Wellbeing</h5>
                <p>We understand the importance of maintaining a healthy work-life wellbeing. At Biocyll Laboratories, we support our people in managing their professional responsibilities while also taking care of their personal well-being.</p>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>
  );
};

export default Our_culture;
