
// import React from 'react'

// function OurProduct() {
//   return (
//     <div>
//       <p>At Biocyll Laboratories Pvt. Ltd., we are at the forefront of sustainable agricultural solutions, producing a diverse range of scientifically formulated products tailored to meet the ever-evolving needs of modern agriculture. Our expertise in manufacturing high-quality biofertilizers, biopesticides (both extract and microbial-based), biostimulants, and micronutrients enables us to deliver innovative solutions that promote plant health, enhance productivity, and align with environmentally responsible practices.</p>
//       <h3><b>Biofertilizers</b></h3>
//       <p>Our biofertilizers are designed to enhance soil fertility by introducing beneficial microorganisms that naturally improve nutrient availability. Through nitrogen fixation, phosphate solubilization, and potassium mobilization, our biofertilizers optimize nutrient uptake, fostering stronger and healthier crops. Each formulation is carefully crafted to ensure maximum efficacy in different soil types and climatic conditions, promoting sustainable and organic farming practices.
// </p>
// <h3><b>Biopesticides</b></h3>
// <p>We specialize in manufacturing both extract-based and microbial-based biopesticides that offer a sustainable alternative to chemical pesticides. Our *extract-based biopesticides* harness the power of plant-derived compounds, delivering potent protection against pests and diseases while maintaining ecological balance. On the microbial side, our *microbial-based biopesticides* employ beneficial bacteria, fungi, and viruses that target specific pests, reducing harmful infestations without leaving toxic residues. These solutions offer precision targeting, environmental safety, and reduced pest resistance.</p>
// <h3><b>Biostimulants</b></h3>
// <p>Our biostimulants are meticulously developed to enhance plant growth, boost resistance to environmental stress, and improve overall yield. By stimulating natural processes within plants, they increase nutrient efficiency and optimize metabolic functions. Our biostimulants also promote root development, nutrient absorption, and stress tolerance, empowering crops to thrive under challenging environmental conditions.</p>
// <h3><b>Micronutrients</b></h3>
// <p>Micronutrients play a critical role in plant nutrition, and our high-quality formulations are designed to address specific deficiencies in the soil. Our range of micronutrients ensures optimal crop health by improving chlorophyll production, boosting immune responses, and enhancing enzymatic activities in plants. Each product is developed with precise attention to bioavailability, ensuring that nutrients are delivered effectively to crops for maximum impact on growth and productivity.</p>
//     </div>
//   )
// }

// export default OurProduct
import React from 'react';
import "../Css/style.css";

function OurProduct() {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-5" style={{fontSize:'20px'}}>
      <div className="max-w-4xl w-full text-white rounded-lg shadow-md p-6" style={{ backgroundColor:'#90d576', boxShadow: '0 -4px 20px 0 #90d576',padding:20}}>
        <h2 className="text-center text-3xl font-bold  mb-8 font-weight-bold">Our Products</h2>
        <div className="mb-6">
          <p className="text-gray-700 text-lg leading-relaxed p-3">
            At <b className="text-blue-600">Biocyll Laboratories Pvt. Ltd.</b>, we are at the forefront of sustainable agricultural solutions, producing a diverse range of scientifically formulated products tailored to meet the ever-evolving needs of modern agriculture. Our expertise in manufacturing high-quality biofertilizers, biopesticides (both extract and microbial-based), biostimulants, and micronutrients enables us to deliver innovative solutions that promote plant health, enhance productivity, and align with environmentally responsible practices.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-md mb-6">
          <h3 className="text-2xl font-bold text-blue-600 mb-2 text-center font-weight-bold">Biofertilizers</h3>
          <p className="text-gray-700 leading-relaxed">
            Our biofertilizers are designed to enhance soil fertility by introducing beneficial microorganisms that naturally improve nutrient availability. Through nitrogen fixation, phosphate solubilization, and potassium mobilization, our biofertilizers optimize nutrient uptake, fostering stronger and healthier crops. Each formulation is carefully crafted to ensure maximum efficacy in different soil types and climatic conditions, promoting sustainable and organic farming practices.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-md mb-6">
          <h3 className="text-2xl font-bold text-blue-600 mb-2 text-center font-weight-bold">Biopesticides</h3>
          <p className="text-gray-700 leading-relaxed">
            We specialize in manufacturing both extract-based and microbial-based biopesticides that offer a sustainable alternative to chemical pesticides. Our <i>extract-based biopesticides</i> harness the power of plant-derived compounds, delivering potent protection against pests and diseases while maintaining ecological balance. On the microbial side, our <i>microbial-based biopesticides</i> employ beneficial bacteria, fungi, and viruses that target specific pests, reducing harmful infestations without leaving toxic residues. These solutions offer precision targeting, environmental safety, and reduced pest resistance.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-md mb-6">
          <h3 className="text-2xl font-bold text-blue-600 mb-2 text-center font-weight-bold">Biostimulants</h3>
          <p className="text-gray-700 leading-relaxed">
            Our biostimulants are meticulously developed to enhance plant growth, boost resistance to environmental stress, and improve overall yield. By stimulating natural processes within plants, they increase nutrient efficiency and optimize metabolic functions. Our biostimulants also promote root development, nutrient absorption, and stress tolerance, empowering crops to thrive under challenging environmental conditions.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-md">
          <h3 className="text-2xl font-bold text-blue-600 mb-2 text-center font-weight-bold">Micronutrients</h3>
          <p className="text-gray-700 leading-relaxed">
            Micronutrients play a critical role in plant nutrition, and our high-quality formulations are designed to address specific deficiencies in the soil. Our range of micronutrients ensures optimal crop health by improving chlorophyll production, boosting immune responses, and enhancing enzymatic activities in plants. Each product is developed with precise attention to bioavailability, ensuring that nutrients are delivered effectively to crops for maximum impact on growth and productivity.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurProduct;
