import React, { useEffect, useState, useContext } from "react";
import Nav from "../Component/Nav";
import Footer from "../Component/Footer";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { base_url } from "../Config/Index";
import { UserContext } from "../Context/CreateContext";
import Total from "../Component/Total";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Cart = () => {
  const { user, token } = useContext(UserContext);
  const [cartItems, setCartItems] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);
  const [cartId, setCartId] = useState(null);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    getCartItems();
  }, []);

  const getCartItems = async () => {
    try {
      const response = await axios.get(`${base_url}/api/get/card`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setIsUser(response.data.user);
      setCartItems(response.data.items || []);
      setCartId(response.data._id);
      setTotalAmt(response.data.total);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const handleRemoveItem = async (productId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (isConfirmed) {
      try {
        await axios.delete(`${base_url}/api/delete/card/${productId}`, {
          headers: { Authorization: `${token}` },
        });
        getCartItems();
      } catch (error) {
        console.error("Error removing item:", error);
      }
    }
  };

  const handleQuantity = async (itemId, action) => {
    try {
      const response = await axios.put(
        `${base_url}/api/carts/${cartId}/items/${itemId}`,
        { action },
        {
          headers: { Authorization: `${token}` },
        }
      );
      if (response.status === 200) {
        getCartItems();
      }
    } catch (error) {
      console.error(
        "Error updating quantity:",
        error.response ? error.response.data : error.message
      );
      toast.error("Error updating quantity or item not found");
    }
  };

  return (
    <>
      {user ? (
        <>
          {cartItems.length === 0 ? (
            <section className="empty-cart-section py-5">
              <div className="container text-center">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-6">
                    <img
                      src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-7359557-6024626.png"
                      className="img-fluid"
                      alt="Empty Cart"
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <h5>Your cart is empty!</h5>
                    <p>Add items to it</p>
                    <NavLink to="/">
                      <button className="btn btn-primary btn-lg">Shop now</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="cart-items-section py-5">
              <div className="container">
                <div className="row text-center mb-4">
                  <div className="col-12">
                    <h2>Your Cart Items</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 mb-4">
                    {cartItems.map((item, index) => (
                      <div
                        className="product-container mb-4 p-3"
                        key={index}
                        style={{
                          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          borderRadius: "20px",
                        }}
                      >
                        <div className="row align-items-center">
                          <div className="col-4 col-md-3">
                            <div
                              className="product-image-wrapper overflow-hidden rounded"
                              style={{ height: "150px" }}
                            >
                              <img
                                src={item?.product?.images[0]?.url}
                                alt={item?.product?.title}
                                className="img-fluid h-100 w-100"
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </div>
                          <div className="col-8 col-md-5">
                            <h6 className="mt-2 mt-md-0">{item?.product?.title}</h6>
                            <p className="text-success mt-2">
                              <i className="fa-solid fa-indian-rupee-sign"></i> {item?.product?.price}
                            </p>
                          </div>
                          <div className="col-12 col-md-3 mt-3 mt-md-0 text-center">
                            <button
                              onClick={() => handleQuantity(item._id, "decrement")}
                              className="btn btn-outline-success me-2"
                            >
                              -
                            </button>
                            {item?.quantity}
                            <button
                              onClick={() => handleQuantity(item._id, "increment")}
                              className="btn btn-outline-success ms-2"
                            >
                              +
                            </button>
                          </div>
                          <div className="col-12 col-md-1 mt-3 mt-md-0 text-center">
                            <button
                              onClick={() => handleRemoveItem(item._id)}
                              className="btn btn-outline-danger"
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-4">
                    <Total totalAmt={totalAmt} />
                  </div>
                </div>
                <div className="row justify-content-end mt-4">
                  <div className="col-auto">
                    <NavLink to="/checkout">
                      <button className="btn btn-success btn-lg">Place Order</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      ) : (
        <section className="login-prompt-section py-5">
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <img
                  src="https://cdni.iconscout.com/illustration/free/thumb/free-empty-cart-4085814-3385483.png"
                  className="img-fluid"
                  alt="Missing Cart Items"
                />
              </div>
              <div className="col-12 mt-4">
                <h4>Missing Cart items?</h4>
                <p>Login to see the items you added previously</p>
                <NavLink to="/login">
                  <button className="btn btn-warning btn-lg">Login</button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Cart;
