import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Css/style.css";
import { base_url } from "../Config/Index";

const EnquireNow = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const toastOptions = {
        position: "bottom-right",
        autoClose: 8000,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [values, setValues] = useState({
        name: "",
        email: "",
        mobileNo: "",
        message: "", 
    });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleValidation = () => {
        const { name, email, mobileNo } = values;
        if (name.length < 3) {
            toast.error("Name should be greater than 3 characters.", toastOptions);
            return false;
        } else if (mobileNo.length < 10) {
            toast.error("Mobile number should be at least 10 digits.", toastOptions);
            return false;
        } else if (email === "") {
            toast.error("Email is required.", toastOptions);
            return false;
        }

        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (handleValidation()) {

            // try {
            //     // Enquiry app call
            //     const response = await axios.post(`${base_url}/api/enquiry`, values);
            //     if (response.data.status === true) {
            //         toast.success(response.data.message, toastOptions);
            //         navigate("/thank-you");
            //     }

            // } catch (error) {
            //     toast.error(error.response.data.message, toastOptions);
            // }
        }
    };

    return (
        <div className="con">
            <div className="form-container">
                <form onSubmit={(event) => handleSubmit(event)}>
                <input
                        type="tel"
                        placeholder="Mobile Number"
                        name="mobileNo"
                        onChange={(e) => handleChange(e)}
                        value={values.mobileNo}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        onChange={(e) => handleChange(e)}
                        value={values.name}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={values.email}
                    />
                    {/* <textarea
                        placeholder="Your Message"
                        name="message"
                        onChange={(e) => handleChange(e)}
                        value={values.message}
                        rows="4"
                        className="resize-none p-4 border border-success rounded-2px focus:outline-none focus:ring-2 focus:ring-blue-500"
                    /> */}

                    <button className="login_btn" type="submit">Submit</button>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default EnquireNow;
